import "./PasswordField.css";
import {ReactComponent as IconEye} from '_assets/aldi-essentials-867/assets/brands/aldi/images/icon-eye.svg';
import {
    ReactComponent as IconEyeInverted
} from '_assets/aldi-essentials-867/assets/brands/aldi/images/icon-eye-inverted.svg';
import {forwardRef, Ref, useEffect, useReducer, useState, InputHTMLAttributes} from 'react';
import {PasswordComplexity, useGigya} from 'GigyaContext';
import {GetMessageFunction, useI18n} from "_shared/hooks/I18n";
import {RegisterOptions} from "react-hook-form/dist/types/validator";
import {UseFormRegisterReturn} from "react-hook-form/dist/types/form";

export interface PasswordFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    error?: any
}

/**
 * Renders a password field with a toggle button to view the typed in characters.
 */
export const PasswordField = forwardRef(({ error, ...inputProps }: PasswordFieldProps, ref: Ref<HTMLInputElement> | undefined) => {
    const { getMessage } = useI18n();
    const [passwordVisible, togglePasswordVisible] = useReducer((state) => {
        return !state;
    } , false);

    return (
        <>
            <input ref={ref} type={passwordVisible ? "text" : "password"} className={`textfield__control ${error ? "error" : ""}`} {...inputProps} />
            <i className="togglePassword" onClick={togglePasswordVisible} role="switch" tabIndex={0} aria-checked={passwordVisible} title={getMessage("clp.password.switch", "")}>
                {!passwordVisible && <IconEye aria-hidden={true} />}
                {passwordVisible && <IconEyeInverted aria-hidden={true} />}
            </i>
        </>
    )
});

export type PasswordRegisterHandler = (registerOptions: RegisterOptions) => UseFormRegisterReturn;

/**
 * Renders a password field with a toggle button to view the typed in characters. Furthermore, retrieves the
 * gigya password complexity via API and installs a client side validator.
 * Currently only minLength and regEx are supported. Character classes are currently not supported.
 * 
 * Props:
 * passwordFieldProps: Props that should be handed over to the PasswordField
 * registerHandler: A handler receives the password validation object as an argument and returns the result from a React form hook register()-Call.
 */
export const PasswordFieldWithValidation = ( {registerHandler, ...passwordFieldProps }: {registerHandler:PasswordRegisterHandler } ) => {
    const { getPolicies } = useGigya();
    const { getMessage } = useI18n();
    
    const [passwordValidation, setPasswordValidation] = useState(() => ({
        required: {
            value: true,
            message: getMessage("global.password.required")
        }
    }));

    useEffect(() => {
        // variable run prevent calling setPasswordValidation after removing the component from DOM, getPolicies is a Promise
        // see https://react.dev/reference/react/useEffect#fetching-data-with-effects
        let run = true;
        getPolicies && getPolicies().then(policy => run && setPasswordValidation(mapPasswordValidation(policy.passwordComplexity, getMessage)));
        return () => {
            run = false;
        };
    }, [getPolicies, getMessage]);

    return <PasswordField {...passwordFieldProps} {...registerHandler(passwordValidation)} />
}

const mapPasswordValidation = (gigyaPasswordComplexity: PasswordComplexity, getMessage: GetMessageFunction) => {
    const passwordValidation = {
        required: {
            value: true,
            message: getMessage("global.password.required")
        },
        minLength: {
            value: gigyaPasswordComplexity.minLength,
            message: getMessage("global.passwordcomplexity.error.minlength", "{0}", [ gigyaPasswordComplexity.minLength ])
        }
    };

    if (gigyaPasswordComplexity.regExp) {
        return {
            ...passwordValidation,
            pattern: {
                value: new RegExp(gigyaPasswordComplexity.regExp),
                message: getMessage("global.passwordcomplexity.error.regex")
            }
        }
    }

    return passwordValidation;
}