import {assertIsGigyaErrorResponse, gigyaWithPromise, useGigya} from 'GigyaContext';
import {AccountData, AccountProfile, useAccount} from 'Account';
import {useI18n} from '_shared/hooks/I18n';
import {To, useLocation, useNavigate} from 'react-router-dom';
import {ErrorField} from '_shared/components/ErrorField';
import {AccountFormItemDetail, getConsentObject, useAccountForm} from 'hooks/GigyaSchema';
import {FormInput, LoadingState} from "_shared/components/FormInput";
import {Fragment, useEffect, useState} from "react";
import {ConsentCheckBox} from "_shared/components/ConsentCheckBox";
import {BackButton} from "_shared/components/BackButton";
import {FieldPath, useForm} from 'react-hook-form';
import {useMetaData} from "../../MetaDataContext";

export type NavigateOrigin = "registration" | "siteLogin" | "socialLogin" | "ownIdLogin" | "ownIdRegistration";

const CONSENT_ID = "privacy.aec";

export enum RegionState {
    INCOMPLETE,
    CHECKING,
    FAILED,
    FINISHED
}

export interface RegionCheckData {
    render: boolean,
    state: RegionState,
    region: string
}

interface FinalizeRegistrationScreenFormData {
    data: AccountData,
    profile: AccountProfile,
    preferences: any
}

export const FinalizeRegistrationScreen = () => {

    const { metaData } = useMetaData();

    const { gigya } = useGigya();
    
    const { getMessage, getCdcErrorMessage } = useI18n();

    const navigate = useNavigate();
    const { state } = useLocation();

    const origin: NavigateOrigin = state ? state.origin : "registration";

    const { account } = useAccount();

    //define form fields we want to show in the given order
    const formFields: FieldPath<FinalizeRegistrationScreenFormData>[] = ["profile.email", "profile.zip"];
    const { formItemDetails, registerFormInput, register, unregister, handleSubmit } = useAccountForm<FinalizeRegistrationScreenFormData>(formFields);
    const {setError, formState: { errors }} = useForm() // TODO: handle within useAccountForm

    const isConsentAlreadyGranted = getConsentObject(account.preferences, CONSENT_ID)?.isConsentGranted;
    const [checkRegion, setCheckRegion] = useState<RegionCheckData>({render:false, state:RegionState.INCOMPLETE, region:""});

    useEffect(() => {
        if (isConsentAlreadyGranted) {
            unregister("preferences");
        }
        if (metaData?.aldiGermany) {
            setCheckRegion({render:metaData.aldiGermany, state:RegionState.INCOMPLETE, region:""});
        }
    }, [isConsentAlreadyGranted, account, unregister, metaData]);

    if (!metaData || !gigya) {
        return <></>;
    }

    if (!account.regToken) {
        return (
          <>
            <BackButton to={"/login"} />
            <br /><br />
            <p>{getMessage("clp.registration.finalize.regtoken.missing")}</p>
          </>
        )
    }

    const performFinalizeRegistration = async (formData: FinalizeRegistrationScreenFormData) => {
        try {
            
            const requestParams = {
                ...formData,
                regToken: account.regToken
            }

            await gigyaWithPromise(gigya.accounts.setAccountInfo, requestParams);
            
            await gigyaWithPromise(gigya.accounts.finalizeRegistration, {
                regToken: account.regToken!
            }, [206002]);
            //no additional fields are required

            navigate("/registration/confirmation");
        }
        catch(errorResponse: any) {
            assertIsGigyaErrorResponse(errorResponse);
            console.error("Error during registration finalization", errorResponse);
            
            setError("root.serverError", {
                type: "custom",
                message: getCdcErrorMessage(errorResponse)
            });
        }
    }

    const onZipCodeChange = async(event: any) => {
        return new Promise<LoadingState>(function(resolve, _reject) {
            if (metaData.aldiGermany) {
                const zipCode = event.target.value;
                if (zipCode.length !== 5) {
                    setCheckRegion({render: metaData.aldiGermany, state: RegionState.INCOMPLETE, region: ""});
                    resolve(LoadingState.INCOMPLETE);
                } else {
                    setCheckRegion({render: metaData.aldiGermany, state: RegionState.CHECKING, region: ""});
                    fetch(metaData.restApi + "/rest/v1/region?" +
                        new URLSearchParams({
                            zip: zipCode
                        }), {
                        method: 'GET'
                    }).then(rawResponse => {
                        return rawResponse.json();
                    }).then(response => {
                        setCheckRegion({
                            render: metaData.aldiGermany,
                            state: RegionState.FINISHED,
                            region: response.region.id
                        });
                        resolve(LoadingState.FINISHED);
                    }).catch(() => {
                        setCheckRegion({render: metaData.aldiGermany, state: RegionState.FAILED, region: ""});
                        resolve(LoadingState.FAILED);
                    })
                }
            }else {
                resolve(LoadingState.INCOMPLETE);
            }
        });
    }

    const getClassesForState = (_state: LoadingState)=> {
        return "state_" + RegionState[checkRegion.state];
    }

    return (
        <>
            <BackButton to={-1 as To} />
            <br /><br />
            <form onSubmit={handleSubmit(performFinalizeRegistration)} autoComplete="on">
                {
                    formFields
                        .map(formField => formItemDetails.get(formField)!)
                        .filter(details => shouldRenderItem(details, metaData.aldiGermany))
                        .map((details, idx) => {
                            const formField = details.field;

                            const autoFocusObj = idx === 0 ? { "autoFocus": true } : {};

                            if (formField === "profile.zip") {
                                return <Fragment key={formField}>
                                    <p id={formField + ".description"}>{getMessage("clp.registration.zip.why")}</p>
                                    <FormInput 
                                        {...registerFormInput(details)}
                                        autoComplete="postal-code"
                                        aria-describedby={formField + ".description"}
                                        onInputCheck={onZipCodeChange}
                                        onInputCheckClasses={getClassesForState}
                                        additionalInputClasses="checkRegion"
                                        {...autoFocusObj}
                                    />
                                </Fragment>
                            }
                            return <FormInput key={formField}
                                {...registerFormInput(details)}
                                {...autoFocusObj}
                            />
                        })
                }

                {!isConsentAlreadyGranted && <ConsentCheckBox formFieldName="preferences.privacy.aec.isConsentGranted" register={register} errors={errors} />}

                <ErrorField field={errors.root?.serverError} />
                <RegionCheck render={checkRegion.render} region={checkRegion.region} state={checkRegion.state} />
                <button type="submit" className="btn btn--primary btn-full">
                    {
                        origin === "registration"
                            ? getMessage("clp.registration.register.finalize")
                            : getMessage("clp.registration.register.finalize.update")
                    }
                </button>
            </form>
        </>
    )
}

/**
 * Defines whether detailsOfFormItem should be rendered or not.
 * @param {*} detailsOfFormItem 
 * @param {boolean} aldiGermany 
 * @returns {boolean}
 */
const shouldRenderItem = (detailsOfFormItem: AccountFormItemDetail<FinalizeRegistrationScreenFormData>, aldiGermany: boolean) => {
    //customerassignment should never be rendered. It can't be set on client side anyway
    if (detailsOfFormItem.field === "data.customerassignment") {
        return false;
    }

    //in Germany profile.zip is not mandatory in schema but mandatory for calculating the mandatory field data.customerassignment
    if (aldiGermany && detailsOfFormItem.field === "profile.zip") {
        return true;
    }

    //required fields without value
    if (detailsOfFormItem.required && !detailsOfFormItem.value) {
        return true;
    }

    return false;
}


const RegionCheck = (checkRegion:RegionCheckData) => {
    const { getMessage } = useI18n();
    if (!checkRegion || !checkRegion.render) {
        return <></>;
    }
    switch (checkRegion.state) {
        case RegionState.CHECKING:
            return (
                <div className="alert alert--info alert--simple">
                    <div className="alert__inner">
                        <div className="alert__content">
                            {getMessage("clp.registration.register.region.checking")}
                        </div>
                    </div>
                </div>
            );
        case RegionState.FAILED:
            return (
                <div className="alert alert--error alert--simple">
                    <div className="alert__inner">
                        <div className="alert__content">
                            {getMessage("clp.registration.register.region.failed")}
                        </div>
                    </div>
                </div>
            );
        case RegionState.FINISHED:
            let msg;
            switch (checkRegion.region) {
                case "NORD":
                    msg = getMessage("clp.registration.register.region.nord");
                    break;
                case "SUED":
                    msg = getMessage("clp.registration.register.region.sued");
                    break;
                default:
                    msg = getMessage("clp.registration.register.region.unknown");
                    break;
            }
            return (
                <div className="alert alert--success alert--simple">
                    <div className="alert__inner">
                        <div className="alert__content">
                            {msg}
                        </div>
                    </div>
                </div>
            );
        case RegionState.INCOMPLETE:
        default:
            return (
                <div className="alert alert--info alert--simple">
                    <div className="alert__inner">
                        <div className="alert__content">
                            {getMessage("clp.registration.register.region.check")}
                        </div>
                    </div>
                </div>
            );
    }
}