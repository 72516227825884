import { useGigya } from "GigyaContext";
import { continueSsoFlow, isSsoContinuing, isSsoFlowActive } from "_shared/SsoUtils";
import { GetMessageFunction, useI18n } from "_shared/hooks/I18n";
import { SimpleAccountLayout } from "central-login-page/SimpleAccountLayout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SpriteIcon from '_assets/aldi-essentials-867/assets/brands/aldi/images/sprite.svg';
import "./RegistrationConfirmationScreen.css";
import { LoadingIndicator } from "_shared/components/LoadingIndicator";

/**
 * Screen that is shown for a few seconds after the customer registered and before he's redirected to the next (partner) site
 */
export const RegistrationConfirmationScreen = () => {
    const { gigya } = useGigya();

    const navigate = useNavigate();

    const { getMessage } = useI18n();

    useEffect(() => {
        if (gigya == null) {
            return;
        }
        setTimeout(() => {
            
            const continueSso = () => {
                if (!continueSsoFlow(gigya)) {
                    navigate("/");
                }
            }
            //in case gigya is very slow, we haven't received a login event after 3 seconds and so we've no session.
            //ensure a session exists...
            gigya.hasSession().then(sessionExist => {
                if(sessionExist) {
                    continueSso();
                } else {
                    gigya.accounts.addEventHandlers({
                        onLogin: continueSso
                    })
                }
            })
            
        }, 3000);
    }, [gigya, navigate])

    return <SimpleAccountLayout divId="registration-confirmation" mobileView={true}>
        <svg className="icon icon--check successIcon" aria-hidden="true">
            <use href={SpriteIcon + "#check"}></use>
        </svg>
        <h2 className="text-center">{getMessage("clp.registration.confirmation.headline", "Du hast dich erfolgreich registriert")}</h2>
        <p>{getMessage("clp.registration.confirmation.body", "Bitte bestätige noch deine E-Mail-Adresse in den nächsten 14 Tagen, damit du dich beim nächsten mal anmelden kannst.")}</p>
        <SsoInfo />
    </SimpleAccountLayout>
}

/**
 * Screen that is shown after customer login, when gigya is redirecting... This is not happening immediately
 */
export const SsoFlowContinuingScreen = () => {
    
    return <SimpleAccountLayout divId="login-redirect" mobileView={true}>
        <SsoInfo />
    </SimpleAccountLayout>
}

const SsoInfo = () => {

    const { getMessage } = useI18n();

    if (!isSsoContinuing() && !isSsoFlowActive()) {
        return <></>;
    }

    return <>
        <LoadingIndicator id="loading" loading={true}></LoadingIndicator>
        <h2 className="text-center">{getMessage("clp.registration.confirmation.body.sso", "Wir leiten dich jetzt zurück zu {0}", [ getSsoContinueTarget(getMessage) ])}</h2>
    </>
    
}

const getSsoContinueTarget = (getMessage: GetMessageFunction) => {
    
    const regSource = sessionStorage.getItem("regSource");

    if (!regSource) {
        return getMessage("clp.registration.confirmation.body.sso.defaulttarget", "der Seite, von der du gekommen bist")
    }

    const regEx = /https?:\/\/([^/]+)/g;
    const target = regEx.exec(regSource);

    if (target) {
        return target[1];
    }
    return getMessage("clp.registration.confirmation.body.sso.defaulttarget", "der Seite, von der du gekommen bist")
}