import { Link, To } from "react-router-dom"
import { useI18n } from '_shared/hooks/I18n';
import SpriteIcon from '_assets/aldi-essentials-867/assets/brands/aldi/images/sprite.svg';
import './BackButton.css';

export const BackButton = ({to, text}: {to: To, text?: string}) => {
    const { getMessage } = useI18n();
    
    return <Link className="back" to={to}>
                <svg className="icon icon--baseline icon--sm icon--close" aria-hidden="true">
                    <use href={SpriteIcon + "#arrow-left"}></use>
                </svg>
                {text ? text : getMessage("global.back")}
            </Link>
}