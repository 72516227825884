import './App.css'
import { CmsDataProvider } from './CmsContext'
import { AldiRouterProvider } from './Router'
import { HelmetProvider } from 'react-helmet-async';
import {I18nProvider} from "./_shared/hooks/I18n";
import {MetaDataProvider} from "./MetaDataContext";
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function App() {

  const isTouchDevice = function () {
    return !!("ontouchstart"in window || window.DocumentTouch);
  }

  document.documentElement.classList.add(isTouchDevice() ? 'touch' : 'no-touch');

  return (
    <div className="App">
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <MetaDataProvider>
            <I18nProvider>
              <CmsDataProvider>
                <AldiRouterProvider />
              </CmsDataProvider>
            </I18nProvider>
          </MetaDataProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
