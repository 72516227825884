import {UseAccountFormRegisterFormInputReturn} from "../../hooks/GigyaSchema";
import {MessageKey, useI18n} from "../hooks/I18n";
import {useId, useMemo} from "react";
import {ErrorField} from "./ErrorField";
import {UseFormSetValue} from "react-hook-form/dist/types/form";
import {FieldValues} from "react-hook-form";

interface CountryEntry {
    value: string
    label: string
}
export type CountryOnChangeFunction = (value: string) => Promise<void>;

export const CountryField = ({registerInput, onChangeFunc, setValue} : {registerInput:UseAccountFormRegisterFormInputReturn, setValue: UseFormSetValue<FieldValues>, onChangeFunc?: CountryOnChangeFunction }) => {

    const { getMessage } = useI18n();

    const inputId = useId();
    const errorFieldId = useId();
    const descriptionId = useId();

    const countryOptions = useMemo<CountryEntry[]>(() => {
        const countries = [
            'AT','BE','BG','HR','CY',
            'CZ','DK','EE','FI','FR',
            'DE','GR','HU','IE','IT',
            'LV','LT','LU','MT','NL',
            'PL','PT','RO','SK','SI',
            'ES','SE','GB','IS','LI',
            'NO','CH'
        ];

        let result: CountryEntry[] = [];
        countries.forEach(code => result.push({
            value: code,
            label: getMessage("address.country." + code + ".name" as MessageKey, code)
        }));
        result.sort((c1, c2) => c1.label.localeCompare(c2.label))
        return result;
    }, [getMessage]);

    let {useFormRegisterReturn:sal_rr, errorDetails:sal_ed, label, ...sal_props} = registerInput;

    return (
        <label className={`textfield textfield--select ${sal_ed ? "has-error" : ""}`}>
            <select id={inputId} className="textfield__control" {...sal_props} {...sal_rr}
                    aria-invalid={sal_ed ? true : undefined}
                    aria-errormessage={sal_ed ? String(errorFieldId) : undefined}
                    aria-describedby={descriptionId}
                    onChange={async (e) => {
                        // set value set the value directly, if we use useState we can access the new value to late
                        // if we trigger a revalidation from other fields in onChangeFunc
                        setValue(sal_props.name, e.target.value);
                        onChangeFunc && await onChangeFunc(e.target.value);
                    }}
            >
                {
                    sal_rr.required || <option value="">{getMessage("account.data.addressBook.empty")}</option>
                }
                {
                    countryOptions.map(entry =>
                        <option key={entry.value} value={entry.value}>{entry.label}</option>
                    )
                }
            </select>
            <span id={descriptionId} className="textfield__label">{label}</span>
            <ErrorField id={errorFieldId} field={sal_ed} />
        </label>
    )
}