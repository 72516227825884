import { Account, useAccount } from "Account";
import { useMetaData } from "MetaDataContext";
import { fetchWithErrorHandling } from "_shared/Utils";
import { useMarketingPreferences } from "marketing-preferences-page/MarketingPreferencesProvider";
import { useCallback, useReducer } from "react";
import { useQuery, useQueryClient } from "react-query";

export interface ProductReminder {
    reminderId: string,
    variantId: string,
    variantName: Record<string, string>
}

export interface ProductReminderContext {
    productReminders: ProductReminder[],
    productReminderIdsMarkedForDelete: string[]
}

interface ModifyProductRemindersAction {
    type: "delete" | "keep",
    productReminderIds: string[]
}

interface RefreshProductRemindersAction {
    type: "refresh",
    productReminders: ProductReminder[]
}

interface GetProductRemindersPayload {
    productReminders: ProductReminder[]
}

export type ProductReminderAction = ModifyProductRemindersAction | RefreshProductRemindersAction;

export type ProductReminderModificator = (productReminderIds: string[]) => void

export interface UseProductReminderReturn {
    loading: boolean,
    error: boolean,
    productReminderContext: ProductReminderContext,
    reloadProductReminders: () => Promise<void>,
    markProductRemindersForDelete: ProductReminderModificator,
    unmarkProductRemindersFromDelete: ProductReminderModificator,
    deleteMarkedProductReminders: () => Promise<void>
}

type ProductReminderReducerFunction = (ctx: ProductReminderContext, action: ProductReminderAction) => ProductReminderContext;

export class ProductReminderDeletionFailedError extends Error {
    constructor(options?: ErrorOptions) {
        super("product reminder deletion failed.", options)
    }
}

const emptyProductReminderContext: ProductReminderContext = {
    productReminders: [],
    productReminderIdsMarkedForDelete: []
}

const createDeleteProductReminderAction = (productReminderIds: string[]): ModifyProductRemindersAction => {
    return {
        type: "delete",
        productReminderIds: productReminderIds
    };
}

const createKeepProductReminderAction = (productReminderIds: string[]): ModifyProductRemindersAction => {
    return {
        type: "keep",
        productReminderIds: productReminderIds
    };
}

const createRefreshProductReminderAction = (productReminders: ProductReminder[]): RefreshProductRemindersAction => {
    return {
        type: "refresh",
        productReminders: productReminders
    };
}

const productReminderReducer: ProductReminderReducerFunction = (ctx, action) => {
    switch (action.type) {
        case "delete":
            return {
                ...ctx,
                productReminderIdsMarkedForDelete: removeDuplicateItems( [ ...ctx.productReminderIdsMarkedForDelete, ...action.productReminderIds ] )
            };
        case "keep":
            return {
                ...ctx,
                productReminderIdsMarkedForDelete: ctx.productReminderIdsMarkedForDelete?.filter(item => !action.productReminderIds.includes(item))
            };
        case "refresh":
            return {
                productReminders: action.productReminders,
                productReminderIdsMarkedForDelete: []
            };
    }
}

const fetchProductReminders = async (restApi: string, jwt: string): Promise<GetProductRemindersPayload> => {
    let rawResponse;
    try {
        rawResponse = await fetchWithErrorHandling(restApi + "/rest/v1/productreminder/search", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                authToken: jwt
            })
        });
    }
    catch (error: unknown) {
        throw new Error("product reminder fetch failed.", { cause: error });
    }
    
    return await rawResponse.json() as GetProductRemindersPayload;
}

const deleteProductReminders = async (restApi: string, jwt: string, productReminderIds: string[]): Promise<void> => {
    if (productReminderIds.length === 0) {
        return;
    }

    try {
        await fetchWithErrorHandling(restApi + "/rest/v1/productreminder", {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                authToken: jwt,
                productReminderIds: productReminderIds
            })
        });
    }
    catch (error: unknown) {
        throw new ProductReminderDeletionFailedError({ cause: error });
    }
}

const getProductReminders = async (account: Account, restApi: string, jwt: string): Promise<ProductReminder[]> => {
    if (!hasProductReminderSubscription(account)) {
        return [];
    }

    const payload = await fetchProductReminders(restApi, jwt);
    if (!payload.productReminders) {
        
    }
    return payload.productReminders;
}

const hasProductReminderSubscription = (account: Account): boolean => {
    return account?.subscriptions?.SVC_PRODUCT_REMINDER?.email.isSubscribed === true &&
        account?.subscriptions?.SVC_PRODUCT_REMINDER?.email.doubleOptIn.status === "Confirmed";
}

const removeDuplicateItems = <T>(array: Array<T>): Array<T> => {
    return array.filter((item, pos, self) => {
        return self.indexOf(item) === pos;
    })
}

const getProductRemindersQueryKey = (account: Account) => {
    return ["productReminders", account.data?.sfmc?.subscriptionKey];
}

export const useProductReminderReducer = () => {
    return useReducer(productReminderReducer, emptyProductReminderContext);
}

export const useProductReminders = () => {
    const { account } = useAccount();

    const { metaData } = useMetaData();
    const { jwt } = useMarketingPreferences();

    if (!metaData) {
        throw new Error("metaData must have been loaded")
    }

    return useQuery(getProductRemindersQueryKey(account), () => getProductReminders(account, metaData.restApi, jwt));
}

export const useProductReminderContext = (): UseProductReminderReturn => {

    const { account} = useAccount();
    const { metaData } = useMetaData();
    const { jwt, productReminderContext, dispatchProductReminderContext } = useMarketingPreferences();

    const productRemindersQuery = useProductReminders();
    const queryClient = useQueryClient();

    if (!metaData) {
        throw new Error("metaData must have been loaded")
    }

    const reloadProductReminders = useCallback(async () => {
        if (productRemindersQuery.isSuccess) {
            dispatchProductReminderContext(createRefreshProductReminderAction(productRemindersQuery.data));
        }
    }, [productRemindersQuery.isSuccess, productRemindersQuery.data, dispatchProductReminderContext]);
    
    return {
        loading: productRemindersQuery.isLoading,
        error: productRemindersQuery.isError,
        productReminderContext,
        reloadProductReminders,
        markProductRemindersForDelete: (productReminderIds: string[]) => dispatchProductReminderContext(createDeleteProductReminderAction(productReminderIds)),
        unmarkProductRemindersFromDelete: (productReminderIds: string[]) => dispatchProductReminderContext(createKeepProductReminderAction(productReminderIds)),
        deleteMarkedProductReminders: async () => {
            await deleteProductReminders(metaData.restApi, jwt, productReminderContext.productReminderIdsMarkedForDelete)
            queryClient.invalidateQueries(getProductRemindersQueryKey(account));
        }
    }
}