import { useI18n } from '_shared/hooks/I18n';
import { Navigate, useSearchParams } from 'react-router-dom';
import { SsoClientsOverview } from '_shared/components/SsoClientsOverview';
import { SimpleAccountLayout } from 'central-login-page/SimpleAccountLayout';

/**
 * Screen the user is sent to, when he finishes the double opt-in process.
 * @returns 
 */
export const DoubleOptInDoneScreen = () => {
    const [searchParams] = useSearchParams();
    const errorCode = searchParams.get("errorCode");
    const { getMessage } = useI18n();

    if (!errorCode) {
        return <Navigate to="/" />
    } else if (errorCode && errorCode !== "0") {
        return <Navigate to={"/error/doi-" + errorCode} />
    }
    
    return (
        <SimpleAccountLayout divId="double-opt-in-done">
            <h1>{getMessage("clp.doubleoptin.done.success.headline")}</h1>
            <p>{getMessage("clp.doubleoptin.done.success.message")}</p>
            <SsoClientsOverview />
        </SimpleAccountLayout>
      )
}