import { useI18n } from "_shared/hooks/I18n";
import {SwitcherComponent} from "../SwitcherComponent";
import { ChangeEvent, Fragment, useEffect } from "react";
import { ProductReminder, UseProductReminderReturn, useProductReminderContext } from "marketing-preferences-page/components/productreminder";
import { LoadingIndicator } from "_shared/components/LoadingIndicator";
import "./ProductReminderComponent.css";

export const ProductReminderComponent = () => {

    const { getMessage, currentLanguage} = useI18n();
    
    const productReminderReturn = useProductReminderContext();
    const { productReminderContext, reloadProductReminders, loading, error } = productReminderReturn;

    useEffect(() => {
        reloadProductReminders();
    }, [reloadProductReminders]);

    if (loading) {
        return <ProductRemindersLoadingComponent />
    } 
    
    if (error) {
        return <ProductRemindersFetchFailedComponent />
    }

    if (productReminderContext.productReminders.length === 0) {
        return <NoProductReminderComponent />
    }

    return <div className="productreminders">
        <h3>{getMessage("mpp.productreminders.headline")}</h3>
        <p>{getMessage("mpp.productreminders.description")}</p>
        <SwitcherComponent headline={getMessage("mpp.productreminders.toggleall")} defaultChecked={true}
                onChange={createProductReminderSwitcherChangedHandler(productReminderContext.productReminders, productReminderReturn)}>
            {productReminderContext.productReminders.map(productReminder => {
                return <Fragment key={productReminder.reminderId}>
                        <SwitcherComponent
                            headline={getVariantName(productReminder, currentLanguage)} 
                            onChange={createProductReminderSwitcherChangedHandler([productReminder], productReminderReturn)}
                            checked={!productReminderContext.productReminderIdsMarkedForDelete?.includes(productReminder.reminderId)} />
                    </Fragment>
            })}
        </SwitcherComponent>
    </div>
}

const createProductReminderSwitcherChangedHandler = (productReminders: ProductReminder[], productReminderReturn: UseProductReminderReturn) => (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
        productReminderReturn.unmarkProductRemindersFromDelete(productReminders.map(pr => pr.reminderId));
    } else {
        productReminderReturn.markProductRemindersForDelete(productReminders.map(pr => pr.reminderId));
    }
}

const getVariantName = (productReminder: ProductReminder, languageCode: string): string => {
    if (!productReminder.variantName) {
        return productReminder.variantId;
    }

    if (productReminder.variantName[languageCode]) {
        return productReminder.variantName[languageCode];
    }

    const supportedLanguageCodes = Object.keys(productReminder.variantName);
    if (supportedLanguageCodes.length === 0) {
        return productReminder.variantId;
    }

    return productReminder.variantName[supportedLanguageCodes[0]];
}

const ProductRemindersLoadingComponent = () => {
    const { getMessage } = useI18n();

    return <div className="productreminders">
        <h3>{getMessage("mpp.productreminders.headline")}</h3>
        <LoadingIndicator id="loading-rps" loading={true} />
    </div> 
}

const NoProductReminderComponent = () => {
    const { getMessage } = useI18n();

    return <div className="productreminders">
        <h3>{getMessage("mpp.productreminders.headline")}</h3>
        <div className="mod mod-copy">
            <div className="rte">
                <p>{getMessage("mpp.productreminders.noproductreminders")}</p>
            </div>
        </div>
    </div> 
}

const ProductRemindersFetchFailedComponent = () => {
    const { getMessage } = useI18n();

    return <div className="productreminders">
        <h3>{getMessage("mpp.productreminders.headline")}</h3>
        <div className="mod mod-copy">
            <div className="rte">
                <p>{getMessage("mpp.productreminders.productreminderfetchfailed")}</p>
            </div>
        </div>
    </div> 
}
