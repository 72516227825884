import {UseFormSetValue} from "react-hook-form/dist/types/form";
import {UseAccountFormRegisterFormInputReturn} from "../../hooks/GigyaSchema";
import {useI18n} from "../../_shared/hooks/I18n";
import {ChangeEventHandler, FocusEventHandler, useId, useRef, useState, useMemo} from "react";
import {ErrorField} from "../../_shared/components/ErrorField";
import {FieldValues, UseFormTrigger} from 'react-hook-form';

type OptionalNumber =  number | null | undefined;

export type BirthdayComponentProp = {
    setValue: UseFormSetValue<FieldValues>,
    value_day: OptionalNumber,
    value_month: OptionalNumber,
    value_year: OptionalNumber,
    dayFormRegister: UseAccountFormRegisterFormInputReturn,
    monthFormRegister: UseAccountFormRegisterFormInputReturn,
    yearFormRegister: UseAccountFormRegisterFormInputReturn,
    label?: string
    trigger?: UseFormTrigger<FieldValues>
}

const formatBirthDay = function (day: OptionalNumber, month: OptionalNumber, year: OptionalNumber) {
    if (!day || !month || !year) {
        return "";
    }
    const default_day = String(day).padStart(2, "0");
    const default_month = String(month).padStart(2, "0");
    const default_year = String(year).padStart(4, "0");
    return default_year + "-" + default_month + "-" + default_day;
}

export const BirthdayComponent = ({setValue, value_day, value_month, value_year, dayFormRegister, monthFormRegister, yearFormRegister, label, trigger} : BirthdayComponentProp) => {

    const {getMessage} = useI18n();
    const id = useId();
    const refInput = useRef<HTMLInputElement>(null);
    const errorFieldIdDay = useId();
    const errorFieldIdMonth = useId();
    const errorFieldIdYear = useId();

    const [day, setDay] = useState(value_day);
    const [month, setMonth] = useState(value_month);
    const [year, setYear] = useState(value_year);

    useMemo(() => setDay(value_day), [value_day, setDay]);
    useMemo(() => setMonth(value_month), [value_month, setMonth]);
    useMemo(() => setYear(value_year), [value_year, setYear]);

    const {useFormRegisterReturn: reg_day, errorDetails: err_day} = dayFormRegister;
    const {useFormRegisterReturn: reg_month, errorDetails: err_month} = monthFormRegister;
    const {useFormRegisterReturn: reg_year, errorDetails: err_year} = yearFormRegister;

        const labelValue = label ?? getMessage("mpp.profile.birthdaylabel");

    const birthdayPicked: ChangeEventHandler<HTMLInputElement> = function (event) {
        const birthdaySplit = event.target.value.split('-') || [value_year, value_month, value_day];

        const day = Number(birthdaySplit[2]) || null;
        const month = Number(birthdaySplit[1]) || null;
        const year = Number(birthdaySplit[0]) || null;

        setValue(reg_day.name, day);
        setValue(reg_month.name, month);
        setValue(reg_year.name, year);
        setDay(day);
        setMonth(month);
        setYear(year);
    }

    const onFocusOut: FocusEventHandler<HTMLInputElement> = async function (_event) {
        if (trigger) {
            await trigger(reg_day.name);
            await trigger(reg_month.name);
            await trigger(reg_year.name);
        }
    }

    return (
        <label className={`textfield ${err_day || err_month || err_year ? "has-error" : ""}`}>
            <input type="hidden" {...reg_day} value={day ? String(day) : ""} />
            <input type="hidden" {...reg_month} value={month ? String(month) : ""}/>
            <input type="hidden" {...reg_year} value={year ? String(year) : ""}/>
            <input
                ref={refInput}
                aria-describedby={id}
                aria-invalid={err_day || err_month || err_year ? true : undefined}
                aria-errormessage={err_day ? errorFieldIdDay : (err_month ? errorFieldIdMonth : (err_year ? errorFieldIdYear : undefined))}
                value={formatBirthDay(day, month, year)}
                onChange={birthdayPicked}
                onBlur={onFocusOut}
                type="date"
                className="textfield__control"
                data-attr-value={labelValue}
            />
            <span id={id} className="textfield__label">{labelValue}</span>
            <ErrorField id={errorFieldIdDay} field={err_day}/>
            <ErrorField id={errorFieldIdMonth} field={err_month}/>
            <ErrorField id={errorFieldIdYear} field={err_year}/>
        </label>
    )
}