import untypedTranslation from 'i18n/messages.json';
import {createContext, ReactNode, useContext, useEffect, useMemo, useState} from 'react';
import {useCookies} from 'react-cookie';
import LanguageDetector, {DetectorOptions} from 'i18next-browser-languagedetector';
import {useMetaData, Meta} from "../../MetaDataContext";

// types API
export type MessageKey = keyof typeof untypedTranslation;
export type GetMessageFunction = (key: MessageKey, defaultMessage?: string, args?: any[]) => string;
export type GetCdcErrorMessageFunction = (cdcError: CdcError, context?: string) => string;
export type SwitchLanguageFunction = (newLanguage: string) => boolean;

// Types message file
type OptionalString = string | null | undefined;
type InAllLanguageTranslation = {
    [locale: string]: OptionalString
}
type Translations = {
    [key in MessageKey]: InAllLanguageTranslation | undefined;
};

export interface I18nContextData {
    getMessage: GetMessageFunction,
    getCdcErrorMessage: GetCdcErrorMessageFunction,
    currentLanguage: string,
    currentCountry: string,
    switchLanguage: SwitchLanguageFunction,
    availableLanguages: string[]
}

interface LanguageHook {
    language: string,
    switchLanguage: SwitchLanguageFunction,
    availableLanguages: string[]
}

interface CountryHook {
    country: string
}

export interface CdcError {
    errorCode: number | string,
    errorDetailsCode?: number | string,
    errorDetails?: string
}

const translations: Translations = untypedTranslation;
const defaultLanguage = "de";
const defaultCountry = "DE";
const cookieNameShowMessageKeys = "messageKeys";
const isFallbackToDefaultLanguage = false; // means fixed language "de", not from metaData
const getMetaDataDefaultLanguage = (metaData?: Meta.MetaData) => getLanguage(metaData?.defaultLocale) ?? defaultLanguage;
const getMetaDataDefaultCountry = (metaData?: Meta.MetaData) => getCountry(metaData?.defaultLocale) ?? defaultCountry;
const getLanguage = (locale?: string) => locale ? locale.split("_")[0] : locale;
const getCountry = (locale?: string) => locale ? locale.split("_")[1] : locale;

/**
 * Context I18N
 */
// set empty default to prevent "undefined" as context
const I18nContext = createContext<I18nContextData>({
    getMessage: (key) => key,
    getCdcErrorMessage: (key) => `${key}`,
    currentCountry: defaultCountry,
    currentLanguage: defaultLanguage,
    switchLanguage: () => false,
    availableLanguages: [defaultLanguage]
});

/**
 * Placeholder facade for performing the real translation later on when we've decided on a concrete framework
 */
const getMessage = (showMessageKeys: boolean, language?: string, country?: string): GetMessageFunction =>
    (key: keyof typeof translations, defaultMessage?: string, args: any[] = []): string => {

    let message: OptionalString;
    if (!showMessageKeys) {
        const singleTranslation = translations[key];
        if (singleTranslation) {
            if (language && country) {
                message = singleTranslation[language + "_" + country];
            }
            if (!message && language) {
                message = singleTranslation[language];
            }
            if (!message && isFallbackToDefaultLanguage) {
                message = singleTranslation[defaultLanguage];
            }
        }
        if (!message) {
            message = defaultMessage;
            process.env.REACT_APP_DEBUG === "true" && console.warn(`i18n: missing "${key}": "${defaultMessage}"`);
        }
    }
    if (!message) {
        message = key;
    }
    if (args.length === 0) {
        return message;
    }
    return message.replace(/\{(\d+)\}/g, function() {
        return args[arguments[1]];
    });
}

const checkSwitchLanguage = (availableLanguages: string[], setFunction: SwitchLanguageFunction) =>
    (newLanguage: string): boolean => {

    const canLanguageSwitch = (language: string, availableLanguages: string[]) : boolean => {
        if (isFallbackToDefaultLanguage && language === defaultLanguage) { // always possible
            return true;
        }
        if (!availableLanguages.some(language => newLanguage === language)) {
            console.log("Unknown or unusable language " + language)
            return false;
        }
        return true;
    }

    if (canLanguageSwitch(newLanguage, availableLanguages)) {
        setFunction(newLanguage);
        return true;
    }
    return false;
}

const getCdcErrorMessageInternal = (getMessage: GetMessageFunction): GetCdcErrorMessageFunction =>
    (err: CdcError, context?: string): string => {

    const m1 = getMessage("cdc.errorcode.unexpected", "{0} {1}", [ err.errorCode, err.errorDetails ]);

    const m2 = getMessage(`cdc.errorcode.${err.errorCode}` as MessageKey, m1);
    const m3 = getMessage(`cdc.errorcode.${err.errorCode}.${err.errorDetailsCode}` as MessageKey, m2);

    if (context) {
        const m4 = getMessage(`cdc.errorcode.${err.errorCode}.${context}` as MessageKey, m3);
        return getMessage(`cdc.errorcode.${err.errorCode}.${err.errorDetailsCode}.${context}` as MessageKey, m4);
    }
    return m3;
}

/**
 * @deprecated use getCdcErrorMessage from useI18n()
 */
export const getCdcErrorMessage = (cdcErrorCode: number, getMessage: GetMessageFunction, errorDetails= "") => {
    return getCdcErrorMessageInternal(getMessage)({
        errorCode: cdcErrorCode,
        errorDetails: errorDetails
    });
}

const getBrowserLanguages = (): string[] => {
    const options: DetectorOptions = {
        order: ['querystring','navigator'], // read from browser
        convertDetectedLanguage: 'Iso15897', // convert 'de-DE' to 'de_DE'
        lookupQuerystring: 'lang'
    }
    const services = {
        languageUtils: {
            getBestMatchFromCodes: true // to returns all detected browser languages
        }
    }
    const languageDetector = new LanguageDetector(services, options);
    const bl = languageDetector.detect() ?? [];
    const browserLocales = Array.isArray(bl) ? bl : [bl];
    const result = browserLocales.map((locale) => getLanguage(locale)!);
    console.log("Browser languages detected: " + result);
    return result;
}

export const I18nProvider = ({children}: {children: ReactNode}) => {
    const { language, switchLanguage, availableLanguages } = useLanguage();
    const { country } = useCountry()
    const [ cookieMonster] = useCookies();

    const i18nContextData = useMemo(() => {
        const showMessageKeys = cookieMonster[cookieNameShowMessageKeys] as boolean;
        if (showMessageKeys) {
            console.log("Show message key set, don't show texts.");
        }
        const getMessageFunction = getMessage(showMessageKeys, language, country);
        return ({
            getMessage: getMessageFunction,
            getCdcErrorMessage: getCdcErrorMessageInternal(getMessageFunction),
            currentLanguage: language,
            currentCountry: country,
            switchLanguage: switchLanguage,
            availableLanguages: availableLanguages
        })
    }, [language, country, switchLanguage, availableLanguages, cookieMonster]);

    return (
        <I18nContext.Provider value={i18nContextData}>
            {children}
        </I18nContext.Provider>
    )
}

export const useI18n = () => useContext(I18nContext)

const useLanguage = () : LanguageHook => {
    const { metaData }  = useMetaData();
    const [language, setLanguage] = useState<string>(defaultLanguage);
    const [availableLanguages, setAvailableLanguages] = useState<string[]>([defaultLanguage]);
    const [isDefault, setDefault] = useState<boolean>(true);

    // effect set available languages from metadata
    useEffect(() => {
        if (metaData) {
            setAvailableLanguages(metaData.locales.map((locale) => getLanguage(locale)!))
        }
    }, [metaData, setAvailableLanguages]);

    useEffect(() => {
        if (isDefault && metaData) {
            let isSetLanguageFromBrowser = false;
            // set language from browser (if default flag is true -> set default flag to false)
            if (availableLanguages.length > 1) {
                const checkAndSet = checkSwitchLanguage(availableLanguages, (newCheckedLanguage) => {
                    setDefault(false);
                    setLanguage(newCheckedLanguage);
                    console.log("Usable browser languages detected: " + newCheckedLanguage + " (possible " + availableLanguages + ")")
                    return true;
                });
                isSetLanguageFromBrowser = !!getBrowserLanguages().find((lang) => checkAndSet(lang));
            }
            if (!isSetLanguageFromBrowser) {
                // if default -> set default from metaData (keep default)
                const defaultLanguageFromMetadata = getMetaDataDefaultLanguage(metaData);
                console.log("Set default language from metadata to " + defaultLanguageFromMetadata)
                setLanguage(defaultLanguageFromMetadata)
            }
        }
    }, [metaData, isDefault, availableLanguages, setLanguage, setDefault]);

    return useMemo(() => ({
        language: language,
        switchLanguage: checkSwitchLanguage(availableLanguages, (newLanguage) => {
            console.log("switch language to " + newLanguage);
            setDefault(false);
            setLanguage(newLanguage);
            return true;
        }),
        availableLanguages: availableLanguages
    }), [language, availableLanguages]);
}

const useCountry = (): CountryHook => {
    const { metaData }  = useMetaData();

    return useMemo(() => ({
        country: getMetaDataDefaultCountry(metaData)
    }), [metaData]);
}
