import { Alert } from "./Alert"
import { useEffect, useRef } from "react"

export const SuccessMessage = ({message, show}: {message: string, show: boolean}) => {

    const successmessage = useRef<any>(null)

    useEffect(() => {
        if(show && successmessage.current && successmessage.current.getBoundingClientRect().y < 0){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }
    },[show]);

    return (
        <>
            {
                show && <div ref={successmessage}><Alert message={message} type="info2" icon="check" /></div>
            }
        </>
    )
}