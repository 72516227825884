
import {AccountFormItemDetail, RegisterFormInputFunction} from "../../hooks/GigyaSchema";
import "./RadioTilesComponent.css";
import {UseFormSetValue} from "react-hook-form/dist/types/form";
import {FormBoundSwitcherMultiComponent} from "./FormBoundSwitcherComponent";
import {Cms} from "../../CmsContext";
import React from "react";

/**
 * Render a RadioTiles with switchers.
 * You can set/unset more checkboxes with one switcher (see "schemaPaths" array).
 *
 * @param component RadioTiles object
 * @param registerFormInput RegisterFormInputFunction
 * @param formItemDetails form details map
 * @param setValue set value function from forms
 */
export const RadioTilesComponent = ({component, registerFormInput, formItemDetails, setValue}:{component:Cms.RadioTiles, registerFormInput:RegisterFormInputFunction<any>, formItemDetails:Map<string, AccountFormItemDetail<any>>, setValue: UseFormSetValue<any>}) => {

     let tiles: React.JSX.Element[] = [];
     component.tiles.forEach((tile, tileIndex)=>{
         let inputs: React.JSX.Element[] = [];
         let errorDetailList: any[] = [];
         let value:boolean = false;
         tile.schemaPaths.forEach((schemaPath, _idx)=>{
             let formItemDetail= formItemDetails.get(schemaPath)!;
             let {useFormRegisterReturn, errorDetails, ...props} = {...registerFormInput(formItemDetail)}
             // it's a checkbox to handle true/false as boolean
             inputs.push(<input key={schemaPath} type="checkbox" className="hidden" defaultChecked={formItemDetail.value} {...useFormRegisterReturn} {...props}/>)
             errorDetailList.push(errorDetails);
             value = value || formItemDetail.value
         });
         if (inputs.length>0) {
             tiles.push(<FormBoundSwitcherMultiComponent key={tileIndex} icon={tile.icon!} headline={tile.label} descriptions={tile.description ? [tile.description] : undefined} defaultChecked={value} inputs={inputs} setValue={setValue} errorDetails={errorDetailList} />);
         } else {
             console.error(`${tile.schemaPaths} doesn't exist in CDC schema!`)
         }
    });

    return (
        <div className="preferences RadioTiles">
            <h3>{component.label}</h3>
            {component.description && <p>{component.description}</p>}
            {tiles.map((elem) => elem)}
        </div>
    );
}
