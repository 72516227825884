import * as mr from 'multi-integer-range';

/**
 * Checks a number against a range definition.
 * see https://www.npmjs.com/package/multi-integer-range
 *
 * An empty value is always valid. With an empty range description, the value is always valid.
 *
 * @param range range description, i.e. "5" or "5-" or "5-10" or "-5" or "1,4-5"
 * @param value the number value to test
 *
 *
 */
export const isValidInsideRange = (range: string | null | undefined, value: number | null | undefined) : boolean => {
    if(!value || !range) {
        return true;
    }

    const ranges = mr.parse(range, {
        parseUnbounded: true,
        parseNegative: true
    });
    // console.log("Range " + range + " parsed into " + ranges);
    const valueRange = mr.normalize(value);
    // console.log("Value " + value + " parse into " + valueRange);

    const result = mr.has(ranges, valueRange);
    // console.log("Range result is " + result);
    return result;
}