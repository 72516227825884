import './AldiOwnId.css';
import {useGigya} from "GigyaContext";
import {OwnID, OwnIDInit, OwnidWidgetType, Position} from "@ownid/react";
import {useI18n} from '_shared/hooks/I18n';
import {RefObject} from 'react';
import {LoadAccountFunction, useAccount} from 'Account';
import {NavigateFunction, useNavigate} from 'react-router';
import {navigateToFinalizeRegistration} from 'Router';
import {ForceRedraw} from "../../_shared/components/ForceRedraw";
import {Meta, useMetaData} from "../../MetaDataContext";


export interface UseOwnIdHook {
    enabled: boolean,
    config?: Meta.MetaDataOwnId
}

export interface AldiOwnIdProps {
    passwordField: RefObject<HTMLInputElement>,
    loginIdField: RefObject<HTMLInputElement>,
    onSuccess?: SuccessHandler
}

export interface OwnIdRegisterResponse {
    data: any
    metadata: {
        dataField: string
    }
}

/**
 * Maybe this will have to be split into multiple handlers
 */
export type SuccessHandler = (response: OwnIdRegisterResponse) => void;

const useOwnId = (): UseOwnIdHook => {
    const { isGigyaReady } = useGigya();
    const { metaData } = useMetaData();
    return {
        enabled: !!(isGigyaReady() && metaData && metaData.ownId),
        config: metaData ? metaData.ownId : undefined
    }
}

export const AldiOwnIdInit = () => {
    const {enabled, config} = useOwnId();
    if (!enabled || !config) {
        return <></>;
    }
    const ownIdConfig =  config.environment === 'uat'
        ? {
            appId: config.application,
            env: 'uat',
        } : {
            overrideSdk: `https://cdn.ownid-eu.com/sdk/${config.application}`
        };

    return (
        <OwnIDInit config={{
            ...ownIdConfig,
            addOrStatement: false,
            textColor: "#172b4d",
            borderColor: "#D5DCE6",
        }}/>
    );
}

export const AldiOwnIdLogin = (aldiOwnIdProps: AldiOwnIdProps) => {

    // const navigate = useNavigate();

    // useEffect(() => {

    //     const ownidOverlayClose = () => {
    //         navigate("/");
    //     }

    //     document.addEventListener('ownid-modal-close', ownidOverlayClose);
    //     document.addEventListener('ownid-prompt-close', ownidOverlayClose);

    //     return() => {
    //         document.removeEventListener('ownid-modal-close', ownidOverlayClose);
    //         document.removeEventListener('ownid-prompt-close', ownidOverlayClose);
    //     }
    // }, [navigate]);

    return (
    <ForceRedraw widthThresholds={1024}>
        <AldiOwnId type="login" {...aldiOwnIdProps} />
    </ForceRedraw>
    )
}

export const AldiOwnIdRegister = (aldiOwnIdProps: AldiOwnIdProps) => {
    return  <ForceRedraw widthThresholds={1024}><AldiOwnId type="register" {...aldiOwnIdProps} /></ForceRedraw>
}

const AldiOwnId = ( { passwordField, loginIdField, type, onSuccess }: AldiOwnIdProps & {type: OwnidWidgetType}) => {
    const { currentLanguage } = useI18n();
    const { enabled } = useOwnId();

    const { loadAccount } = useAccount();
    const navigate = useNavigate();

    if (!enabled) {
        return <></>;
    }

    return (
        <OwnID type={type}
            options={{
                // variant:InlineWidgetVariants.ButtonFaceID,
                infoTooltip:true,
                widgetPosition:Position.End,
                addOrStatement:false
            }}
            passwordField={passwordField}
            language={currentLanguage}
            loginIdField={loginIdField}
            {...(type === "login" && { onLogin: onSuccess })}
            {...(type === "register" && { onRegister: onSuccess })}
            onError={createOwnIdErrorHandler(loadAccount, navigate, type)}
        />);
}

const createOwnIdErrorHandler = (
    loadAccount: LoadAccountFunction
    , navigate: NavigateFunction
    , type: OwnidWidgetType) => (errorDetails: unknown) => {
    
    if (errorDetails !== null && typeof errorDetails === "object" && "code" in errorDetails) {
        if (errorDetails.code === 206001 && "regToken" in errorDetails) {
            loadAccount(errorDetails.regToken as string).then(() => {
                navigateToFinalizeRegistration(navigate, type === "register" ? "ownIdRegistration" : "ownIdLogin")
            });
        }
    }

    console.error("OwnId error occurred", errorDetails);
}