import {useI18n} from "../_shared/hooks/I18n";

/**
 * Page that is rendered on fatal error, e.g. when gigya could not be loaded, initial state not be set up etc.
 * This could also be a maintenance thing.
 * @returns 
 */
export const FatalErrorPage = ( {message} : {message?: string} ) => {
    const { getMessage } = useI18n();

    console.error(message);
  
    return (
        <div id="error-page">
            <h1>{getMessage("error.page.sub-headline")}</h1>
            <p>{getMessage("error.page.headline")}</p>
            <p>
                <i>{message}</i>
            </p>
        </div>
    );
} 