import {useState,useEffect} from "react";
import { useI18n } from "_shared/hooks/I18n";
import {useForm} from "react-hook-form";
import {assertIsGigyaErrorResponse, gigyaWithPromise, useGigya} from "../../GigyaContext";
import {useAccount} from "../../Account";

export const Optin = ({hideHandler}:{hideHandler:(() => any)}) => {
    const { getMessage } = useI18n();

    const [checkboxstate, setcheckboxstate] = useState(false);
    const [showerror, setshowerror] = useState(false);
    const {handleSubmit, register} = useForm();
    const {account} = useAccount();
    const {gigya,isGigyaReady} = useGigya();

    useEffect(() => {
        if (!isGigyaReady()) {
            return;
        }
        let consented = account?.data?.sfmc?.dataProcessingConsented===true;
        setcheckboxstate(consented);
    },[account,isGigyaReady]);


    // TODO: Only hide when successfully sent the data
    // TODO: when not successfully sent show error
    const submit = async (_formData:any) => {
        if (!gigya) {
            return;
        }

        let postData = {"data":{"sfmc":{"dataProcessingConsented":checkboxstate}}}
        console.log(JSON.stringify(postData));
        const requestParams = {
            ...postData,
            regToken: account.regToken
        }
        try {
            await gigyaWithPromise(gigya.accounts.setAccountInfo, requestParams);
        }
        catch (errorResponse) {
            assertIsGigyaErrorResponse(errorResponse);
            console.error("Error during setAccountInfo", errorResponse);
            // setError("root.serverError", {
            //     message: getCdcErrorMessage(errorResponse.errorCode, getMessage, errorResponse.errorDetails)
            // });
        }

        if(!checkboxstate){
            setshowerror(true);
        } else {
            hideHandler();
        }
    }

    const checkHandler = () => {
        console.log("checkHandler1: " + checkboxstate);
        if(checkboxstate){
            setcheckboxstate(false)
            setshowerror(true);
        } else {
            setcheckboxstate(true)
            setshowerror(false);
        }
      }

    return (
        <div className={`mod-optindialog ${showerror ? "mod-optindialog-error" : ""}`}>
            <div className="mod-optindialog__content">
                <form data-attr-name={`Profile - ${getMessage("mpp.optin.headline")}}`}>
                    <h2>{getMessage("mpp.optin.headline")}</h2>
                    <p>{getMessage("mpp.optin.description")}</p>
                    <fieldset>
                        <label className="hidden"></label>
                        <label>
                            <input {...register("data.sfmc.dataProcessingConsented")} type="checkbox" className="checkbox__control" checked={checkboxstate} onChange={checkHandler} name="optincheckbox" data-attr-value={getMessage("mpp.optin.checkbox.description")} aria-required="true" />
                            <span className="checkbox__label">{getMessage("mpp.optin.checkbox.description")}</span>
                            <span className="checkbox__error">{getMessage("mpp.optin.checkbox.error")}</span>
                        </label>
                    </fieldset>
                    <button onClick={handleSubmit(submit)} className="btn btn--block btn--primary">{getMessage("mpp.optin.save")}</button>
                </form>
            </div>
        </div>
        );
}