import {Link, isRouteErrorResponse, useParams, useRouteError} from "react-router-dom";
import Header from "../central-login-page/shared-components/Header";
import {MessageKey, useI18n} from "../_shared/hooks/I18n";
import "./ErrorPage.css";
import {Meta} from "../_shared/components/Meta";
import {SimpleFooter} from "../_shared/components/SimpleFooter";

export const ErrorPage = ({ errorMessage }: { errorMessage?: string } ) => {
    const error = useRouteError();
    const { errorCode } = useParams();
    const { getMessage } = useI18n();

    console.error("Error shown on error page ", error);

    type ButtonMapping = {
        [propKey: string]: string;
    }

    const buttonMapping: ButtonMapping = {
        "doi-403002": "registration"
    }

    let buttontype = (errorCode && buttonMapping[errorCode]) || "login";
    let headline = "";

    if (!errorMessage && isRouteErrorResponse(error)) {
        // router error
        if (error.status) {
            // http status
            errorMessage = getMessage("global.errors." + error.status + ".description" as MessageKey, "Fehlercode: " + errorCode);
            headline = getMessage("global.errors." + error.status + ".headline" as MessageKey, getMessage("global.errors.general.headline"));
        }else {
            errorMessage = error.statusText;
        }
    }
    if (!errorMessage && errorCode) {
        // error code from URL
        errorMessage = getMessage("global.errors." + errorCode + ".description" as MessageKey, getMessage("global.errors.general.code", "{0}", [errorCode]));
    }
    if (!headline) {
        if (errorCode) {
            // error code from url
            headline = getMessage("global.errors." + errorCode + ".headline" as MessageKey, getMessage("global.errors.general.headline"));
        } else {
            // fall back
            headline = getMessage("error.page.sub-headline", "Oh nein!")
        }
    }

    return (
        <>
            <div className="page__main mobile-view" role="main">
                <div className="page__content">
                    <div className="container">
                        <Meta titleProperty="error.page.title" />
                        <Header/>
                        <div id="error-page">
                            <h2>{getMessage("error.page.headline")}</h2>
                            <h3>{headline}</h3>
                            <p>{errorMessage}</p>
                            { buttontype === "login" && <Link className="btn btn--primary btn-full" to={"/login"}>{getMessage("global.error.button.login")}</Link> }
                            { buttontype === "registration" && <Link className="btn btn--primary btn-full" to={"/registration"}>{getMessage("global.error.button.registration")}</Link> }
                        </div>
                    </div>
                </div>
                <SimpleFooter />
            </div>
        </>
    );
} 