import {useI18n} from "_shared/hooks/I18n";
import {Breadcrumb} from "./shared-components/Breadcrumb";
import {useMemo, useState} from "react";
import {SuccessMessage} from "./shared-components/SuccessMessage";
import {ChangePasswordForm} from "../../_shared/components/ChangePasswordForm";
import {ChangeEmailForm} from "../../_shared/components/ChangeEmailForm";

export const AccessData = () => {
    const { getMessage } = useI18n();
    const [showSuccessEmail, setShowSuccessEmail] = useState(false);
    const [showSuccessPassword, setShowSuccessPassword] = useState(false);

    const breadCrumbData = useMemo(() =>
        ([
            {
                label: getMessage("ap.startpage"),
                link: "/"
            },
            {
                label:getMessage("ap.account.accessdata.headline"),
                isCurrent: true
            }
        ]), [getMessage]
    );

    const changeMessages = (setEmail: boolean, setPassword: boolean) => {
        setShowSuccessEmail(setEmail);
        setShowSuccessPassword(setPassword);
    }
    const changeMessageEmail = (set: boolean) => changeMessages(set, false);
    const changeMessagePassword = (set: boolean) => changeMessages(false, set);

    return (
        <div id="accessdata">
            <Breadcrumb items={breadCrumbData} />
            <SuccessMessage show={showSuccessEmail} message={getMessage("ap.account.accessdata.success")} />
            <SuccessMessage show={showSuccessPassword} message={getMessage("ap.account.accessdata.success.logout")} />

            <h2>{getMessage("ap.account.accessdata.headline")}</h2>

            <h3>{getMessage("ap.account.accessdata.email.headline")}</h3>
            <ChangeEmailForm setShowSuccess={changeMessageEmail} />

            <h3>{getMessage("ap.account.accessdata.password.headline")}</h3>
            <ChangePasswordForm setShowSuccess={changeMessagePassword} />
        </div>
    )
}