import React from "react";
import {MessageKey, useI18n} from "../hooks/I18n";
import {assertIsGigyaErrorResponse, gigyaWithPromise, useGigya} from "../../GigyaContext";
import {PasswordField, PasswordFieldWithValidation} from "./PasswordField";
import {useForm} from "react-hook-form";
import {replaceEmptyStringsWithNull} from "../Utils";
import {RegisterOptions} from "react-hook-form/dist/types/validator";
import {UseFormRegister} from "react-hook-form/dist/types/form";
import {ErrorData, ErrorField} from "./ErrorField";
import {useNavigate} from "react-router-dom";

export const ChangePasswordForm = ({setShowSuccess}: {setShowSuccess: (set: boolean) => void}) => {
    const navigate = useNavigate();
    const { gigya } = useGigya();
    const { getMessage, getCdcErrorMessage } = useI18n();
    const { register, setError, handleSubmit, formState: { errors } } = useForm();

    if (!gigya) {
        return <></>
    }

    const saveNewPassword = (formData: any)=> {
        setShowSuccess(false);

        const newPw = replaceEmptyStringsWithNull(formData.password);
        const oldPw = replaceEmptyStringsWithNull(formData.newPassword);

        if (newPw === null || oldPw === null) {
            return;
        }

        try {
            gigyaWithPromise(gigya.accounts.setAccountInfo, {
                password: newPw,
                newPassword: oldPw
            }).then(async function(){
                setShowSuccess(true);
                setTimeout(()=> {
                    gigyaWithPromise(gigya.accounts.logout, {})
                    .then(_ => navigate('/'))
                }, 3000);
            }).catch((err) => {
                assertIsGigyaErrorResponse(err);
                const message = getCdcErrorMessage(err, "password");
                if (err.errorCode === 403042 || err.errorCode === 403005) { // wrong old password
                    setError("password", {
                        type: "pattern",
                        message: message
                    });
                }else {
                    setError("newPassword", {
                        type: "pattern",
                        message: message
                    });
                }
            });
        } catch(errorResponse) {
            throw errorResponse;
        }
    }

    return (
        <form onSubmit={handleSubmit(saveNewPassword)} autoComplete="on">
            <LabeledPasswordField name="password"
                                  label="ap.account.accessdata.password.current"
                                  error={errors.password}
                                  register={register}
            />
            <LabeledPasswordFieldWithValidation name="newPassword"
                                                label="ap.account.accessdata.password.new"
                                                error={errors.newPassword}
                                                register={register}
            />
            <button type="submit" className="btn btn--primary btn-full">{getMessage("global.button.save")}</button>
        </form>
    );
}



const LabeledPasswordFieldWithValidation = ({name, label, register, error}: {name: string, label: MessageKey, register:UseFormRegister<any>, error: ErrorData}) => {
    const { getMessage } = useI18n();

    return (
        <label className="textfield">
            <PasswordFieldWithValidation
                {...{
                    placeholder: getMessage(label),
                    error: error,
                    required: true
                }}
                registerHandler={((passwordValidation: RegisterOptions) => register(name, {...{passwordValidation}, required: true}) )}
            />
            <span className="textfield__label">{getMessage(label)}</span>
            <ErrorField field={error} />
        </label>)
}

const LabeledPasswordField = ({name, label, register, error}: {name: string, label: MessageKey, register:UseFormRegister<any>, error: ErrorData}) => {
    const { getMessage } = useI18n();

    return (
        <label className="textfield">
            <PasswordField
                {...{
                    placeholder: getMessage(label),
                    error: error,
                    required: true
                }}
                {...register(name, {required: true})}
            />
            <span className="textfield__label">{getMessage(label)}</span>
            <ErrorField field={error} />
        </label>)
}