import { useI18n } from '_shared/hooks/I18n';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BackButton } from '_shared/components/BackButton';
import { LoginScreen, LoginScreenFormData } from './LoginScreen';
import { assertIsGigyaErrorResponse, useGigya } from 'GigyaContext';
import { LoginRequestParams, PerformLoginFunction, useLogin } from 'central-login-page/hooks/Hooks';
import { useAccount } from 'Account';
import { SimpleAccountLayout } from 'central-login-page/SimpleAccountLayout';

/**
 * Screen used when a customer logs in or registers with a social login and an email is already present in our system.
 * @returns 
 */
export const LinkAccountsScreen = () => {
  const [email, setEmail] = useState<string>();

  const {gigya} = useGigya();

  const { getMessage } = useI18n();

  const navigate = useNavigate();

  const { performLogin } = useLogin();

  const { cdcIncludeFields, extraProfileFields } = useAccount();

  useEffect(() => {
    const regToken = localStorage.getItem("accountLinkingRegToken");
    if (!regToken) {
      navigate("/");
      return;
    }

    gigya?.accounts.getConflictingAccount({
      regToken: regToken,
      callback: (response) => setEmail(response.conflictingAccount.loginID)
    });
  }, [navigate, gigya])
  
  const handleLoginClick = async (formData: LoginScreenFormData) => {
    try {
      await linkAccountsIfInLinkingFlow(performLogin, {
        email: formData.email,
        password: formData.password,
        include: cdcIncludeFields,
        extraProfileFields: extraProfileFields
      } );

      navigate("/", { replace: true});
    }
    catch (errorResponse) {
      console.error("Error during account linking process.");
      console.error(errorResponse);
      throw errorResponse;
    }
  };

  //Login
  return (
    <SimpleAccountLayout divId="link-accounts" mobileView={true}>
      <br />
      <br />
      <h1>{getMessage("clp.linkaccounts.header")}</h1>
      <BackButton to={"/"} />
      <p>{getMessage("clp.linkaccounts.description")}</p>
      <LoginScreen prefilledEmail={email} showSocialLogin={false} showOwnId={false} onLoginClick={handleLoginClick} readOnlyEmail={true} loginButtonText={getMessage("clp.linkaccounts.perform")} />
    </SimpleAccountLayout>
    )
}

export const linkAccountsIfInLinkingFlow = async (performLogin: PerformLoginFunction, additionalArgs: LoginRequestParams) => {
  
  const regToken = localStorage.getItem("accountLinkingRegToken");

  if (!regToken) {
    return false;
  }

  try {
    //link the accounts
    await performLogin({
      ...additionalArgs,
      loginMode: "link",
      regToken: regToken
    });
    return true;
  }
  catch (errorResponse: unknown) {
    assertIsGigyaErrorResponse(errorResponse);
    //accounts have been successfully linked - perform the login
    if (errorResponse.errorCode === 200009) {
      localStorage.removeItem("accountLinkingRegToken");
      await performLogin(additionalArgs);
      return true;
    }

    throw errorResponse;
  }
}