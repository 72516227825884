import { useI18n } from "_shared/hooks/I18n";
import {Navigation, NavigationEntry} from '_shared/components/Navigation';
import Header from './shared-components/Header';
import { Navigate } from 'react-router-dom';
import { useAccount } from 'Account';
import {ReactNode, useState} from "react";
import {Meta} from "../_shared/components/Meta";
import {SimpleFooter} from "../_shared/components/SimpleFooter";
import "./CentralLoginLayout.css";

export const CentralLoginLayout = ({ children }: {children: ReactNode}) => {
    const { getMessage } = useI18n();

    const { account } = useAccount();

    const [accountInitiallyLoggedIn,] = useState(account.loggedIn);

    //we want the sub components to handle the navigation during its normal process
    //we don't want the rendering part to kick in immediately after account update
    if (accountInitiallyLoggedIn) {
        return <Navigate to="/" />;
    }

    const navigationEntries: NavigationEntry[] = [
        {
            label: getMessage("clp.nav.login"),
            route: "/login"
        },
        {
            label: getMessage("clp.nav.register"),
            route: "/registration"
        }
    ]

    return (
        <>
            <div className="page__main mobile-view central-login-page" role="main">
                <div className="page__content">
                    <div className="container">
                        <Meta titleProperty="clp.page.title" />
                        <Header />
                        <Navigation entries={navigationEntries} />
                        {
                            Array.isArray(children) ? children.map((child) => child) : children
                        }
                    </div>
                </div>
                <SimpleFooter />
            </div>
        </>
    );
}
