import "./LoadingScreen.css";

/**
 * This Screen shows a complete loading screen. It's meant to be used when the whole page
 * has not finished loading. In comparison {@link LoadingIndicator} can be used for individual components
 * and can be rendered as part of the component.
 * @returns 
 */
export default function LoadingScreen() {
    return <div className="loading-screen"><div className="loading"></div></div>
}
