import { Account, AccountSubscriptions, useAccount } from "Account"
import { useState } from "react";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import { NewsletterComponent, NewsletterComponentProps } from "./NewsletterComponent";
import { ConfirmOverlayCallback, InitialNewsletterOptInDialog, isInitialNewsletterOptIn } from "./initialnewsletteroptindialog";

interface FormData {
    subscriptions?: AccountSubscriptions,
    data?: {
        sfmc?: {
            dataProcessingConsented?: boolean
        }
    }
}

/**
 * In case the customer subscribes for his very first newsletter, an opt-in dialog is shown.
 * In case he agrees, he'll also set the dataProcessingConsented flag
 * @param account 
 * @param setValue 
 * @param setInitialNewsletterOptInCallback 
 * @returns 
 */
const createInitialNewsletterOptInCheck = (account: Account, setValue: UseFormSetValue<FieldValues>, setInitialNewsletterOptInCallback: (cb?: ConfirmOverlayCallback) => void) => async (formData: FormData): Promise<FormData> => {
    if (!isInitialNewsletterOptIn(account, formData.subscriptions)) {
        return formData;
    }

    const confirmed = await new Promise<boolean>((resolve) => {
        setInitialNewsletterOptInCallback({ promiseResolver: resolve })
    });
    setInitialNewsletterOptInCallback(undefined);

    if (!confirmed) {
        for (const key of Object.keys(formData.subscriptions.sfmc!)) {
            formData.subscriptions.sfmc![key]!.email.isSubscribed = false;
        }

        setValue("subscriptions.sfmc", formData.subscriptions.sfmc);
        return formData;
    }

    if (!formData.data) {
        formData.data = { sfmc: { dataProcessingConsented: true } };
    } else if (!formData.data.sfmc) {
        formData.data.sfmc = { dataProcessingConsented: true };
    } else {
        formData.data.sfmc.dataProcessingConsented = true;
    }

    return formData;
}

export const useNewsletter = (setValue: UseFormSetValue<FieldValues>) => {
    const { account } = useAccount();

    const [initialNewsletterOptInCallback, setInitialNewsletterOptInCallback] = useState<ConfirmOverlayCallback | undefined>();
    
    return {
        createInitialNewsletterOptInCheck: () => createInitialNewsletterOptInCheck(account, setValue, setInitialNewsletterOptInCallback),
        createNewsletterComponent: (props: NewsletterComponentProps & { key?: React.Key }) => <NewsletterComponentWithOverlay initialNewsletterOptInCallback={initialNewsletterOptInCallback} {...props } />
    }
}

const NewsletterComponentWithOverlay = (props: NewsletterComponentProps & { initialNewsletterOptInCallback?: ConfirmOverlayCallback}) => {

    const { initialNewsletterOptInCallback, ...nlProps} = props;

    return <>
        <NewsletterComponent {...nlProps } />
        {initialNewsletterOptInCallback && <InitialNewsletterOptInDialog confirmCallback={initialNewsletterOptInCallback} />}
    </>
}