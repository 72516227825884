import {AccountFormItemDetail, RegisterFormInputFunction} from "../../hooks/GigyaSchema";
import {FormInput} from "../../_shared/components/FormInput";
import {FavoriteStoreComponent} from "./FavoriteStoreComponent";
import {UseFormReturn, FieldValues} from "react-hook-form";
import {SalutationComponent} from "../../_shared/components/SalutationComponent";
import {ShoppingFrequenciesComponent} from "./ShoppingFrequenciesComponent";
import {BirthdayComponent} from "./BirthdayComponent";
import {CountryComponent} from "./CountryComponent";
import {Cms} from "../../CmsContext";
import React from "react";

export type ProfileComponentProp<TFieldValues extends FieldValues> = {
    component:Cms.ProfileTiles,
    registerFormInput:RegisterFormInputFunction<TFieldValues>,
    formItemDetails:Map<string, AccountFormItemDetail<TFieldValues>>,
    useFormReturn: UseFormReturn<FieldValues>
};

export const ProfileComponent = <TFieldValues extends FieldValues> ({useFormReturn, component, registerFormInput, formItemDetails} : ProfileComponentProp<TFieldValues>) => {

    const {setValue, trigger } = useFormReturn;

    const components: React.ReactNode[] = [];

    component.fields.map((profileField, index)=> {
        switch (profileField.component) {
            case "FormInput":
                components.push(<FormInput key={index} {...registerFormInput(formItemDetails.get(profileField.cdcProperty)!)} label={profileField.label} readOnly={profileField.readonly} trigger={trigger} />);
                break;
            case "SalutationInput":
                components.push(<SalutationComponent key={index} registerInput={registerFormInput(formItemDetails.get(profileField.cdcProperty)!)} label={profileField.label} cmsInputField={profileField as Cms.ProfileFieldSalutation}/>);
                break;
            case "BirthdayInput":
                let bd_fid=formItemDetails.get((profileField as Cms.ProfileFieldBirthday).cdcPropertyDay);
                let bm_fid=formItemDetails.get((profileField as Cms.ProfileFieldBirthday).cdcPropertyMonth);
                let by_fid=formItemDetails.get((profileField as Cms.ProfileFieldBirthday).cdcPropertyYear);
                components.push(<BirthdayComponent key={index} setValue={setValue} value_day={bd_fid?.value} value_month={bm_fid?.value} value_year={by_fid?.value} dayFormRegister={registerFormInput(bd_fid!)} monthFormRegister={registerFormInput(bm_fid!)} yearFormRegister={registerFormInput(by_fid!)} label={profileField.label} trigger={trigger}/>)
                break;
            case "FavoriteStoreInput":
                components.push(<FavoriteStoreComponent key={index} useFormReturn={useFormReturn} registerFormInput={registerFormInput} formItemDetails={formItemDetails} cmsInputField={profileField as Cms.ProfileFieldFavoriteStore} />)
                break;
            case "CountryInput":
                components.push(<CountryComponent key={index} registerFormInput={registerFormInput} formItemDetails={formItemDetails} cmsInputField={profileField as Cms.ProfileFieldCountry}/>);
                break;
            case "ShoppingFrequencyInput":
                components.push(<ShoppingFrequenciesComponent key={index} registerFormInput={registerFormInput} formItemDetails={formItemDetails} cmsInputField={profileField as Cms.ProfileFieldShoppingFrequency}/>);
                break;
            default:
                // unknown;
                break;
        }
        return component;
    })


    return(

        <div className="preferences profile">
            <fieldset>
                {components.map((component) => component)}
            </fieldset>
        </div>
    );
}
