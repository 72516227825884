import {CheckPasswordField} from "./CheckPasswordField";
import {FormInput} from "./FormInput";
import {useRef, useState} from "react";
import {replaceEmptyStringsWithNull} from "../Utils";
import {gigyaWithPromise, useGigya} from "../../GigyaContext";
import {useAccount} from "../../Account";
import {useI18n} from "../hooks/I18n";
import {useAccountForm} from "../../hooks/GigyaSchema";

export const ChangeEmailForm = ({setShowSuccess}: {setShowSuccess: (set: boolean) => void}) => {
    const formFields = [
        "profile.email"
    ];
    const { handleSubmit, formItemDetails, registerFormInput, setError } = useAccountForm(formFields);
    const { refreshAccount} = useAccount();
    const { gigya } = useGigya();
    const { getMessage } = useI18n();

    const [passwordConfirmed, setPasswordConfirmed] = useState(false);
    const refPasswordField1 = useRef<any>(null);

    if (!gigya) {
        return <></>
    }

    const confirmPassword = (e:any) => {
        e.preventDefault();
        setPasswordConfirmed(false);
        setShowSuccess(false);
        refPasswordField1.current.confirm()
            .then(() => setPasswordConfirmed(true))
            .catch(() => setPasswordConfirmed(false))
    }

    const saveEmail = async (formData: any)=> {
        formData = replaceEmptyStringsWithNull(formData);
        setShowSuccess(false);
        try {
            await gigyaWithPromise(gigya.accounts.setAccountInfo, formData)
                .then(async function(){
                    await refreshAccount();
                    setShowSuccess(true);
                    setPasswordConfirmed(false);
                }).catch((err) => {
                    err?.validationErrors?.forEach((validationError: any, _idx: number) => {
                        if (validationError?.fieldName) {
                            setError(validationError.fieldName, {
                                type: "pattern",
                                message: validationError.message
                            });
                        }
                    });
                });
        } catch(errorResponse) {
            throw errorResponse;
        }
    }

    return (<>
        {
            !passwordConfirmed ?
                <>
                    <p>{getMessage("ap.account.accessdata.email.passwordconfirm.description")}</p>
                    <form onSubmit={confirmPassword} autoComplete="on">
                        <CheckPasswordField ref={refPasswordField1} />
                        <button type="submit" className="btn btn--primary btn-full">{getMessage("global.button.confirm")}</button>
                    </form>
                </>
                :
                <form onSubmit={handleSubmit(saveEmail)} autoComplete="on">
                    <FormInput {...registerFormInput(formItemDetails.get("profile.email")!)}/>
                    <button type="submit" className="btn btn--primary btn-full">{getMessage("global.button.save")}</button>
                </form>
        }
    </>);
}