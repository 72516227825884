import {useEffect, useRef, useState} from "react";
import "./ScrollSlider.css";
import SpriteSvg from '_assets/aldi-essentials-867/assets/brands/aldi/images/sprite.svg';
import {Cms} from "../../CmsContext";
import {useI18n} from "../../_shared/hooks/I18n";
import { CmsTabStatus } from "./MarketingPreferencesTabStatus";

export type ScrollSliderProp = {
    items: CmsTabStatus[],
    activeItem?: CmsTabStatus,
    callbackFunction:(_:Cms.Tab) => any
};

export const ScrollSlider = ({items, activeItem, callbackFunction}: ScrollSliderProp) => {
    const { currentLanguage } = useI18n();
    const sliderelement = useRef<HTMLUListElement>(null)
    const [showprev, setshowprev] = useState(false);
    const [shownext, setshownext] = useState(false);

    const scrollHandler = function () {
        if (sliderelement.current) {
            let maxWidth = sliderelement.current.scrollWidth - sliderelement.current.clientWidth;
            sliderelement.current.scrollLeft === 0 ? setshowprev(false) : setshowprev(true);
            sliderelement.current.scrollLeft === maxWidth || sliderelement.current.children[sliderelement.current.children.length - 1].getBoundingClientRect().right < sliderelement.current.getBoundingClientRect().width ? setshownext(false) : setshownext(true);
        }
    }

    const scrollPrev = function () {
        sliderelement.current && sliderelement.current.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    const scrollNext = function () {
        sliderelement.current && sliderelement.current.scrollBy({
            top: 0,
            left: sliderelement.current.scrollWidth - sliderelement.current.clientWidth,
            behavior: "smooth"
        });
    }

    useEffect(() => {
      scrollHandler();

      window.addEventListener('resize', scrollHandler);
    }, []);
    

    return (
        <div className="scroll-slider">
            {showprev && <button onClick={scrollPrev} type="button" className="scroll-slider__btn scroll-slider__btn--prev scroll-tabs__btn">
                <svg className="icon icon--arrow-left" role="img" aria-hidden="true">
                    <use href={`${SpriteSvg}#arrow-left`}>
                    </use>
                </svg>
            </button>}
            {shownext && <button onClick={scrollNext} type="button" className="scroll-slider__btn scroll-slider__btn--next scroll-tabs__btn">
                <svg className="icon icon--arrow-right" role="img" aria-hidden="true">
                    <use href={`${SpriteSvg}#arrow-right`}>
                    </use>
                </svg>
            </button>}
            <div className="scroll-slider__wrapper scroll-tabs">
                <ul ref={sliderelement} className="tabs tabs--block scroll-slider__items" onScroll={scrollHandler}>
                {
                    items.map((tabStatus, idx) =>
                        <li key={currentLanguage + tabStatus.tab.anchor + idx} className={`tabs__item ${activeItem === tabStatus ? 'is-selected' : ''} ${tabStatus.enabled ? '' : 'disabled'}`}>
                            <button onClick={() => callbackFunction(tabStatus.tab)}
                                className="tabs__action"
                                data-attr-name="Main Navigation - Level 1"
                                data-attr-value={tabStatus.tab.label}
                                >{tabStatus.tab.label}</button>
                        </li>
                    )
                }
                </ul>
            </div>
        </div>
        );
}