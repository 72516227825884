import SpriteIcon from '_assets/aldi-essentials-867/assets/brands/aldi/images/sprite.svg';
import "./Alert.css";

// typical icons: check, info, warning 
// type info2 = info alert from aec

export const Alert = ({title, message, type, icon}: {title?: string, message?: string, type:"success"|"error"|"info"|"info2", icon?: string}) => {

    return (
        <div className={`alert alert--${type} alert-default`} role={type === "error" ? "alert" : undefined} aria-live={type !== "error" ? "polite" : undefined}>
            <div className="alert__inner">
                { icon && 
                    <svg className="icon alert__icon" aria-hidden="true">
                        <use href={SpriteIcon + `#${icon}`}></use>
                    </svg>
                }
                <div className="alert__content">
                    { title &&
                        <span className="alert__title">
                            {title}
                        </span>
                    }
                    { message && 
                        <span className="alert__message">
                            {message}
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}