import {LegalStatement, useGigya} from "../../GigyaContext";

/**
 * Renders a link to a consent. Fetches the consent link on demand.
 */
export const ConsentLink = ({ consentKey, linkText}: { consentKey: string, linkText: string} ) => {
    const { gigyaLanguage, getSiteConsentDetails } = useGigya();
    
    const onClick = async (_e: any) => {
        if (!getSiteConsentDetails) {
            return;
        }
        const siteConsentDetails = await getSiteConsentDetails();

        const consent = siteConsentDetails[consentKey];
        if (!consent) {
            throw new Error(`Configuration error. Consent ${consentKey} not found.`);
        }

        let legalStatement: LegalStatement | undefined;
        if (gigyaLanguage) {
            legalStatement = consent.legalStatements[gigyaLanguage];
        }
        // fallback, use first key from object
        if (!legalStatement && Object.keys(consent.legalStatements).length > 0) {
            legalStatement = consent.legalStatements[Object.keys(consent.legalStatements)[0]];
        }

        if (legalStatement) {
            window.open(legalStatement.documentUrl, '_blank')?.focus();
        }
    }

    return <button type="button" className="link link--standard" onClick={onClick}>{linkText}</button>
}
