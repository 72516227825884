import SocialLogin from '../shared-components/SocialLogin';
import {PasswordField} from '_shared/components/PasswordField';
import {ErrorField} from '_shared/components/ErrorField';
import {Link, useSearchParams, useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useLogin} from 'central-login-page/hooks/Hooks';
import {useI18n} from '_shared/hooks/I18n';
import {useRef} from "react";
import {AldiOwnIdInit, AldiOwnIdLogin} from "../shared-components/AldiOwnId";
import {useState} from 'react';
import {LoadingIndicator} from '_shared/components/LoadingIndicator';
import {FormInput} from '_shared/components/FormInput';
import {useGigyaSchema} from 'hooks/GigyaSchema';
import {copyRefFunc} from '_shared/Utils';
import { useAccount } from 'Account';
import { isSsoContinuing } from '_shared/SsoUtils';
import { assertIsGigyaErrorResponse } from 'GigyaContext';
import { useMetaData } from 'MetaDataContext';

type LoginScreenProps = {
    prefilledEmail?: string,
    showOwnId?: boolean,
    showSocialLogin?: boolean,
    onLoginClick?: LoginScreenOnLoginClick,
    readOnlyEmail?: boolean,
    loginButtonText?: string
}

export type LoginScreenFormData = {
    email: string,
    password: string
}

type LoginScreenOnLoginClick = (formData: LoginScreenFormData) => Promise<void>;

export const LoginScreen = ({ prefilledEmail = undefined, showOwnId = true, showSocialLogin = true, onLoginClick = undefined, readOnlyEmail = undefined, loginButtonText = undefined }: LoginScreenProps) => {
    const [searchParams] = useSearchParams();
    const email = prefilledEmail || searchParams.get("email");
    
    const [ isLoading, setLoading ] = useState(false);

    const { getFormValidationForField } = useGigyaSchema();

    const { register, handleSubmit, setError, formState: { errors } } = useForm<LoginScreenFormData>();

    const { getMessage, getCdcErrorMessage } = useI18n();
    
    const { performLogin } = useLogin();

    const navigate = useNavigate();
    const location = useLocation();

    const emailInputRef = useRef<HTMLInputElement>();
    const passwordInputRef = useRef<HTMLInputElement>();
    
    const [resendVerificationEmail, setResendVerificationEmail] = useState<string>();

    const { cdcIncludeFields } = useAccount();

    const registerEmailResult = register("email", getFormValidationForField("profile.email"));

    //note that intentionally no validation is applied here. We've migrated users with less strict passwords in our system
    const registerPasswordResult = register("password", {required:
            { value: true, message: getMessage("global.password.required") } }
    );
    
    const afterLogin = () => {
        let { from } = location.state || { from: { pathname: "/" } };

        if (isSsoContinuing()) {
            navigate("/login/redirect")
        }
        else {
            //Navigate back to the URL where we came from and replace the history entry.
            navigate(from, { replace: true});
        }
    }

    const handleLoginClick = async (formData: LoginScreenFormData) => {
        setResendVerificationEmail(undefined);
        try {
            setLoading(true);
            //test//throw {errorCode: 400006, customMessage: "REGISTRATION_UNVERIFIED"};
            if (onLoginClick) {
                await onLoginClick(formData);
                return;
            }

            const loginPerformed = await performLogin({
                email: formData.email,
                password: formData.password,
                include: cdcIncludeFields
            })
            if (loginPerformed) {
                afterLogin();
            }
        }
        catch (errorResponse: unknown) {
            assertIsGigyaErrorResponse(errorResponse);
            //errors from extension points like accounts-internal
            if (errorResponse.errorCode === 400006 && errorResponse.customMessage) {
                switch (errorResponse.customMessage) {
                    case "REGISTRATION_UNVERIFIED":
                        setResendVerificationEmail(formData.email);
                        break;
                    default:
                        setError("root.serverError", {
                            type: "custom",
                            message: getMessage("clp.login.customError.unknown", "{0}", [errorResponse.customMessage])
                        });
                        break;
                }
                return;
            }
            console.error(errorResponse);
            
            setError("root.serverError", {
                type: "custom",
                message: getCdcErrorMessage(errorResponse)
            });
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <LoadingIndicator id="login-screen" loading={isLoading}>
            { resendVerificationEmail ? 
            <>
                <DoiAlert email={resendVerificationEmail} backToLogin={() => setResendVerificationEmail(undefined)} />
            </> 
            :
            <>
                {showOwnId && <AldiOwnIdInit/>}
                <form onSubmit={handleSubmit(handleLoginClick)} autoComplete="on">
                    <FormInput type="email" inputMode="email" autoFocus autoComplete="email" defaultValue={email ? email : ""}
                        label={getMessage("global.email.address")}
                        errorDetails={errors.email}
                        readOnly={readOnlyEmail}
                        useFormRegisterReturn={
                            {
                                ...registerEmailResult,
                                ref: copyRefFunc(registerEmailResult.ref, emailInputRef)
                            }
                        }
                    />
                    <fieldset>
                        <label className="textfield input-with-ownid">
                            <PasswordField
                                {...{
                                    error: errors.password,
                                    placeholder: getMessage("global.password"),
                                    autoComplete: "current-password",
                                    ...registerPasswordResult,
                                    ref: copyRefFunc(registerPasswordResult.ref, passwordInputRef)
                                }}
                            />
                            <span className="textfield__label">{getMessage("global.password")}</span>
                            <div className='input-with-ownid__or'>{getMessage("clp.login.loginOrPhysical")}</div>
                            {showOwnId && <AldiOwnIdLogin passwordField={passwordInputRef as React.RefObject<HTMLInputElement>} loginIdField={emailInputRef as React.RefObject<HTMLInputElement>}/>}
                            <ErrorField field={errors.password} />
                        </label>
                    </fieldset>
                    <ErrorField field={errors.root?.serverError} />
                    <Link className="link" to={"/login/reset-password/start"}>{getMessage("global.password.reset.question")}</Link>
                    <button type="submit" className="btn btn--primary btn-full btn-right">{loginButtonText ? loginButtonText : getMessage("clp.login.perform")}</button>
                </form>

                {showSocialLogin && 
                <>
                    <p className='text-center margin-top-bottom labeledSeperator'>{getMessage("clp.login.loginOrSocial")}</p>
                    <SocialLogin afterLogin={afterLogin} />
                </>
                }
            </>
            }
        </LoadingIndicator>
    )
}

const DoiAlert = ({email, backToLogin}: {email: string, backToLogin: () => void}) => {
    const { getMessage } = useI18n();
    const {metaData} = useMetaData();

    const [ verificationEmailResponseStatus, setVerificationEmailResponseStatus] = useState<number>();

    if (!email || !metaData) {
        return <></>;
    }

    const onVerifyLinkClick = async () => {
        const rawResponse = await fetch(metaData.restApi + "/rest/v1/account/registration/verification-email", {
            method: 'PUT',
            headers:{
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: "email="+encodeURIComponent(email)
        });
        setVerificationEmailResponseStatus(rawResponse.status);
    }

    const mapResponseStatusCodeToCssClass = (responseStatus: number) => {
        switch (responseStatus) {
            case 201:
                return "success";
            case 204:
                return "info";
            default:
                return "error";
        }
    }

    return (
        <>
            <p>
                {`${getMessage("clp.login.customError.doi.resend.first")} `}
                <b className='nowrap'>{email}</b>
                {`. ${getMessage("clp.login.customError.doi.resend.second")}`}
            </p>
            <p>
                {`${getMessage("clp.login.customError.doi.resend.third")} `}
                <b><button type="button" className="link link--standard" onClick={onVerifyLinkClick}>{getMessage("clp.login.customError.doi.resend.link")}</button></b>
                {` ${getMessage("clp.login.customError.doi.resend.fourth")}`}
            </p>
            {verificationEmailResponseStatus &&
                <>
                    <div className={`alert alert--${mapResponseStatusCodeToCssClass(verificationEmailResponseStatus)} alert--simple`}>
                        <div className="alert__inner">
                            <div className="alert__content">
                                {getMessage("clp.login.customError.doi.resend.status." + verificationEmailResponseStatus as any, getMessage("clp.login.customError.doi.resend.status.unknown"))}
                            </div>
                        </div>
                    </div>
                </>
            }
            <button type="button" className="btn btn-full btn-right btn--primary" onClick={backToLogin}>{getMessage("clp.login.customError.doi.resend.button")}</button>
        </>
    )
}