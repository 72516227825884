import React, {useEffect, useState, useTransition} from "react";
import {useRegisterWidthThreshold} from "../hooks/Window";

/**
 * Not sure how the force redrawing the OwnId component to prevent styling issues.
 * I found this solution to remove it and add it again.
 *
 * @param children children elements
 * @param widthThresholds window width thresholds to redraw
 */
export const ForceRedraw = ({children, widthThresholds=[]}: {children: React.JSX.Element[] | React.JSX.Element, widthThresholds: number[] | number | undefined}) => {
    const { index } = useRegisterWidthThreshold(widthThresholds);
    const [redrawSwitch, setRedrawSwitch] = useState(false);
    const [isPending, redrawTransition] = useTransition();

    useEffect(() => {
        setRedrawSwitch(true);
        redrawTransition(() => {
            setRedrawSwitch(false);
        })
    }, [index]);

    if (redrawSwitch || isPending) {
        return <></>;
    }

    return (
        <>
            {
                Array.isArray(children) ? children.map((child) => child) : children
            }
        </>
    )
}