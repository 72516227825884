import { NavLink } from "react-router-dom";
import SpriteIcon from '_assets/aldi-essentials-867/assets/brands/aldi/images/sprite.svg';

export interface BreadcrumbData {
    label: string,
    link?: string,
    isCurrent?: boolean
}

export const Breadcrumb = ({items}: {items: BreadcrumbData[]}) => {
    return (
        <div className="mod mod-breadcrumb" data-t-name="Breadcrumb" role="navigation">
            <ol className="mod-breadcrumb__nav">
                {
                    items.map((item,idx) => <BreadcrumbLink key={"item" + idx} data={item} isFirst={idx === 0} />)
                }
            </ol>
        </div>
    )
}

const BreadcrumbLink = ({data, isFirst}: {data:BreadcrumbData, isFirst:boolean}) => {
    if (!data.label) {
        return <></>
    }
    return (
        <li className="mod-breadcrumb__item">
            {!isFirst &&
                <svg className="icon icon--arrow-right" aria-hidden="true">
                    <use href={SpriteIcon + "#arrow-right"}></use>
                </svg>
            }
            { !data.isCurrent && data.link
                ? <NavLink to={data.link}><span>{data.label}</span></NavLink>
                : <span aria-current={data.isCurrent ? "page" : undefined}>{data.label}</span>
            }
        </li>
    )
}