import { useI18n } from '_shared/hooks/I18n';
import { ErrorField } from '_shared/components/ErrorField';
import { FieldErrors, UseFormRegister, useForm } from 'react-hook-form';
import { assertIsGigyaErrorResponse, gigyaWithPromise, useGigya } from 'GigyaContext';
import DOMPurify from "dompurify";
import { Link } from 'react-router-dom';

interface ResetPasswordStartProcessScreenFormData {
    email: string
}

export const ResetPasswordStartProcessScreen = () => {
    const { getMessage, getCdcErrorMessage } = useI18n();
    const { register, handleSubmit, setError, formState: { isSubmitSuccessful, errors }, getValues } = useForm<ResetPasswordStartProcessScreenFormData>();

    const { gigya } = useGigya();

    if (!gigya) {
        return <></>;
    }

    const performResetPassword = async (formData: ResetPasswordStartProcessScreenFormData) => {
        try {
            await gigyaWithPromise(gigya.accounts.resetPassword, {
                loginID: formData.email
            });
            localStorage.setItem("pwResetLoginID", formData.email);
        }
        catch(errorResponse: unknown) {
            console.error(errorResponse);
            assertIsGigyaErrorResponse(errorResponse);
            
            setError("root.serverError", {
                message: getCdcErrorMessage(errorResponse)
            });
        }
    }

    return (
        <>
            <div id="reset-password-start-process">
                <h2>{getMessage("clp.resetpassword.title")}</h2>

                {isSubmitSuccessful && <ResetPasswordStartedScreen email={getValues("email")} />}
                {!isSubmitSuccessful && <ResetPasswordStartScreenForm register={register} errors={errors} onSubmit={handleSubmit(performResetPassword)} />}
            </div>
        </>
    )
}

function ResetPasswordStartedScreen({ email }: {email: string}) {
    const { getMessage } = useI18n();

    const text = getMessage("clp.resetpassword.processstarted", "{0}", [ email ]);

    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}/>
        <Link className="btn btn-full btn--secondary" to={"/login"}>{getMessage("clp.resetpassword.processstarted.back")}</Link>
     </>
    )
}

function ResetPasswordStartScreenForm({ register, errors, onSubmit }: {register: UseFormRegister<ResetPasswordStartProcessScreenFormData>, errors: FieldErrors<ResetPasswordStartProcessScreenFormData>, onSubmit: any}) {
    const { getMessage } = useI18n();
    
    return (<>
        <p>{getMessage("clp.resetpassword.introduction")}</p>
        <form onSubmit={onSubmit} autoComplete="on">
            <fieldset>
                <label className="textfield">
                    <input type="email"
                        className={`textfield__control ${errors.email ? "error" : ""}`}
                        placeholder={getMessage("global.email.address")}
                        autoFocus
                        autoComplete="email"
                        {...register("email", { required: 
                                { value: true, message: getMessage("global.email.required") } }
                            )
                        }
                    />
                    <span className="textfield__label">{getMessage("global.email.address")}</span>
                    <ErrorField field={errors.email} />
                </label>
            </fieldset>
            
            <ErrorField field={errors.root?.serverError} />
             
            <button type="submit" className="btn btn-full btn--primary btn-right mg-b-0">{getMessage("clp.resetpassword.startprocess.submit")}</button>
            <Link className="btn btn-full btn--secondary" to={"/login"}>{getMessage("clp.resetpassword.startprocess.cancel")}</Link>
        </form>
    </>
    )
}