import { AccountFormItemDetail, RegisterFormInputFunction} from "../../hooks/GigyaSchema";
import {MessageKey, useI18n} from "../../_shared/hooks/I18n";
import React, {useId} from "react";
import {ErrorField} from "../../_shared/components/ErrorField";
import {Cms} from "../../CmsContext";

export const CountryComponent= ({registerFormInput, formItemDetails, cmsInputField} : {registerFormInput:RegisterFormInputFunction<any>, formItemDetails:Map<string, AccountFormItemDetail<any>>, cmsInputField:Cms.ProfileFieldCountry}) => {
    const { getMessage } = useI18n();

    const countryInputId = useId();
    const countryErrorFieldId = useId();
    const countryDescriptionId = useId();

    let {useFormRegisterReturn:country_rr, errorDetails:country_ed, ...country_props} = registerFormInput(formItemDetails.get(cmsInputField.cdcProperty)!);

    const options: React.ReactNode[] = [];
    cmsInputField.options.map((value, index) => {
        options.push(<option value={value}>{getMessage("address.country." + value + ".name" as MessageKey, value)}</option>);
        return index;
    });

    return (
        <label className={`textfield textfield--select ${country_ed ? "has-error" : ""}`}>
            <select id={countryInputId} className="textfield__control" {...country_props} {...country_rr}
                    aria-invalid={country_ed ? true : undefined}
                    aria-errormessage={country_ed ? String(countryErrorFieldId) : undefined}
                    aria-describedby={countryDescriptionId}
            >
                {
                    country_rr.required || <option value="">{cmsInputField.empty}</option>
                }
                {options}
            </select>
            <span id={countryDescriptionId} className="textfield__label">{cmsInputField.label}</span>
            <ErrorField id={countryErrorFieldId} field={country_ed} />
        </label>
    )
}