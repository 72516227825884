import SpriteSvg from '_assets/aldi-essentials-867/assets/brands/aldi/images/sprite.svg';
import "./Footer.css";
import {Cms} from "../../CmsContext";
import {useI18n} from "../../_shared/hooks/I18n";

export const Footer = ({footer, socialMediaBar}:{footer:Cms.FooterArea[], socialMediaBar: Cms.SocialMediaBar}) => {
    const { currentLanguage } = useI18n();

    const cookieConsentLinkClick = function (event:any) {
        event.preventDefault();
        // @ts-ignore
        if (window.UC_UI) {
            // @ts-ignore
            window.UC_UI.showSecondLayer();
        }
    }   

    return (
        <footer className="mod mod-footer footer-mpc" role="contentinfo">
            {footer && <div className="container">
                <ul className="mod-footer__list">
                    {footer.map((footerarea, footerareaidx) => {
                        return (
                            <li key={currentLanguage + footerarea.headlinelink.id + footerareaidx}>
                                <div className="mod-fheadlinelinkooter__area">
                                    {footerarea.headlinelink.url ? <a href={footerarea.headlinelink.url} title={footerarea.headlinelink.label} target={footerarea.headlinelink.openblank ? "_blank" : "_self"} className="mod-footer__title" rel="noreferrer">{footerarea.headlinelink.label}</a> 
                                        : <span className="mod-footer__title">{footerarea.headlinelink.label}</span>
                                    }
                                    <ul>
                                        {footerarea.defaultlinks.map((footerlink, idx) => {
                                            return (
                                                <li key={currentLanguage + footerlink.id + idx}>
                                                    <a href={footerlink.url} title={footerlink.label} target={footerlink.openblank ? "_blank" : "_self"} data-attr-name="Footer Navigation - Level 1" data-attr-value={footerlink.label} data-attr-type="footerLink" rel="noreferrer">{footerlink.label}</a>
                                                </li>
                                            )
                                        })}
                                        {footerarea.cookieconsentlink && 
                                            <li>
                                                <a onClick={cookieConsentLinkClick} href="#cookieconsent" title={footerarea.cookieconsentlink.label} data-attr-name="Footer Navigation - Level 1" data-attr-value={footerarea.cookieconsentlink.label} data-attr-type="footerLink">{footerarea.cookieconsentlink.label}</a>
                                            </li>}
                                    </ul>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>}
            {socialMediaBar && <div className="container">
                <div className="mod mod-social-media-bar">
                    <div className="mod-social-media-bar__items">
                        <span className="heading-h5 mod-social-media-bar__title">{socialMediaBar.headline}</span>
                        {socialMediaBar.socialmedialinks.map((socialmedialink, socialmedialinkidx) => {
                            return (
                                <a key={socialmedialinkidx} href={socialmedialink.url} title={socialmedialink.alt} target={socialmedialink.openblank ? "_blank" : "_self"} className="mod-social-media-bar__link" rel="noreferrer">
                                    <svg aria-hidden={true} className={`icon icon--${socialmedialink.icon}`}>
                                        <use href={`${SpriteSvg}#${socialmedialink.icon}`}>
                                        </use>
                                    </svg>
                                </a>
                            )
                        })}
                    </div>
                </div>
            </div>}
        </footer>
    );
}