import {useId, useState} from "react"
import {ErrorData, ErrorField} from "./ErrorField"
import './FormInput.css'
import SpriteIcon from '_assets/aldi-essentials-867/assets/brands/aldi/images/sprite.svg';
import {FocusEventHandler,InputHTMLAttributes} from "react";
import {FieldValues, UseFormTrigger} from 'react-hook-form';

export enum LoadingState {
    INCOMPLETE,
    CHECKING,
    FAILED,
    FINISHED
}

export const FormfieldLoadingIndicator = () => {
    return (
        <div className={`formfield-indicator formfield-indicator--loading show`}></div>
    )
}

export const FormfieldVerifiedIndicator = () => {
    return (
        <div className={`formfield-indicator formfield-indicator--verified show`}>
            <svg className="icon" aria-hidden="true">
                <use href={SpriteIcon + "#verified"}></use>
            </svg>
        </div>
    )
}

export interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label:string,
    errorDetails:ErrorData,
    additionalInputClasses?:string,
    type?:string,
    useFormRegisterReturn:any,
    onInputCheck?: ((event:any) => Promise<LoadingState>),
    onInputCheckClasses?: ((loadingState:LoadingState) => string),
    autoComplete?:string,
    readOnly?:boolean,
    required?: boolean,
    trigger?: UseFormTrigger<FieldValues>
}

/**
 * Typical form component with error handling
 * @param {*} param0 
 * @returns 
 */
export const FormInput = ( {label, errorDetails, additionalInputClasses, type, useFormRegisterReturn, trigger, onInputCheck, onInputCheckClasses, autoComplete, readOnly, required, ...inputProps }: FormInputProps ) => {
    const inputId = useId();
    const errorFieldId = inputId + "_error";

    const [loadingState, setLoadingState] = useState(LoadingState.INCOMPLETE);

    const onInput = function(event: any) {
        if (onInputCheck) {
            setLoadingState(LoadingState.CHECKING);
            onInputCheck(event).then(setLoadingState).catch(() =>
                setLoadingState(LoadingState.FAILED)
            );
        }
    }

    const onFocusOut: FocusEventHandler<HTMLInputElement> = async function (_event) {
        if (trigger) {
            const name = useFormRegisterReturn.name ?? inputProps.name;
            await trigger(name);
        }
    }

    return (
        <>
            <label htmlFor={inputId} className={`textfield ${errorDetails ? "has-error" : ""} ${type === "hidden" ? "hidden" : ""}`}>
                <input id={inputId}
                    type={type?type:"text"}
                    className={`textfield__control ${errorDetails ? "error" : ""} ${additionalInputClasses?additionalInputClasses:""} ${onInputCheckClasses? onInputCheckClasses(loadingState): ""}`}
                    spellCheck="false"
                    aria-errormessage={errorDetails ? errorFieldId : null}
                    aria-invalid={errorDetails ? true : null}
                    placeholder={label}
                    onInput={onInput}
                    {...inputProps}
                    autoComplete={autoComplete ? autoComplete : null}
                    readOnly={readOnly ? readOnly : null}
                    {...useFormRegisterReturn}
                    onBlur={onFocusOut}
                    data-attr-value={label}
                />
                <span className="textfield__label">
                    {label}
                    {required && <abbr className="required">*</abbr>}
                    </span>
                {loadingState === LoadingState.CHECKING && <FormfieldLoadingIndicator/>}
                {loadingState === LoadingState.FINISHED && <FormfieldVerifiedIndicator/>}
                <ErrorField id={errorFieldId} field={errorDetails} />
            </label>
        </>
    )
}