import {Helmet} from "react-helmet-async";
import {useI18n} from "../hooks/I18n";
import React, {memo, useMemo, useEffect} from "react";
import {useMetaData} from "../../MetaDataContext";

export type UserCentricsComponentProps = {
    pageType: string,
    pageName: string,
    primaryCategory: string,
    subCategory1?: string,
    subCategory2?: string,
    subCategory3?: string
}

export const UserCentricsComponent = memo(({pageType, pageName, primaryCategory, subCategory1, subCategory2, subCategory3}: UserCentricsComponentProps)=> {
    const {metaData} = useMetaData();
    const {currentCountry, currentLanguage} = useI18n();

    const digitalData = useMemo(() => {
        return ({
            page: {
                pageInfo: {
                    pageName: pageName,
                    country: currentCountry,
                    environment: metaData?.environmentType,
                    currency: metaData?.currency,
                    language: currentLanguage?.toUpperCase(),
                    siteName: "n/a"
                },
                pageCategory: {
                    pageType: pageType,
                    primaryCategory: primaryCategory,
                    subCategory1: subCategory1 || "n/a",
                    subCategory2: subCategory2 || "n/a",
                    subCategory3: subCategory3 || "n/a"
                }
            }
        })
    }, [pageName, currentCountry, metaData?.environmentType, metaData?.currency, currentLanguage, pageType, primaryCategory, subCategory1, subCategory2, subCategory3])

    useEffect(() => {
        // @ts-ignore
        if (window.UC_UI) {
            // @ts-ignore
            window.UC_UI.updateLanguage(currentLanguage)
                .then(() => console.log("changed UC_UI language to " + currentLanguage))

        }
    }, [currentLanguage]);

    if (!metaData || !metaData.userCentrics || !metaData.userCentrics.settingsId || !digitalData) {
        return <></>;
    }
    return (
        <Helmet>
            <script id="usercentrics-cmp" data-settings-id={metaData.userCentrics.settingsId} src="https://app.usercentrics.eu/browser-ui/latest/bundle.js"></script>
            <script>{`var digitalData = ${JSON.stringify(digitalData)};`}</script>
        </Helmet>
    )
})