import {AccountProvider, hasNewsletterSubscriptions, useAccount} from "Account";
import {GigyaProvider, useGigya} from "GigyaContext";
import {fetchWithErrorHandling} from "_shared/Utils";
import LoadingScreen from "central-page/LoadingScreen";
import {Dispatch, createContext, useCallback, useContext, useEffect, useState} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {ErrorPagePreferences} from "./ErrorPagePreferences";
import {useMetaData} from "../MetaDataContext";
import { ProductReminderAction, ProductReminderContext, useProductReminderReducer, useProductReminders } from "./components/productreminder";

export type RefreshTokenFunction = () => Promise<void>;

interface MarketingPreferencesData {
    regToken: string,
    createdAt: string
    expiresAt: string
}

interface MarketingPreferencesResult {
    regToken: string,
    regTokenCreationTime: Date
    regTokenExpirationTime: Date
    //uberallCache: DataType..
}

interface MarketingPreferencesContext extends MarketingPreferencesResult {
    jwt: string,
    refreshToken: RefreshTokenFunction,
    productReminderContext: ProductReminderContext,
    dispatchProductReminderContext: Dispatch<ProductReminderAction>
}

const marketingPreferencesContext = createContext<MarketingPreferencesContext>({} as MarketingPreferencesContext);

const fetchMarketingPreferences = async (restApi: string, token: string): Promise<MarketingPreferencesResult> => {
    
    let rawResponse;
    try {
        rawResponse = await fetchWithErrorHandling(restApi + "/rest/v1/subscriber/regToken/jwt", {
            method: 'POST',
            body: new URLSearchParams({jwt: token})
        });
    }
    catch (error: unknown) {
        throw new Error("regToken fetch failed.", { cause: error });
    }
    
    const marketPreferencesData = await rawResponse.json() as MarketingPreferencesData;

    return {
        regToken: marketPreferencesData.regToken,
        regTokenCreationTime: new Date(marketPreferencesData.createdAt),
        regTokenExpirationTime: new Date(marketPreferencesData.expiresAt)
    };
}

export const MarketingPreferencesProvider = () => {
    const { token } = useParams();
    const { metaData } = useMetaData();
    const navigate = useNavigate();

    if (!token || (metaData && !metaData.marketingPreferenceCenter)) {
        navigate("/preferences/error/notfound");
        return <></>
    }

    return (
    <GigyaProvider type="MarketingPreferences">
        <MarketingPreferencesContent token={token} />
    </GigyaProvider>
    );
}

const startTokenReloadTimeout = (startReload:(arg:Date)=>void, regTokenCeationTime:Date|undefined, regTokenExpirationTime:Date|undefined): NodeJS.Timeout => {
    const timeout = Math.max(10 * 1000, // 10 sec minimum
        (
            (regTokenCeationTime !== undefined && regTokenExpirationTime !== undefined) ?
            (regTokenExpirationTime.getTime() - regTokenCeationTime.getTime()) :
            (3600 * 1000)
        ) - (10 * 1000)); // 10sec before
    //console.log("timeout: " + timeout);
    return setTimeout(()=> {
        startReload(new Date());
    }, timeout)
}

const MarketingPreferencesContent = ( {token}: {token: string} ) => {

    const { isGigyaReady } = useGigya();
    const { metaData } = useMetaData();
    const [ marketingPreferences, setMarketingPreferences ] = useState<MarketingPreferencesResult>();
    const [ error, setError] = useState<string>();
    const [reloadToken, setReloadToken] = useState<Date>(new Date());
    const [productReminderContext, dispatchProductReminderContext ] = useProductReminderReducer();

    useEffect(() => {
        const timeoutId = startTokenReloadTimeout(setReloadToken, marketingPreferences?.regTokenCreationTime, marketingPreferences?.regTokenExpirationTime);
        return () => clearTimeout(timeoutId);
    }, [marketingPreferences]);

    useEffect(() => {
        if (!metaData) {
            return;
        }
        fetchMarketingPreferences(metaData.restApi, token)
            .then((prefs)=>{
                setMarketingPreferences({
                    regToken: prefs.regToken,
                    regTokenCreationTime: prefs.regTokenCreationTime,
                    regTokenExpirationTime: prefs.regTokenExpirationTime
                });
            })
            .catch(e => {
                console.error("Unexpected error while loading market preferences", e);
                setError("Unexpected error while loading market preferences");
            });
    }, [token, metaData, reloadToken]);

    const refreshToken = useCallback(() => new Promise<void>((resolve, _reject) => {setReloadToken(new Date()); resolve()}), []);

    if (error) {
        return <ErrorPagePreferences errorCode="preferences.loading" />
    }

    if (!isGigyaReady() || !marketingPreferences) {
        return <LoadingScreen />
    }

    const ctx = {
        jwt: token,
        ...marketingPreferences,
        refreshToken,
        productReminderContext,
        dispatchProductReminderContext
    };

    return (
        <marketingPreferencesContext.Provider value={ctx}>
            <AccountProvider cdcIncludeFields="data,preferences,profile,subscriptions">
                <GateKeeper />
            </AccountProvider>
        </marketingPreferencesContext.Provider>
    );
}

const GateKeeper = () => {

    const { loadAccount, account } = useAccount();
    const { regToken } = useMarketingPreferences();
    const navigate = useNavigate();

    useEffect(() => {
        loadAccount(regToken).catch(console.log)
    }, [regToken, loadAccount]);

    const { data: productReminders, isLoading: productReminderStillLoading } = useProductReminders();

    const accountLoaded = !!account.data?.sfmc?.subscriptionKey;

    useEffect(() => {
        // only show opt-in dialog if the customer is completely loaded
        if (!accountLoaded) {
            return;
        }
        
        //only fetch / check for product reminders, if the customer has no active newsletter subscriptions
        if (!hasNewsletterSubscriptions(account)) {
            if (productReminderStillLoading) {
                return;
            }
            
            //it doesn't matter if the PR-Call failed or not. If it failed, we can't do anything in the CPC anyway
            const redirectToUnsubscribedPage =  (productReminders?.length ?? 0) === 0;
            if (redirectToUnsubscribedPage) {
                navigate("/unsubscribe-success");
            }
        }
    }, [ account, accountLoaded, productReminderStillLoading, navigate, productReminders ]);

    // only show preference center, if account was loaded
    if (!accountLoaded) {
        return <LoadingScreen />;
    }

    return <Outlet />
}

export const useMarketingPreferences = () => useContext(marketingPreferencesContext);