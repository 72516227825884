import {Address} from "../../_shared/components/Address";
import {useI18n} from "../../_shared/hooks/I18n";
import { useCmsData } from "CmsContext";
import {Breadcrumb, BreadcrumbData} from "./shared-components/Breadcrumb";
import {useMemo, useState} from "react";
import { SuccessMessage } from "./shared-components/SuccessMessage";

export const Addresses = () => {
    const {getMessage} = useI18n();
    const {cmsData} = useCmsData();
    const [showSuccess, setShowSuccess] = useState(false);

    const breadcrumbItems = useMemo<BreadcrumbData[]>(() =>
        ([
            {
                label: getMessage("ap.startpage"),
                link: "/"
            },
            {
                label: getMessage("ap.account.addresses.title"),
                isCurrent: true
            }
        ]),[getMessage]
    );

    if (!cmsData) {
        return <></>;
    }

    return (
        <div id="addresses">
            <Breadcrumb items={breadcrumbItems} />
            <SuccessMessage show={showSuccess} message={getMessage("ap.account.addresses.success")} />
            <h2>{getMessage("ap.account.addresses.title")}</h2>
            <p>{getMessage("ap.account.addresses.description")}</p>
            <Address setShowSuccess={setShowSuccess}/>
        </div>
    )
}