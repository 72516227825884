import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { useCmsData } from "CmsContext";
import {MessageKey, useI18n} from "../_shared/hooks/I18n";
import {UserCentricsComponent} from "../_shared/components/UserCentricsComponent";
import {AdobeTagManagerComponent} from "../_shared/components/AdobeTagManagerComponent";
import {Meta} from "../_shared/components/Meta";
import { CDNResponsiveImage } from "_shared/components/CDNResponsiveImage";

/**
 * Double Opt In page for CPC (Product Reminder Service).
 *
 * @param headline
 * @param description
 * @constructor
 */
export const DOIPagePreferences = ({ headline, description }: { headline: string, description: string } ) => {
    const {cmsData} = useCmsData();
    const { getMessage } = useI18n();

    if (!cmsData) {
        return <></>;
    }

    return (
        <div className="preference-center">
            <Meta titleProperty="error.page.title" />
            <UserCentricsComponent pageType={"Double Opt In Page"} pageName={headline} primaryCategory={"Double Opt In Page"} subCategory1={headline} />
            <AdobeTagManagerComponent />
            <Header header={cmsData.components.header} />
            <div className="page__main" role="main">
                <div className="page__content">
                    { cmsData.components.preferenceCenter.doubleoptinimage &&
                        <div className="container-full">
                            <div className="mod mod-overview-intro-2">
                                <div className="mod-overview-intro-2__media">
                                    <CDNResponsiveImage
                                        className="img-responsive img-cover cq-dd-image"
                                        title={cmsData.components.preferenceCenter.doubleoptinimage.alt}
                                        alt={cmsData.components.preferenceCenter.doubleoptinimage.alt}
                                        sizes="(min-width: 1817px) 1817px, 100vw"
                                        src={cmsData.components.preferenceCenter.doubleoptinimage.src}
                                        widths={[768, 1024, 1817]} // no other sizes in germany: could be added "3000" for retina displays
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="container">
                        <div className="mod mod-headline">
                            <h1 data-binding="true">{getMessage(headline as MessageKey, "Bestätigt")}</h1>
                        </div>
                        <div className="mod mod-copy">
                            <div className="rte">
                                <p>{getMessage(description as MessageKey, "Das Double Opt In wurde erfolgreich bestätigt.")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {cmsData && <Footer footer={cmsData.components.footer} socialMediaBar={cmsData.components.socialmediabar}></Footer>}
        </div>
    );
} 