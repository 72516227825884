import { Meta } from '_shared/components/Meta';
import { SimpleFooter } from '_shared/components/SimpleFooter';
import Header from './shared-components/Header';
import { ReactNode } from 'react';

/**
 * Simple Layout rendering the two ALDI Logos
 * @returns 
 */
export const SimpleAccountLayout = ({children, divId, mobileView} : {children: ReactNode, divId: string, mobileView?: boolean}) => {
    return (
        <>
            <div className={`page__main ${mobileView ? "mobile-view" : ""}`} role="main">
                <div className="page__content">
                    <div className="container">
                        <Meta titleProperty="clp.page.title" />
                        <Header />
                        <div id={divId}>
                            {children}
                        </div>
                    </div>
                </div>
                <SimpleFooter />
            </div>
        </>
      )
}