import {DetailedHTMLProps, InputHTMLAttributes, forwardRef, useId} from "react";
import SpriteIcon from "../../_assets/aldi-essentials-867/assets/brands/aldi/images/sprite.svg";
import {ReactComponent as FishIcon} from "../../_assets/icons/fish.svg";
import "./SwitcherComponent.css";

interface SwitcherComponentProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    icon?: string,
    headline:string,
    descriptions?: string[],
    defaultChecked?: boolean
}

/**
 * Switch component to render a switch with a headline and an optional description text.
 * If the switcher is changed you can set one or more (hidden) checkbox input elements. So you can set/unset more than one value.
 *
 * @param icon an icon
 * @param headline headline
 * @param descriptions array of descriptions, if more than one render as list, can be undefined
 * @param defaultChecked the current value
 * @param onChange A handler called, when the input is changed by the user.
 */
export const SwitcherComponent = forwardRef<HTMLInputElement, SwitcherComponentProps>(
    ( {icon, headline, descriptions, defaultChecked, children, ...additionalInputProps }, ref) => {

    const inputId = useId();
    const labelId = useId();

    return (
        <>
            <div className="mod mod-accordion mod-accordion--primary">
                <label className="checkbox" htmlFor={inputId}>
                    <input id={inputId} defaultChecked={defaultChecked} type="checkbox" data-attr-value={headline} aria-describedby={labelId} ref={ref} {...additionalInputProps} />
                    <span aria-hidden={true}></span>
                </label>
                <details className={`mod-accordion__item ${!descriptions ? "block-accordion" : ""}`}>
                    <summary className="mod-accordion__head" style={{gridTemplateColumns: `${icon ? "40px" : "0"} ${headline ? "auto" : "0"} ${descriptions ? "50px" : "0"}`}}>
                        { descriptions &&
                            <svg aria-hidden={true} className="icon icon--arrow-down icon--xs mod-accordion__arrow"
                            >
                                <use href={`${SpriteIcon}#arrow-down`}>
                                </use>
                            </svg>
                        }
                        {(icon==='fish')?
                            <FishIcon aria-hidden={true} />
                            :
                            icon && <svg aria-hidden={true} className={`icon icon--${icon}`}>
                                <use href={`${SpriteIcon}#${icon}`}>
                                </use>
                            </svg>
                        }
                        <span id={labelId} className="mod-accordion__headline">{headline}</span>
                    </summary>
                    { descriptions &&
                        <div className="mod-accordion__body">
                            <div className="rte copy copy--50">
                                {descriptions.length > 1 && <ul> {
                                    descriptions.map(value => {
                                        return(<li key={value}>{value}</li>)
                                    })
                                } </ul>}
                                {descriptions.length === 1 && descriptions[0]}
                            </div>
                        </div>
                    }
                </details>
            </div>
            {children && <div className="mod mod-accordion-childrens">
                {children}
            </div>}
        </>
    );
});