import {createContext, MutableRefObject, ReactNode, useContext, useEffect, useRef, useState} from "react";
import {Meta, useMetaData} from "./MetaDataContext";
import { AccountData, AccountPreferences, AccountProfile, AccountSubscriptions } from "Account";
import { DeepPartial } from "react-hook-form";

declare global {
    var gigya: GigyaWebSdk.Gigya | undefined;
    var onGigyaServiceReady: OnGigyaReadyCallback;
  }

/**
 * All Types describing the Gigya WebSDK. Shouldn't be used much our side in order to move to a
 * declaration provided by SAP - if ever available
 * Currently these types are related to our types used internally, but this can change
 */
export namespace GigyaWebSdk {
    export interface Accounts {
        setAccountInfo: ApiFunctionSetAccountInfo,
        getAccountInfo: ApiFunctionGetAccountInfo,
        socialLogin: ApiFunctionSocialLogin,
        login: ApiFunctionLogin,
        logout: ApiFunctionLogout,
        finalizeRegistration: ApiFunctionFinalizeRegistration,
        getSchema: ApiFunctionGetSchema,
        getPolicies: ApiFunctionGetPolicies,
        getSiteConsentDetails: ApiFunctionGetSiteConsentDetails,
        // showScreenSet: NotDefinedApiFunction,
        addEventHandlers: ApiFunctionAddEventHandler,
        resetPassword: ApiFunctionResetPassword,
        register: ApiFunctionRegister,
        getConflictingAccount: ApiFunctionGetConflictingAccount,
        initRegistration: ApiFunctionInitRegistration,
        session: {
            verify: ApiFunctionVerify
        }
    }

    export interface Gigya {
        accounts: Accounts,
        socialize: any,
        hasSession: () => Promise<boolean>,
        isReady: boolean,
        sso: any
    }

    export interface ApiFunctionCallback<Response extends ApiResponse> {
        callback: (response: Response) => void
    }

    export type WithCallback<Args, Response extends ApiResponse> = Args & ApiFunctionCallback<Response>

    export interface ApiFunctionArgsAccountsRegister {
        data?: DeepPartial<AccountData>,
        include?: string,
        finalizeRegistration?: boolean,
        lang?: string,
        preferences?: AccountPreferences,
        profile?: DeepPartial<AccountProfile>,
        regSource?: string
        regToken?: string,
        subscriptions?: AccountSubscriptions,
        ignoreInterruptions: boolean
    }

    export interface ApiFunctionArgsSetAccountInfo {
        data?: DeepPartial<AccountData>,
        lang?: string,
        preferences?: AccountPreferences,
        profile?: DeepPartial<AccountProfile>,
        regToken?: string,
        subscriptions?: AccountSubscriptions,
        ignoreInterruptions?: boolean
    }

    export interface ApiFunctionArgsSetAccountInfoChangePassword {
        password: string,
        newPassword: string
    }

    export interface ApiFunctionArgsGetConflictingAccount {
        regToken: string
    }

    export interface ApiFunctionArgsFinalizeRegistration {
        regToken: string
    }

    export interface ApiFunctionArgsSocialLogin {
        provider: string
    }

    export interface ApiFunctionArgsGetPolicies {
        sections: string
    }

    export interface ApiFunctionArgsResetPasswordStep1 {
        passwordResetToken: string,
        newPassword: string
    }

    export interface ApiFunctionArgsResetPasswordStep2 {
        loginID: string
    }

    export type ApiFunctionArgsResetPassword = ApiFunctionArgsResetPasswordStep1 | ApiFunctionArgsResetPasswordStep2;

    export interface ApiFunctionArgsLogin {
        loginID: string,
        password: string
        ignoreInterruptions: boolean,
        loginMode?: string,
        regToken?: string,
        include?: string,
    }

    export interface ApiFunctionArgsGetAccountInfo {
        include?: string,
        extraProfileFields?: string,
        regToken?: string
    }

    export interface ApiFunctionArgsAddEventHandler {
        onLogin?: EventHandlerOnLoginFunction,
        onLogout?: EventHandlerOnLogoutFunction
    }

    export type ApiFunction<Arg extends object | undefined, Response extends ApiResponse> = (args: Arg) => Response;
    export type ApiFunctionWithCallback<Arg extends object | undefined, Response extends ApiResponse> = ApiFunction<WithCallback<Arg, Response>, Response>;

    export type EmptyApiFunction<Response extends ApiResponse> = ApiFunctionWithCallback<{} | undefined, Response>;
    export type EmptyApiFunctionWithStandardResponse = EmptyApiFunction<ApiResponse>;

    export type ApiFunctionGetSchema = EmptyApiFunction<AccountsGetSchemaResponse>;

    export type ApiFunctionGetConflictingAccount = ApiFunctionWithCallback<ApiFunctionArgsGetConflictingAccount, AccountsGetConflictingAccountResponse>;

    export type ApiFunctionRegister = ApiFunctionWithCallback<ApiFunctionArgsAccountsRegister, ApiResponse>;

    export type ApiFunctionInitRegistration = ApiFunctionWithCallback<{isLite?: boolean}, AccountsInitRegistrationResponse>;

    export type ApiFunctionLogout = EmptyApiFunctionWithStandardResponse;

    export type ApiFunctionVerify = EmptyApiFunctionWithStandardResponse;

    export type ApiFunctionFinalizeRegistration = ApiFunctionWithCallback<ApiFunctionArgsFinalizeRegistration, ApiResponse>;

    export type ApiFunctionSocialLogin = ApiFunctionWithCallback<ApiFunctionArgsSocialLogin, ApiResponse>;

    export type ApiFunctionGetPolicies = ApiFunctionWithCallback<ApiFunctionArgsGetPolicies, AccountsGetPoliciesResponse>;

    export type ApiFunctionResetPassword = ApiFunctionWithCallback<ApiFunctionArgsResetPassword, ApiResponse>;

    export type ApiFunctionLogin = ApiFunctionWithCallback<ApiFunctionArgsLogin, ApiResponse>;

    export type ApiFunctionGetAccountInfo = ApiFunctionWithCallback<ApiFunctionArgsGetAccountInfo, GetAccountInfoResponse>;

    export type ApiFunctionAddEventHandler = (args: ApiFunctionArgsAddEventHandler) => void;

    export type ApiFunctionGetSiteConsentDetails = EmptyApiFunction<AccountsGetSiteConsentDetailsResponse>;

    export type ApiFunctionSetAccountInfo = ApiFunctionWithCallback<ApiFunctionArgsSetAccountInfo | ApiFunctionArgsSetAccountInfoChangePassword, ApiResponse>;

    export interface ApiResponse {
        errorCode: string | number
        status: string
    }

    export interface AccountsGetConflictingAccountResponse extends ApiResponse {
        conflictingAccount: {
            loginID: string
        }
    }

    export interface GigyaErrorResponse extends ApiResponse {
        errorMessage: string,
        errorDetails: string,
        validationErrors: Array<any>,
        customMessage?: string
    }
    
    export interface AccountsGetSchemaResponse extends ApiResponse {
        dataSchema: AccountDataSchema,
        preferencesSchema: AccountPreferencesSchema,
        profileSchema: AccountDataSchema,
        subscriptionsSchema: any
    }

    export interface AccountsGetPoliciesResponse extends ApiResponse {
        passwordComplexity?: PasswordComplexity
    }

    export interface AccountsGetSiteConsentDetailsResponse extends ApiResponse {
        siteConsentDetails: any
    }

    export interface AccountsInitRegistrationResponse extends ApiResponse {
        regToken: any
    }

    export interface LoginResponse extends ApiResponse {
        UID?: string,
        profile: any,
        data: any,
        preferences: any,
        subscriptions?: any,
        newUser?: boolean
    }

    export interface GetAccountInfoResponse extends ApiResponse {
        UID?: string,
        profile: any,
        data: any,
        preferences: any,
        subscriptions?: any
    }

    export interface AccountDataSchema {
        fields: Record<string,  BaseAccountSchemaField>
    }

    export interface AccountPreferencesSchema {
        fields: {[key: string]: BaseAccountSchemaField}
    }
    
    export interface BaseAccountSchemaField {
        required: boolean,
        format: string,
        type: string
    }

    export type EventHandlerOnLogoutFunction = () => void;

    export type EventHandlerOnLoginFunction = (loginData: LoginResponse) => void
}

type OnGigyaReadyCallback = (gigyaContext: Omit<GigyaContext, "onReadyCallbackRegistry">) => Promise<void>;

interface OnReadyCallbackRegistry {
    addListener: any,
    removeListener: any
}

type OnReadyCallbackListenerRef = MutableRefObject<OnGigyaReadyCallback[]>;

export interface AccountSchema {
    dataSchema: GigyaWebSdk.AccountDataSchema,
    profileSchema: GigyaWebSdk.AccountDataSchema,
    subscriptionsSchema: GigyaWebSdk.AccountDataSchema,
}

export interface AccountPolicies {
    passwordComplexity: PasswordComplexity;
}

export interface PasswordComplexity {
    minCharGroup: number,
    minLength: number,
    regExp: string
}

export type SiteConsentDetails = {[key: string]: SiteConsentDetail}

export interface SiteConsentDetail {
    isActive: boolean,
    isMandatory: boolean,
    legalStatements: {[key:string]: LegalStatement}
}

export interface LegalStatement {
    documentUrl: string
}

type AppType = "CLP" | "MarketingPreferences"

interface GigyaContext {
    /**
     * "gigya" from CDC WebSDK. Always use this and don't use window.gigya
     * Null, if gigya has not been fully initialized and when isGigyaReady is false
     */
    gigya: GigyaWebSdk.Gigya | null,
    /**
     * The locale gigya was initialized with. E.g. "de_DE"
     * Null, if gigya has not been fully initialized and when isGigyaReady is false
     */
    locale: string | null,
    /**
     * The language gigya was initialized with. E.g. "de"
     */
    gigyaLanguage: string | null,

    /**
     * true, if gigya has not been fully initialized and when isGigyaReady is false
     * @returns true if gigya is ready for use.
     */
    isGigyaReady: () => boolean,
    /**
     * Retrieves the gigya account schema for the current site. If it has already been requested once, a cached value is returned.
     * undefined, if gigya has not been fully initialized and when isGigyaReady is false
     * @returns 
     */
    getSchema?: () => Promise<AccountSchema>,
    /**
     * Retrieves the gigya policies object for the current site. If it has already been requested once, a cached value is returned.
     * undefined, if gigya has not been fully initialized and when isGigyaReady is false
     * @returns 
     */
    getPolicies?: () => Promise<AccountPolicies>,
    /**
     * Retrieves the consent details. If it has already been requested once, a cached value is returned.
     * undefined, if gigya has not been fully initialized and when isGigyaReady is false
     * @returns 
     */
    getSiteConsentDetails?: () => Promise<SiteConsentDetails>,
    /**
     * Registry to add callbacks when gigya is fully initialized and ready for use.
     * @returns 
     */
    onReadyCallbackRegistry: OnReadyCallbackRegistry
}

const gigyaScriptId = "gigyaScript";

const gigyaContext = createContext<GigyaContext>({isGigyaReady: () => false} as GigyaContext);

const extractLanguageFromLocale = (locale: string | null) => {
    if (locale == null) {
        return locale;
    }
    
    const idx = locale.indexOf("-");
    return idx >= 0 ? locale.substring(0, idx) : locale;
}

const fetchSchema = async (gigya : GigyaWebSdk.Gigya) => {
    try {
        const response = await gigyaWithPromise(gigya.accounts.getSchema, {});
        return {
            dataSchema: response.dataSchema,
            profileSchema: response.profileSchema,
            subscriptionsSchema: response.subscriptionsSchema
        }
    }
    catch (error: unknown) {
        if (isGigyaErrorResponse(error)) {
            throwGigyaResponseErrorDetails("Couldn't load account schema", error);
        }
        throw error;
    }
}

const fetchPolicies = async (gigya: GigyaWebSdk.Gigya) => {
    try {
        const response = await gigyaWithPromise(gigya.accounts.getPolicies, {sections: "passwordComplexity"});
        return {
            passwordComplexity: response.passwordComplexity!
        }
    }
    catch (error: unknown) {
        if (isGigyaErrorResponse(error)) {
            throwGigyaResponseErrorDetails("Couldn't load account policies", error);
        }
        throw error;
    }
}

const fetchSiteConsentDetails = async (gigya: GigyaWebSdk.Gigya): Promise<SiteConsentDetails> => {
    try {
        const response = await gigyaWithPromise(gigya.accounts.getSiteConsentDetails, {});
        return response.siteConsentDetails;
    }
    catch (error: unknown) {
        if (isGigyaErrorResponse(error)) {
            throwGigyaResponseErrorDetails("Couldn't load site consent details", error);
        }
        throw error;
    }
}

const createCacheForAsyncCall = <T,>(gigya: GigyaWebSdk.Gigya | null, dataFetcher: (gigya: GigyaWebSdk.Gigya) => Promise<T>): undefined | (() => Promise<T>) => {
    if (gigya == null) {
        return undefined;
    }
    
    let cache: T | null = null;

    return async () => {
      if (cache == null) {
          cache = await dataFetcher(gigya);
      }
      
      return cache;
    }
}

const createOnReadyCallbackRegistry = (ctx: Omit<GigyaContext, "onReadyCallbackRegistry">, gigyaScriptLoadListenersRef: OnReadyCallbackListenerRef): OnReadyCallbackRegistry => {

    return {
        addListener: (item: OnGigyaReadyCallback) => {
            //call the listener immediately if gigya is already ready
            if (ctx.isGigyaReady()) {
                item(ctx).catch(e => console.error(e));
            }
            gigyaScriptLoadListenersRef.current.push(item)
        },
        removeListener: (item: OnGigyaReadyCallback) => gigyaScriptLoadListenersRef.current.splice(gigyaScriptLoadListenersRef.current.indexOf(item))
    }
}

const buildContextObject = (gigya: GigyaWebSdk.Gigya | null, gigyaLocale: string | null, gigyaScriptLoadListenersRef: OnReadyCallbackListenerRef): GigyaContext => {
    const ctx = {
        gigya : gigya,
        locale: gigyaLocale,
        gigyaLanguage:  extractLanguageFromLocale(gigyaLocale),
        isGigyaReady: () => !!gigya?.isReady,
        getSchema: createCacheForAsyncCall(gigya, fetchSchema),
        getPolicies: createCacheForAsyncCall(gigya, fetchPolicies),
        getSiteConsentDetails: createCacheForAsyncCall(gigya, fetchSiteConsentDetails)
    };

    const onReadyCallbackRegistry = createOnReadyCallbackRegistry(ctx, gigyaScriptLoadListenersRef);

    return { ...ctx, onReadyCallbackRegistry };
}

const getApiKey = (metaData: Meta.MetaData, type: AppType): string => {
    switch (type) {
        case "CLP":
            return metaData.sso.apiKey;
        case "MarketingPreferences":
            return metaData.marketingPreferenceCenter.apiKey;
    }
}

const loadGigya = (currentLanguage:string, metaData: Meta.MetaData, gigyaScriptLoadListenersRef: OnReadyCallbackListenerRef, type: AppType, setGigyaContextObject: (arg: GigyaContext) => void) => {
    const gigyaScript = document.getElementById(gigyaScriptId);

    const gigyaLocale = currentLanguage;

    const onGigyaServiceReady = async () => {
        //create a proxy in order to stay backward compatible with all those effects depending on ctx.gigya
        const gigyaProxy = new Proxy(window.gigya! as GigyaWebSdk.Gigya, {});
        const newGigyaCtx = buildContextObject(gigyaProxy, gigyaLocale, gigyaScriptLoadListenersRef);
        for (const listener of gigyaScriptLoadListenersRef.current) {
            await listener(newGigyaCtx);
        }
        setGigyaContextObject(newGigyaCtx);
    };

    if (!gigyaScript) {
        const script = document.createElement("script");

        script.src = `https://cdns.gigya.com/js/gigya.js?apikey=${encodeURIComponent(getApiKey(metaData, type))}&lang=${encodeURIComponent(gigyaLocale)}`;
        script.id = gigyaScriptId;
        script.async = false;

        //Global Callback method used by gigya-SDK (See https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/417f6b5e70b21014bbc5a10ce4041860.html?locale=en-US)
        window.onGigyaServiceReady = onGigyaServiceReady;

        document.head.appendChild(script);
        return true;
    }

    //our effect can be called while gigya is still loading
    if (window.gigya && window.gigya.isReady) {
        onGigyaServiceReady();
    }
    return false;
}

export const GigyaProvider = ({children, type}: {children: ReactNode, type: AppType}) => {
    const gigyaScriptLoadListenersRef = useRef([]);
    const [gigyaContextObject, setGigyaContextObject] = useState<GigyaContext>(() => buildContextObject(null, null, gigyaScriptLoadListenersRef));
    const { metaData } = useMetaData();

    useEffect(() => {
        if (!metaData) {
          return;
        }

        // always with "de", in theory we don't use language specific features
        loadGigya("de", metaData, gigyaScriptLoadListenersRef, type, setGigyaContextObject);
    }, [type, metaData, gigyaScriptLoadListenersRef]);
   
    const { Provider } = gigyaContext;

    return(
        <Provider value={gigyaContextObject}>
            {children}
        </Provider>
    )
}

export const useGigya = () => useContext(gigyaContext);

export const gigyaWithPromise =
    <Arg extends object | undefined, Response extends GigyaWebSdk.ApiResponse>
    (gigyaFunction: GigyaWebSdk.ApiFunctionWithCallback<Arg, Response>, args: Arg, acceptedErrorCodes: Array<Number> = []): Promise<Response> => {
    
    return new Promise(function(resolve, reject) {
        const para = {
            ...args,
            callback: (response: Response) => {
                if (response.errorCode === 0 || response.errorCode === "0") {
                    // in the case of social login, response code is "0"
                    resolve(response);
                } else if (acceptedErrorCodes.indexOf(Number(response.errorCode)) > -1) {
                    console.log("Gigya success with accepted error " + response.errorCode);
                    resolve(response);
                } else {
                   reject(response);
                }
            }
        };
        gigyaFunction(para);
    });
}

export const logGigyaResponseErrorDetails = (message: string, response: GigyaWebSdk.GigyaErrorResponse) => {
    console.error(message + `; ErrorCode: ${response.errorCode}; ErrorMessage: ${response.errorMessage}; ErrorDetails: ${response.errorDetails}`);
}

export const throwGigyaResponseErrorDetails = (message: string, response: GigyaWebSdk.GigyaErrorResponse): never => {
    throw new Error(message + `; ErrorCode: ${response.errorCode}; ErrorMessage: ${response.errorMessage}; ErrorDetails: ${response.errorDetails}`);
}

export const isGigyaErrorResponse = (error: unknown): error is GigyaWebSdk.GigyaErrorResponse => {
    if (error === null) {
        return false;
    }

    if (typeof error !== "object") {
        return false;
    }

    return "errorCode" in error;
}

export function assertIsGigyaErrorResponse(error: unknown): asserts error is GigyaWebSdk.GigyaErrorResponse {
    if (!isGigyaErrorResponse(error)) {
        throw error;
    }
}