import {useCallback, useEffect, useMemo, useState} from 'react';
import {AccountSchema, GigyaWebSdk, useGigya} from 'GigyaContext';
import {GetMessageFunction, MessageKey, useI18n} from "_shared/hooks/I18n";
import {Account, AccountPreference, AccountPreferences, useAccount} from 'Account';
import {queryObjectProperty, removeArrayPartFromString} from '_shared/Utils';
import {FieldErrors, FieldPath, FieldValue, FieldValues, useForm, UseFormRegisterReturn, UseFormReturn} from 'react-hook-form';
import {Meta, useMetaData} from "../MetaDataContext";
import {isValidInsideRange} from "../_shared/RangeValidator";

export interface UseGigyaSchemaHook {
    getFormValidationForField: GetFormValidationForFieldFunction,
    getFormValidationForFieldEx: (field: string, forceRequired: boolean) => FormValidation
    getFormValidationForZipCode: (field: string, forceRequired: boolean, countryIsoCode: string) => FormValidation
}

export type GetFormValidationForFieldFunction = (field: string) => FormValidation;

interface SchemaAndFieldName {
    schema: "dataSchema" | "profileSchema" | "subscriptionsSchema",
    fieldName: string
}

export type ValidationFunc = (v: FieldValue<any>) => boolean | string;
export type ValidationFuncObject = Record<string, ValidationFunc>;

export interface FormValidation {
    required?: {
        value: boolean,
        message: string
    },
    pattern?: {
        value: RegExp,
        message: string
    },
    validate?: ValidationFunc | ValidationFuncObject
}

export interface AccountFormItemDetail<TFieldValues extends FieldValues> {
    /**
     * The fully qualified name of the field. e.g. "profile.firstName"
     */
    field: FieldPath<TFieldValues>,
    /**
     * The field name within the given schema. e.g. "firstName" for "profile.firstName" or "customerassignment" for "data.customerassignment"
     */
    schemaFieldName: string,
    /**
     * A validation object that can be handed over to the register call of the UseForm-Hook
     */
    validation: FormValidation,
    /**
     * Defines if the field is required within the account schema
     */
    required: boolean,
    /**
     * The concrete value from the users account. e.g. "Mustermann" for "profile.lastName"
     */
    value: any
}

export type RegisterFormInputFunction<TFieldValues extends FieldValues> = (itemDetail: AccountFormItemDetail<TFieldValues>, formValidation?:FormValidation) => UseAccountFormRegisterFormInputReturn;

export type FieldOverwritingData<TFieldValues extends FieldValues> = Map<FieldPath<TFieldValues>, any>;

export interface UseAccountFormReturn<TFieldValues extends FieldValues> extends UseFormReturn<TFieldValues> {
    /** Map with keys from a given fields array to more specific details like validation information, value etc. */
    formItemDetails: Map<string, AccountFormItemDetail<TFieldValues>>,
    /**
     * Allows changing the fields dynamically when they differ from the fields handed over within the initial hook call.
     * @param arg new fields
     * @returns 
     */
    updateFields: (arg: FieldPath<TFieldValues>[]) => void,
    /**
     * Calls register on the useForm-Hook for the specified field with the given validation etc.
     * @param itemDetail 
     * @returns 
     */
    registerFormInput: RegisterFormInputFunction<TFieldValues>
}

export interface UseAccountFormRegisterFormInputReturn {
    name: string,
    label: string,
    errorDetails: FieldErrors,
    useFormRegisterReturn: UseFormRegisterReturn
}

export const useGigyaSchema = (): UseGigyaSchemaHook => {
    const [gigyaSchema, setGigyaSchema] = useState<AccountSchema>();
    const { isGigyaReady, getSchema } = useGigya();
    const {metaData} = useMetaData();
    
    const { getMessage } = useI18n();

    useEffect(() => {
        if (!isGigyaReady()) {
            return;
        }
        getSchema!().then(setGigyaSchema);

    }, [isGigyaReady, getSchema]);
    
    return useMemo(() => {
        if (!gigyaSchema) {
            return {
                "getFormValidationForField": (_field: string) => { return {} },
                "getFormValidationForFieldEx": (_field: string, _forceRequired: boolean) => { return {} },
                "getFormValidationForZipCode": (_field: string, _forceRequired: boolean, _countryIsoCode: string) => { return {} }
            }
        }
        return {
            "getFormValidationForField": (field: string) => getFormValidationForField(field, gigyaSchema, getMessage, false),
            "getFormValidationForFieldEx": (field: string, forceRequired: boolean) => getFormValidationForField(field, gigyaSchema, getMessage, forceRequired),
            "getFormValidationForZipCode": (field: string, forceRequired: boolean, countryIsoCode: string) => getFormValidationForZipCode(field, gigyaSchema, getMessage, forceRequired, metaData, countryIsoCode)
        }
    }, [gigyaSchema, getMessage, metaData]);
}

/**
 * Build a form validation from gigya fields schema definition and overwrites the pattern validation with a country
 * dependent zip pattern (if available).
 *
 * @param field A field description. e.g. "profile.zip"
 * @param gigyaSchema The result of a gigya.accounts.getSchema-Call
 * @param getMessage
 * @param forceRequired true for required fields, otherwise false
 * @param metaData
 * @param countryIsoCode the selected county, i.e. "DE"
 * @returns Returns an empty object in case the field was not found within the schema.
 */
const getFormValidationForZipCode = (field: string, gigyaSchema: AccountSchema, getMessage: GetMessageFunction, forceRequired: boolean,
                                     metaData: Meta.MetaData | undefined, countryIsoCode: string): FormValidation => {
    const result = {
        ...getFormValidationForField(field, gigyaSchema, getMessage, forceRequired),
        ...getZipCodeValidationPattern(metaData, countryIsoCode, getMessage)
    }
    console.log(countryIsoCode);
    return result;
}

/**
 * Converts a gigya fields schema definition into a form hook validation object, that can be used to perform the client side validation.
 * @param {*} field A field description. e.g. "profile.zip"
 * @param {*} gigyaSchema The result of a gigya.accounts.getSchema-Call
 * @param {*} getMessage
 * @param {*} forceRequired
 * @returns Returns an empty object in case the field was not found within the schema.
 */
const getFormValidationForField = (field: string, gigyaSchema: AccountSchema, getMessage: GetMessageFunction, forceRequired: boolean): FormValidation => {
    const { schema, fieldName} = extractSchemaAndFieldName(field);

    if (!schema) {
        return {};
    }

    const definition = gigyaSchema[schema].fields[fieldName];
    if (!definition) {
        return {};
    }

    // @ts-ignore
    if (definition.email) {
        // @ts-ignore
        return mapValidation(definition.email, field, forceRequired, getMessage);
    }

    return mapValidation(definition, field, forceRequired, getMessage);
}

const mapValidation = (definition: GigyaWebSdk.BaseAccountSchemaField, field: string, forceRequired: boolean, getMessage: GetMessageFunction) => {
    let validation: FormValidation = {};
    
    if (definition.required || forceRequired) {
        validation["required"] = {
            value: true,
            message: getMessage("gigya.schema." + field as MessageKey, getMessage("gigya.schema.required.error"))
        }
    }
    
    if (definition.format && definition.format.startsWith("regex('")) {
        const regex = definition.format.substring("regex('".length, definition.format.length - 2);
        validation["pattern"] = {
            value: new RegExp(regex),
            message: getMessage("gigya.schema." + field as MessageKey, getMessage("gigya.schema.format.error"))
        }
    }

    if (definition.type === "long" && definition.format) {
        validation["validate"] =
            (value: string | number) => {
                return isValidInsideRange(definition.format, Number(value))
                    || getMessage("gigya.schema." + field as MessageKey, getMessage("gigya.schema.format.error"));
            }
    }

    return validation;
}

/**
 * Build the form validation for country dependent regular expression.
 *
 * @param metaData
 * @param countryIsoCode the selected county, i.e. "DE"
 * @param getMessage function to resolve messages
 * @return a FormValidation with a pattern or empty
 */
const getZipCodeValidationPattern = (metaData: Meta.MetaData | undefined, countryIsoCode: string, getMessage: GetMessageFunction) : FormValidation => {
    let validation: FormValidation = {};
    if (metaData?.validation?.zipCode) {
        const error = metaData.validation.zipCode[countryIsoCode] || metaData.validation.zipCode["default"] || {};
        const errorMessage = (error?.errorMessage && getMessage(error.errorMessage as MessageKey)) || getMessage("gigya.schema.format.error");

        if (error.regex) {
            validation["pattern"] = {
                value: new RegExp(error.regex),
                message: errorMessage
            }
        }
    }
    return validation;
}

/**
 * @param {*} fields Array of strings describing a field within account schema.
 * e.g. [ "profile.email", "data.zip" ].
 * This property is cached and used only during the first rendering
 *  In case you need to update, please use property "updateFields" from response.
 * @param initialOverwriteData
 * @returns {object} Object with the following properties:
 *
 */
export const useAccountForm = <TFieldValues extends FieldValues>(fields: FieldPath<TFieldValues>[], initialOverwriteData?: FieldOverwritingData<FieldValues>): UseAccountFormReturn<TFieldValues> => {
    const useFormResult = useForm<TFieldValues>();

    const [ internalFields, setInternalFields ] = useState(fields);

    const { account } = useAccount();
    const { getFormValidationForField } = useGigyaSchema();

    const { getMessage } = useI18n();

    const overwrittenData = useMemo(() => {
        return initialOverwriteData ?? new Map() as FieldOverwritingData<FieldValues>;
    }, [initialOverwriteData]);

    useEffect(() => {
        useFormResult.reset();
    }, [account, useFormResult]);

    const registerFormInput = useCallback((itemDetail: AccountFormItemDetail<TFieldValues>, formValidation?: FormValidation): UseAccountFormRegisterFormInputReturn => {
        const reg = {
            ...itemDetail.validation,
            ...formValidation,
            ...(itemDetail.value && {value: itemDetail.value}),
            ...(overwrittenData.has(itemDetail.field) && {value: overwrittenData.get(itemDetail.field)})
        };
        return {
            name: itemDetail.field,
            label: getMessage("account." + itemDetail.field as MessageKey, itemDetail.field),
            errorDetails: queryObjectProperty(useFormResult.formState.errors, itemDetail.field),
            useFormRegisterReturn: useFormResult.register(itemDetail.field, reg)
        }
    }, [getMessage, useFormResult, overwrittenData]);

    return useMemo(() => {
        const itemDetails = buildFormItemDetails(internalFields, account, overwrittenData, getFormValidationForField);
        return {
            formItemDetails: itemDetails,
            updateFields: setInternalFields,
            registerFormInput: registerFormInput,
            ...useFormResult
        }
            
    }, [internalFields, setInternalFields, account, getFormValidationForField, registerFormInput, useFormResult, overwrittenData])
}

const buildFormItemDetails = <TFieldValues extends FieldValues>(fields: FieldPath<TFieldValues>[], account: Account, overwrittenData: FieldOverwritingData<FieldValues>, getFormValidationForField: GetFormValidationForFieldFunction): Map<FieldPath<TFieldValues>, AccountFormItemDetail<TFieldValues>> => {
    return fields.reduce((result, field) => {
        result.set(field, buildFormItemDetail(field, account, overwrittenData, getFormValidationForField));
        return result;
    }, new Map<FieldPath<TFieldValues>, AccountFormItemDetail<TFieldValues>>())
}

const buildFormItemDetail = <TFieldValues extends FieldValues>(field: FieldPath<TFieldValues>, account: Account, overwrittenData: FieldOverwritingData<FieldValues>, getFormValidationForField: GetFormValidationForFieldFunction): AccountFormItemDetail<TFieldValues> => {
    const validation = getFormValidationForField(field);

    let fieldValue: any;
    if (overwrittenData.has(field)) {
        fieldValue = overwrittenData.get(field);
    } else {
        fieldValue = queryObjectProperty(account, field);
        //console.log(field + ": " + fieldValue);
    }

    return {
        field: field,
        schemaFieldName: extractSchemaAndFieldName(field).fieldName,
        validation: validation,
        required: validation["required"] !== undefined,
        value: fieldValue
    }
}

const extractSchemaAndFieldName = (field: string): SchemaAndFieldName => {
    // remove [...] -> not in schema json
    field = removeArrayPartFromString(field);
    if (field.startsWith("data")) {
        return {
            schema: "dataSchema",
            fieldName: field.substring("data.".length)
        }
    } else if (field.startsWith("profile")) {
        return {
            schema: "profileSchema",
            fieldName: field.substring("profile.".length)
        }
    } else if (field.startsWith("subscriptions.")) {
        return {
            schema: "subscriptionsSchema",
            //fieldName: field.substring("subscriptions.".length) + ".email.isSubscribed"
            fieldName: field.substring("subscriptions.".length)
        }
    } else {
        throw new Error("Unexpected field. Expected field to start with data or profile or subscriptions; " + field);
    }
}

/**
 * Ensures we can handle both gigya responses regarding consent grant
 * Sometimes gigya returns consents as {
 *  "privacy.aec": {...}
 * }
 * and sometimes as {
 *  "privacy": {
 *      "aec": {}
 *  }
 * }
 * @param {*} preferences 
 * @param {*} consentId 
 */
export const getConsentObject = (preferences: AccountPreferences | undefined, consentId: string): AccountPreference | undefined => {
    if (!preferences) {
        return undefined;
    }

    if (preferences[consentId]) {
        return preferences[consentId];
    }

    return queryObjectProperty(preferences, consentId);
}
