import { hasNewsletterSubscriptions, useAccount } from "Account";
import { Cms } from "CmsContext";
import { useMemo } from "react";

export interface CmsTabStatus {
    readonly tab: Cms.Tab,
    readonly enabled: boolean
}

export const useTabsStatus = (tabs?: Cms.Tab[]): Map<Cms.Tab, CmsTabStatus> => {
    const {account} = useAccount();

    return useMemo(() => {
        const map = new Map();

        if (!tabs) {
            return map;
        }

        const newsletterSubscriptions = hasNewsletterSubscriptions(account);
        tabs.forEach(tab => {
            map.set(tab, {
                tab,
                enabled: newsletterSubscriptions || tab.accessWithoutNewsletterSubscription
            })
        });
        return map;
    }
    , [account, tabs]);
}