import React, {memo} from "react";

export type CDNResponsiveImageProp = {
    widths?:number[],
    heights?:number[],
    src?: string,
    alt: string,
    title?: string,
    sizes?: string,
    className?: string
};

export const EMPTY_IMAGE: string = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

/**
 * Simple responsive image for performance (original images are huge).
 * Add "wid" parameter to the URL.
 *
 * @param src URL to image w/o "wid" and "hei" parameter
 * @param widths available image widths, optional
 * @param heights available image widths, optional (ignored if "widths" is set)
 * @param sizes sizes parameter from img tag
 * @param title title
 * @param alt alternative text
 * @param imgProp other properties
 */
export const CDNResponsiveImage = memo(({widths, heights, src, alt, ...imgProp}: CDNResponsiveImageProp) => {
    if (!src) { // i.e. "null" in configuration
        return <img {...imgProp} alt={alt} src={EMPTY_IMAGE} />
    }

    const separater = (src.lastIndexOf("?") < 0 ? "?" : "&");
    let srcSetEntries: string[];
    if (widths) {
        srcSetEntries = widths.map((width) => src + `${separater}wid=${width} ${width}w`);
    } else if (heights) {
        srcSetEntries = heights.map((height) => src + `${separater}hei=${height} ${height}w`);
    } else {
        return <img {...imgProp} alt={alt} src={src} />
    }
    return <img {...imgProp} alt={alt} src={EMPTY_IMAGE} srcSet={srcSetEntries.join(", ")} />
});
