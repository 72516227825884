import {useI18n} from '_shared/hooks/I18n';
import {useAccountForm, useGigyaSchema} from "../../hooks/GigyaSchema";
import {gigyaWithPromise, useGigya} from "../../GigyaContext";
import {FormInput} from "./FormInput";
import {useAccount} from "../../Account";
import {CountryField} from "./CountryField";
import {replaceEmptyStringsWithNull} from "../Utils";

export const Address = ({setShowSuccess}: {setShowSuccess: (set:boolean) => void}) => {
    const formFields = [
        "data.addressBook.street",
        "data.addressBook.streetNo",
        "data.addressBook.zip",
        "data.addressBook.city",
        "data.addressBook.country"
    ];

    const { refreshAccount} = useAccount();
    const { trigger, getValues, formItemDetails, registerFormInput, setError, setValue, handleSubmit } = useAccountForm(formFields);
    const {getMessage} = useI18n();
    const {gigya} = useGigya();
    const {getFormValidationForZipCode} = useGigyaSchema();

    if (!gigya) {
        return <></>
    }

    const handleUpdateAddress = async (formData: any) => {
        if(setShowSuccess) {
            setShowSuccess(false);
        }
        formData = replaceEmptyStringsWithNull(formData);
        try {
            await gigyaWithPromise(gigya.accounts.setAccountInfo, formData)
            .then(async function(){
                await refreshAccount();
                if(setShowSuccess) {
                    setShowSuccess(true);
                }
            }).catch((err) => {
                err?.validationErrors?.forEach((validationError: any, _idx: number) => {
                    if (validationError?.fieldName) {
                        setError(validationError.fieldName, {
                            type: "pattern",
                            message: validationError.message
                        });
                    }
                });
            });
        } catch(errorResponse) {
            throw errorResponse;
        }
    }

    /**
     * Refresh the state of field to revalidate this field.
     *
     * @param fieldName {*} the field name to refresh
     * @param value {*} the current value
     * @returns {Promise<void>}
     */
    const refresh = async (value: string, fieldName: string) => {
        await trigger(fieldName).then(_value => {
            // set the values again, I haven't found another solution here to revalidate the value w/o change the value
            setValue(fieldName, getValues(fieldName), {
                shouldValidate: true
            });
        });
    }

    // make before register zip for correct validation handling
    // do not move below
    const registerCountry = registerFormInput(formItemDetails.get("data.addressBook.country")!)

    return (
        <div id="address">
            <form onSubmit={handleSubmit(handleUpdateAddress)} autoComplete="on">
                <fieldset>
                    <div className='row'>
                        <div className='col-xs-9 col-sm-8 col-md-9'>
                            <FormInput {...registerFormInput(formItemDetails.get("data.addressBook.street")!)}/>
                        </div>
                        <div className='col-xs-3 col-sm-4 col-md-3'>
                            <FormInput {...registerFormInput(formItemDetails.get("data.addressBook.streetNo")!)}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-4 col-sm-5 col-md-4'>
                            <FormInput {...registerFormInput(formItemDetails.get("data.addressBook.zip")!, getFormValidationForZipCode("data.addressBook.zip", true, getValues().data?.addressBook?.country))} required={true} />
                        </div>
                        <div className='col-xs-8 col-sm-7 col-md-8'>
                            <FormInput {...registerFormInput(formItemDetails.get("data.addressBook.city")!)}/>
                        </div>
                    </div>
                    <CountryField onChangeFunc={(value) => refresh(value, "data.addressBook.zip")} setValue={setValue} registerInput={registerCountry} />
                </fieldset>

                <button type="submit" className="btn btn--primary btn-full">{getMessage("address.save")}</button>
            </form>
        </div>
    )
}