import {
    AccountFormItemDetail,
    RegisterFormInputFunction
} from "../../hooks/GigyaSchema";
import React, {useId} from "react";
import {Cms} from "../../CmsContext";

export type ShoppingFrequenciesComponentProp = {
    registerFormInput: RegisterFormInputFunction<any>,
    formItemDetails: Map<string, AccountFormItemDetail<any>>,
    cmsInputField: Cms.ProfileFieldShoppingFrequency
}

export const ShoppingFrequenciesComponent = ({registerFormInput, formItemDetails, cmsInputField}: ShoppingFrequenciesComponentProp) => {
    let {useFormRegisterReturn:sf_rr, errorDetails:sf_ed, ...sf_props} = registerFormInput(formItemDetails.get(cmsInputField.cdcProperty)!);
    const id = useId();
    const options: React.ReactNode[] = [];
    cmsInputField.options.forEach((value) => {
        options.push(<option key={value.id} value={value.id}>{value.label}</option>);
    });

    return (
        <label className="textfield textfield--select">
            <select className="textfield__control" aria-describedby={id} {...sf_rr} {...sf_props}>
                {
                    sf_rr.required || <option value="">{cmsInputField.empty}</option>
                }
                {options.map((option) => option)}
            </select>
            <span id={id} className="textfield__label">{cmsInputField.label}</span>
        </label>
    )
}