import { useI18n } from "_shared/hooks/I18n";
import { ConfirmOverlay } from "_shared/components/confirmoverlay";
import { Account, AccountSubscriptions, containsNewsletterSubscriptionInSubscribedStatus, hasNewsletterSubscriptions } from "Account";

export interface ConfirmOverlayCallback {
    promiseResolver: (confirmed: boolean) => void;
}

/**
 * This dialog is shown, when the customer has no newsletter subscription yet, but wants to opt in to one for the first time
 * @param props 
 * @returns 
 */
export const InitialNewsletterOptInDialog = ( { confirmCallback } : { confirmCallback: ConfirmOverlayCallback } ) => {
    const { getMessage } = useI18n();

    return <ConfirmOverlay headline={getMessage("mpp.newsletter.initialoptin.dialog.headline")}
        description={getMessage("mpp.newsletter.initialoptin.dialog.description")}
        confirmText={getMessage("mpp.newsletter.initialoptin.dialog.confirm")}
        denyText={getMessage("mpp.newsletter.initialoptin.dialog.cancel")}
        confirmHandler={() => confirmCallback.promiseResolver(true)}
        denyHandler={() => confirmCallback.promiseResolver(false)} />
}

export const isInitialNewsletterOptIn = (account: Account, newSubscriptions?: AccountSubscriptions): newSubscriptions is AccountSubscriptions => {
    if (!newSubscriptions || hasNewsletterSubscriptions(account)) {
        return false;
    }

    return containsNewsletterSubscriptionInSubscribedStatus(newSubscriptions);
}