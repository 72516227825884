import {MessageKey, useI18n} from "../hooks/I18n";
import "./LanguageSwitcher.css";
import {memo, ReactNode} from "react";

type LanguageSwitcherEntryData = {
    language: string,
    click: (arg0: string)=>void
}

const LanguageSwitcherEntry = memo(({language, click}: LanguageSwitcherEntryData) => {
    const { getMessage, currentLanguage } = useI18n();

    const isActive = currentLanguage === language;

    return <div className="mod-language-selector__item">
    <button onClick={() => click(language)} className={`mod-language-selector__action ${isActive ? "is-active" : ""}`} data-attr-name="Meta Navigation - Level 1" data-attr-value={`Language: ${language.toUpperCase()}`}>
        <abbr title={getMessage(("language.long." + language) as MessageKey, language)}>{getMessage(("language.short." + language) as MessageKey, language).toUpperCase()}</abbr>
    </button>
</div>
})

export const LanguageSwitcher = memo(() => {
    const { availableLanguages, switchLanguage } = useI18n();

    if (availableLanguages.length <= 1) {
        // one language only
        return <></>
    }

    const click = (language: string)  => {
        switchLanguage(language);
    }

    // extra array to handle key attribute w/ separator correctly
    const nodes: ReactNode[] = [];
    availableLanguages.forEach((language, index, m) => {
        nodes.push(<LanguageSwitcherEntry key={language} language={language} click={click} />);
    })

    return <div className="mod mod-language-selector mod-language-selector--list">
        <div className="mod-language-selector__list">
            {nodes.map((node) => node)}
        </div>
    </div>
});