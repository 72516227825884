import {Cms, useCmsData} from "CmsContext";
import "./SsoClientsOverview.css"
import React, {useRef} from "react";
import {CDNResponsiveImage} from "./CDNResponsiveImage";

export const SsoClientsOverview = () => {
    const {cmsData} = useCmsData();

    if (!cmsData) {
        return <></>;
    }
    const data = cmsData.components.SsoClientsOverview
    return (
        <div className="mod mod-tiles mod-tiles--services">
            <div className="tiles-grid tiles-grid--4">
            {
                data.items.map((item, _idx) => <SsoClientItem key={item.id} ssoClient={item}/>)
            }
            </div>
        </div>
    );
}

const SsoClientItem = ({ssoClient}: {ssoClient: Cms.LinkBox}) => {
    const clickRef = useRef<HTMLAnchorElement>(null);

    return (
        <div onClick={(_e) => clickRef.current?.click()} className="mod mod-content-tile-enhanced mod-content-tile-enhanced--secondary mod-content-tile-enhanced--hover">
            <div className="mod-content-tile-enhanced__media" data-debug-imgsize="imgSize3">
                <CDNResponsiveImage
                    className="img-responsive img-cover"
                    sizes="(min-width: 1024px) 286px, (min-width: 768px) 233px, (min-width: 560px) 258px, calc(((100vw - 32px) / 2) - 6px)"
                    src={ssoClient.image}
                    alt={ssoClient.buttonlabel}
                    widths={[233, 258, 286, 572, 858]} // images are in very high resolution
                />
            </div>
            <div className="mod-content-tile-enhanced__content">
                <h4 className="mod-content-tile-enhanced__title">{ssoClient.label}</h4>
                <p>{ssoClient.description}</p>
                <a ref={clickRef} className="btn btn--secondary mod-content-tile-enhanced__action" href={ssoClient.url}>{ssoClient.buttonlabel}</a>
            </div>
        </div>
    );
}
