import { ReactNode } from "react";
import "./LoadingIndicator.css";

/**
 * This component is meant to be shown e.g. as overlay of a real component,
 * while asynchronous background tasks happen.1
 * @returns 
 */
export const LoadingIndicator = ({ id, loading, children = <></> }: {id: string, loading: boolean, children?: ReactNode}) => {
    return (
        <div id={id}>
            { loading ?
                <div className="loading-indicator">
                    <div id={id} className={loading === true ? "loading" : ""}>
                    </div>
                </div>
            : children }
        </div>
    )
}