import {useCmsData} from "CmsContext";
import {Footer} from "./components/Footer";
import {Header} from "./components/Header";
import {UserCentricsComponent} from "../_shared/components/UserCentricsComponent";
import {AdobeTagManagerComponent} from "../_shared/components/AdobeTagManagerComponent";
import {Meta} from "../_shared/components/Meta";
import {useI18n} from "../_shared/hooks/I18n";
import {useMetaData} from "../MetaDataContext";
import {CDNResponsiveImage} from "../_shared/components/CDNResponsiveImage";

export const UnsubscribePage = () => {
    const { getMessage } = useI18n();
    const {cmsData} = useCmsData();
    const {metaData} = useMetaData();

    if (!cmsData || !metaData) {
        return <></>;
    }

    return (
        <div className="preference-center">
            <Meta titleProperty="mpc.page.title" />
            <UserCentricsComponent pageType={"Form Page"} pageName={getMessage("tracking.cpp.unsubscribe.all")} primaryCategory={getMessage("tracking.cpp.unsubscribe.all")} />
            <AdobeTagManagerComponent />
            <Header header={cmsData.components.header} />
            <div className="page__main" role="main">
                <div className="page__content">
                    <div className="container-full">
                        <div className="mod mod-overview-intro-2">
                            <div className="mod-overview-intro-2__media">
                                <CDNResponsiveImage
                                    className="img-responsive img-cover cq-dd-image"
                                    title={cmsData.components.preferenceCenter.unsubscribeimage.alt}
                                    alt={cmsData.components.preferenceCenter.unsubscribeimage.alt}
                                    sizes="(min-width: 1817px) 1817px, 100vw"
                                    src={cmsData.components.preferenceCenter.unsubscribeimage.src}
                                    widths={[768, 1024, 1817]} // no other sizes in germany: could be added "3000" for retina displays
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="mod mod-headline">
                            <h1>{getMessage("mpp.unsubscribeall.success.headline")}</h1>
                        </div>
                        <div className="mod mod-copy">
                            <div className="rte">
                                <p dangerouslySetInnerHTML={{__html: getMessage("mpp.unsubscribeall.success.body", "def", [cmsData.components.preferenceCenter.subscribeLink?.src ?? "/"])}}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer footer={cmsData.components.footer} socialMediaBar={cmsData.components.socialmediabar}></Footer>
        </div>
    );
}
