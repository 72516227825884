import "./SocialLogin.css";
import { ReactComponent as IconFacebook } from '_assets/aldi-essentials-867/assets/brands/aldi/images/SM Icons/facebook.svg';
import { ReactComponent as IconGoogle } from '_assets/aldi-essentials-867/assets/brands/aldi/images/SM Icons/google.svg';
import { ReactComponent as IconApple } from '_assets/aldi-essentials-867/assets/brands/aldi/images/SM Icons/apple.svg';
import { useLogin } from "central-login-page/hooks/Hooks";
import React from "react";

const SocialLoginButton = ({ loginType, onClick, icon, label }: { loginType: string, onClick: React.MouseEventHandler<HTMLButtonElement>, icon: React.ReactElement, label: string }) => {

    return (
        <button type="button" className='social-login-button' data-logintype={loginType} onClick={onClick}>
            <div className="social-login-icon">
                {icon}
            </div>
            <span>{label}</span>
        </button>
    )
}

export const SocialLogin = ({ afterLogin = () => { } }: { afterLogin?: () => void }) => {
    const { socialLogin } = useLogin();

    const handleClick = (provider: string) => async () => {
        if (await socialLogin(provider)) {
            afterLogin();
        }
    }

    return (
        <div className='SocialLogin'>
            <SocialLoginButton loginType="Apple" onClick={handleClick("apple")} icon={<IconApple aria-hidden={true} className="icon icon--baseline" />} label="Apple" />
            <SocialLoginButton loginType="Google" onClick={handleClick("googleplus")} icon={<IconGoogle aria-hidden={true} className="icon icon--baseline" />} label="Google" />
            <SocialLoginButton loginType="Facebook" onClick={handleClick("facebook")} icon={<IconFacebook aria-hidden={true} className="icon icon--baseline" />} label="Facebook" />
        </div>
    )
}

export default SocialLogin;
