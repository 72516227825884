import { DeepPartial, useForm } from "react-hook-form";
import { useGigya, gigyaWithPromise, assertIsGigyaErrorResponse, GigyaWebSdk } from 'GigyaContext';
import { AccountData, useAccount } from 'Account';
import { useI18n } from '_shared/hooks/I18n';
import { PasswordFieldWithValidation } from '_shared/components/PasswordField';
import { ErrorField } from '_shared/components/ErrorField';
import { useNavigate } from 'react-router-dom';
import SocialLogin from 'central-login-page/shared-components/SocialLogin';
import { handleLoginErrors, useLogin } from 'central-login-page/hooks/Hooks';
import {useId, useRef} from "react";
import {AldiOwnIdInit, AldiOwnIdRegister, OwnIdRegisterResponse} from "../shared-components/AldiOwnId";
import { ConsentCheckBox } from '_shared/components/ConsentCheckBox';
import { useGigyaSchema } from 'hooks/GigyaSchema';
import { LoadingIndicator } from '_shared/components/LoadingIndicator';
import { useState } from 'react';
import { copyRefFunc } from "_shared/Utils";
import { FormInput } from "_shared/components/FormInput";

interface RegistrationScreenFormData {
    email: string,
    password: string,
    preferences: any
}

export const RegistrationScreen = () => {
    const { register, handleSubmit, setError, formState: { errors } } = useForm<RegistrationScreenFormData>();
    const [ isLoading, setLoading ] = useState(false);

    const labelId = useId();

    const { gigya } = useGigya();
    
    const { getMessage, getCdcErrorMessage, currentLanguage } = useI18n();
    
    const navigate = useNavigate();

    const { getFormValidationForField } = useGigyaSchema();
    const { initRegistration } = useAccount();
    const { performLogin } = useLogin()

    const emailInputRef = useRef<HTMLInputElement>();
    const passwordInputRef = useRef<HTMLInputElement>();
    const ownIdData = useRef<OwnIdRegisterResponse>();

    const { cdcIncludeFields,  extraProfileFields, } = useAccount();
    
    const registerEmailResult = register("email", getFormValidationForField("profile.email"));

    if (!gigya) {
        return <></>;
    }

    const afterRegistration = () => {
        navigate("/registration/confirmation");
    }

    const performRegistration = async (formData: RegistrationScreenFormData) => {
        try {
            setLoading(true);
            const initRegResponse = await gigyaWithPromise(gigya.accounts.initRegistration, {});
            
            const regToken = initRegResponse.regToken;
            initRegistration(regToken);

            const regSource = sessionStorage.getItem("regSource");
            let requestParams: GigyaWebSdk.ApiFunctionArgsAccountsRegister = {
                data: {},
                include: cdcIncludeFields,
                finalizeRegistration: true,
                ...formData,
                ...(regSource && { regSource: regSource}),
                regToken: regToken,
                ignoreInterruptions: true,
                lang: currentLanguage /* registration language */
            };

            //add ownId to data object
            if (ownIdData.current?.data && ownIdData.current?.metadata?.dataField) {
                requestParams.data = {
                    ...requestParams.data,
                    [ownIdData.current.metadata.dataField]: {
                        ...requestParams.data?.[ownIdData.current?.metadata.dataField as keyof DeepPartial<AccountData>] as any,
                        ...ownIdData.current.data
                    }
                };
            }

            await gigyaWithPromise(gigya.accounts.register, requestParams);

            //no additional fields are required
            afterRegistration();
        }
        catch(errorResponse: unknown) {
            assertIsGigyaErrorResponse(errorResponse);
            //additional required fields have to be provided - forward to finalize registration screen
            if (handleLoginErrors(errorResponse, initRegistration, navigate, "registration")) {
                return;
            }

            if (errorResponse.errorCode === 400009) {
                const loginIdExistsAlready = errorResponse.validationErrors
                    .filter(error => error.errorCode === 400003).length !== 0;
                
                //if the user typed in the correct username and password, we'll log him in
                if (loginIdExistsAlready) {
                    try {
                        await performLogin({
                            email: formData.email,
                            password: formData.password,
                            include: cdcIncludeFields,
                            extraProfileFields: extraProfileFields
                        });
                        navigate("/");
                    }
                    catch (loginErrorResponse) {
                        //we don't care about this error
                    }
                }

                console.error(errorResponse);
            
                setError("root.serverError", {
                    message: getMessage("clp.registration.register.error.account.exists")
                });
                return;
            }

            console.error(errorResponse);
            
            setError("root.serverError", {
                message: getCdcErrorMessage(errorResponse)
            });
        }
        finally {
            ownIdData.current = undefined;
            setLoading(false);
        }
    }

    const handleOwnIdRegistration = (ownIdResponse: OwnIdRegisterResponse) => {
        ownIdData.current = ownIdResponse;
    }

    return (
        <LoadingIndicator id="registration-screen" loading={isLoading}>
            <AldiOwnIdInit/>
            <form onSubmit={handleSubmit(performRegistration)} autoComplete="on">
                <FormInput type="email" inputMode="email" name="email" autoFocus autoComplete="email"
                    label={getMessage("global.email.address")}
                    errorDetails={errors.email}
                    useFormRegisterReturn={
                        {
                            ...registerEmailResult,
                            ref: copyRefFunc(registerEmailResult.ref, emailInputRef)
                        }
                    }
                />
                <fieldset>
                    <label className="textfield input-with-ownid">
                        <PasswordFieldWithValidation
                            {...{
                                name: "password",
                                error: errors.password,
                                placeholder: getMessage("global.password"),
                                autoComplete: "new-password",
                                "aria-describedby": labelId,
                            }}
                            registerHandler={passwordValidation /* RegisterOptions */ => {
                                    const registerPasswordResult = register("password", passwordValidation);
                                    return {
                                        ...registerPasswordResult,
                                        ref: copyRefFunc(registerPasswordResult.ref, passwordInputRef)
                                    }
                                }
                            }
                        />
                        <span id={labelId} className="textfield__label">{getMessage("global.password")}</span>
                        <div className="input-with-ownid__or">{getMessage("clp.registration.registrationOr")}</div>
                        <AldiOwnIdRegister passwordField={passwordInputRef as React.RefObject<HTMLInputElement>} loginIdField={emailInputRef as React.RefObject<HTMLInputElement>} onSuccess={handleOwnIdRegistration} />
                        <ErrorField field={errors.password} />
                    </label>
                </fieldset>
                <ConsentCheckBox formFieldName="preferences.privacy.aec.isConsentGranted" register={register} errors={errors} />
            
                <ErrorField field={errors.root?.serverError} />

                <button type="submit" className="btn btn--primary btn-full btn-right">{getMessage("clp.registration.register")}</button>
            </form>
            <p className='text-center margin-top-bottom labeledSeperator'>{getMessage("clp.registration.registerOr")}</p>
            <SocialLogin afterLogin={afterRegistration} />
        </LoadingIndicator>
    )
}

