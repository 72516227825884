import "./Header.css";
import LogoNord from '_assets/aldi-essentials-867/assets/brands/aldi/images/logo.svg';
import {Cms} from "../../CmsContext";
import {LanguageSwitcher} from "../../_shared/components/LanguageSwitcher";
import {memo} from "react";
import { useI18n } from "_shared/hooks/I18n";

export type HeaderData = {
    header: Cms.Header
}

export const Header = memo(({header}:HeaderData) => {

    const { availableLanguages } = useI18n();

    return (
        <header className="mod mod-header mod-header--usp header-preferences" role="banner">
            {(header.usptext || availableLanguages.length > 1) && <div className="mod-header__meta">
                <div className="mod-header__meta-inlay">
                    {header.usptext && <div className="mod mod-usp">
                        <div className="mod-usp__item">{header.usptext}</div>
                    </div>}
                    {availableLanguages.length > 1 && <div className="mod mod-meta-navigation">
                        <LanguageSwitcher />
                    </div>}
                </div>
            </div>}
            <div className="mod-header__wrapper" data-scroll-direction="UP">
                <div className="container">
                    <div className="mod-header__inlay">
                        <a href={header.logo.src} className="mod-header__logo" data-attr-name="Main Navigation - Level 1" data-attr-value="Logo">
                            <img src={LogoNord} alt={header.logo.alt} className="img-responsive logo" />
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
})