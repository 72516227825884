import {useMemo} from 'react';
import {NavigateFunction, useNavigate} from "react-router-dom";
import {InitRegistrationFunction, RefreshAccountFunction, SubmitLoginEventFunction, useAccount} from 'Account';
import {assertIsGigyaErrorResponse, GigyaWebSdk, gigyaWithPromise, useGigya} from 'GigyaContext';
import {useCookies} from 'react-cookie';
import {navigateToFinalizeRegistration} from 'Router';
import {NavigateOrigin} from "../screens/FinalizeRegistrationScreen";
import {Meta, useMetaData} from "../../MetaDataContext";

const LOGIN_WITH_JSSDK = process.env.REACT_APP_LOGIN_WITH_JSSDK;

//export type LoginRequestParams = Record<string, string>;
export interface LoginRequestParams {
    email: string,
    password: string,
    loginMode?: string,
    regToken?: string,
    include?: string,
    extraProfileFields?: string
}

export type PerformLoginFunction = (params: LoginRequestParams) => Promise<boolean>;
export type SocialLoginFunction = (provider: string) => Promise<boolean>;
export type CheckPasswordFunction = (password: string) => Promise<GigyaWebSdk.ApiResponse>;
export interface LoginFunctions {
    performLogin: PerformLoginFunction,
    socialLogin: SocialLoginFunction,
    checkPassword: CheckPasswordFunction
}

export const useLogin = () : LoginFunctions => {
    const { metaData } = useMetaData();
    const { gigya } = useGigya();
    const { submitLoginEvent, account } = useAccount();
    
    const navigate = useNavigate();

    const [, setCookie] = useCookies()
    
    const { initRegistration, refreshAccount } = useAccount();

    return useMemo(() => {
        return { 
            performLogin: createPerformLoginFunc(submitLoginEvent, gigya, metaData, initRegistration, refreshAccount, navigate, setCookie),
            socialLogin: createPerformSocialLoginFunc(gigya, initRegistration, navigate),
            checkPassword: createCheckPasswordFunc(gigya, account.profile?.email)
        }
    }, [metaData, submitLoginEvent, gigya, initRegistration, navigate, setCookie, refreshAccount, account.profile?.email]);
}

const createCheckPasswordFunc = (gigya: GigyaWebSdk.Gigya | null, email?: string): CheckPasswordFunction => async (password: string) => {
    return new Promise(function(resolve, reject) {
        if (!gigya || !email) {
            reject(null);
            return;
        }
        gigyaWithPromise(gigya.accounts.login, {
            ignoreInterruptions: true,
            loginID: email,
            password: password
        })
            .then(resolve)
            .catch(reject);
    });
}

const createPerformSocialLoginFunc = (gigya: GigyaWebSdk.Gigya | null, initRegistration: InitRegistrationFunction, navigate: NavigateFunction) => async (provider: string) => {
    if (!gigya) {
        return false;
    }
    try {
        await gigyaWithPromise(gigya.accounts.socialLogin, {
            "provider": provider
        });
        return true;
    }
    catch(errorResponse: any) {
        if (handleLoginErrors(errorResponse, initRegistration, navigate, "socialLogin")) {
            return false;
        }

        //Login identifier exists already
        if (errorResponse.errorCode === "403043") {
            localStorage.setItem("accountLinkingRegToken", errorResponse.regToken);
            navigate("/social/link-accounts");
            return false;
        }

        throw errorResponse;
    }
}

const createPerformLoginFunc = (submitLoginEvent: SubmitLoginEventFunction, gigya: GigyaWebSdk.Gigya | null, metaData : Meta.MetaData | undefined, initRegistration: InitRegistrationFunction, refreshAccount:RefreshAccountFunction, navigate: NavigateFunction, setCookie: any) => async (loginRequestParams:LoginRequestParams) => {
    if (!gigya || !metaData) {
        return false;
    }
    try {
        if (metaData.sso.customLogin) {
            const rawResponse = await fetch(metaData.restApi + "/rest/v1/account/login", {
                method: 'POST',
                headers:{
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: new URLSearchParams(loginRequestParams as Record<string, any>)
            });
            const response = await rawResponse.json();

            if (response.errorCode !== 0) {
                throw response;
            }
            if (!response.apiVersion) {
                console.error("Response to login call didn't match the expected format.");
                console.error(response);
                const fakeErrorResponse = {
                    "errorCode": 500002,
                    "errorDetails": "Response to login call didn't match the expected format.",
                    "errorMessage": "Response to login call didn't match the expected format.",
                    "statusCode": 500
                };
                throw fakeErrorResponse;
            }

            console.info("Successfully logged in");

            //the setCookie-Implementation doesn't work when using api domain prefix (Staging)
            //it's quite hard to figure out what the JS-SDK does. It uses undocumented features (targetEnv=jssdk)
            //so sticking to its solution is risky as well. We'll better use it directly, though it is less performant
            //when the special handling of ALDI Fotos users can be dropped in 2 years, we can tidy up here again.
            if (LOGIN_WITH_JSSDK !== "true") {
                setCookie(response.sessionInfo.cookieName, response.sessionInfo.cookieValue, {
                    
                    path: "/",
                    secure: true
                });
                
                await submitLoginEvent(response);
                return true;
            }
        }

       await gigyaWithPromise(gigya.accounts.login, {
            ignoreInterruptions: true,
            loginID: loginRequestParams.email,
            ...loginRequestParams
        });

        // we need an extra getAccountInfo to load "extraProfileFields"
        await refreshAccount();
        return true;
    }
    catch(errorResponse) {
        assertIsGigyaErrorResponse(errorResponse);

        if (handleLoginErrors(errorResponse, initRegistration, navigate, "siteLogin")) {
            return false;
        }
        throw errorResponse;
    }
}

/**
 * Handles typical login error responses.
 * Be careful about changes to this class. It's not sure that  errorResponse is always a response object.
 * @param {*} errorResponse The error response returned from gigya or a different error
 * @param {*} initRegistration account.initRegistration Function
 * @param {*} navigate React router navigate-Function
 * @param {*} origin the origin
 * @returns {boolean} true if the error response has been handles by this function and the caller is not responsible for handling it anymore. false otherwise
 */
export const handleLoginErrors = (errorResponse: any, initRegistration: InitRegistrationFunction, navigate: NavigateFunction, origin: NavigateOrigin) => {
    //pending registration error - account has missing mandatory fields
    if (errorResponse.errorCode === 206001 || errorResponse.errorCode === "206001") {
        initRegistration(errorResponse.regToken, errorResponse.profile, errorResponse.data, errorResponse.preferences);
        navigateToFinalizeRegistration(navigate, origin);
        return true;
    }

    return false;
}