import {ReactComponent as LogoNord} from '_assets/aldi-essentials-867/assets/brands/aldi/images/logo.svg';
import {ReactComponent as LogoSued} from '_assets/aldi-essentials-867/assets/brands/aldi/images/aldi-logo-sued.svg';
import "./Header.css"
import {MessageKey, useI18n} from "../../_shared/hooks/I18n";
import {useMemo} from "react";

function Header({ textProperty}: {textProperty?: MessageKey}) {
    const { getMessage } = useI18n();

    const text = useMemo(() => textProperty ? getMessage(textProperty) : null, [getMessage, textProperty]);

    return (
        <div className="header header-login" role="banner">
            <div className='logos'>
                <LogoNord aria-hidden={true} className="logo-nord" />
                <LogoSued aria-hidden={true} className="logo-sued" />
            </div>
            {text && (
            <div className="mod-copy">
                <div className="rte">
                    <p>{text}</p>
                </div>
            </div>
            )}
        </div>
    )
}

export default Header;
