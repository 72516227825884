import {useEffect, useLayoutEffect, useMemo, useState} from "react";

export interface Window {
    width: number,
    height: number
}

export interface ThresholdChanged {
    index: number
}

/**
 * Window size hook. Attention: state hook is changed with every window size change.
 */
export const useWindow = (): Window => {
    const [size, setSize] = useState<Window>({
        width: 0,
        height: 0
    });
    useLayoutEffect(() => {
        function updateSize() {
            setSize({width: window.innerWidth, height: window.innerHeight});
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return useMemo(() => size, [size]);
}

/**
 * You can define thresholds if the window size cross a threshold (lower or higher) the returned index changed.
 * @param thresholds width thresholds
 */
export const useRegisterWidthThreshold = (thresholds: number[] | number | undefined): ThresholdChanged => {
    const { width } = useWindow();
    const [index, setIndex] = useState<ThresholdChanged>({ index: 0 });

    useEffect(() => {
        if (thresholds) {
            const arr = Array.isArray(thresholds) ? thresholds : [thresholds];
            //const sorted = arr.sort((a, b) => a - b);
            const filtered = arr.filter((value, _idx) => width > value);
            setIndex({index: filtered.length});
        }
    }, [width, thresholds]);

    return useMemo(() => index, [index]);
}
