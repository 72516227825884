import { useI18n, getCdcErrorMessage } from '_shared/hooks/I18n';
import { useForm } from 'react-hook-form';
import { gigyaWithPromise, useGigya } from 'GigyaContext';
import {PerformLoginFunction, useLogin} from 'central-login-page/hooks/Hooks';
import { PasswordFieldWithValidation } from '_shared/components/PasswordField';
import { ErrorField } from '_shared/components/ErrorField';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { linkAccountsIfInLinkingFlow } from './LinkAccountsScreen';
import { useAccount } from 'Account';
import { useState } from 'react';
import {RegisterOptions} from "react-hook-form/dist/types/validator";

export const ResetPasswordScreen = () => {
    const [resetperformed, setresetperformed] = useState(false);

    return (
        <>
        { !resetperformed && <ResetPasswordForm showSuccess={() => setresetperformed(true)} /> }
        { resetperformed && <ResetPasswordSuccess  /> }
        </>
    )
}

function ResetPasswordForm ({showSuccess} : {showSuccess: () => void}) {
    const { getMessage } = useI18n();
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const gigyaCtx = useGigya();
    const [searchParams, ] = useSearchParams();
    const navigate = useNavigate();
    const { performLogin } = useLogin();
    const { cdcIncludeFields } = useAccount();

    if (!searchParams.get("pwrt")) {
        console.debug("No pwrt query parameter found. Redirecting to home page.");
        navigate("/");
        return <></>;
    }

    const performResetPassword = async (formData: any) => {
        if (formData.password !== formData.passwordretype) {
            setError("root.formError", {
                message: getMessage("clp.resetpassword.retypepassword.mismatch")
            });
            return;
        }
        try {
            if (!gigyaCtx.gigya) {
                return;
            }
            await gigyaWithPromise(gigyaCtx.gigya.accounts.resetPassword, {
                passwordResetToken: searchParams.get("pwrt")!,
                newPassword: formData.password
            });
        }
        catch(errorResponse: any) {
            console.error(errorResponse);
            
            setError("root.formError", {
                //errorCode: errorResponse.errorCode,
                message: getCdcErrorMessage(errorResponse.errorCode, getMessage, errorResponse.errorDetails)
            });

            return;
        }

        await autoLogin(performLogin, formData.password, cdcIncludeFields);
        showSuccess();
    }

    return (
        <>
            <div id="reset-password">
                <h2>{getMessage("clp.resetpassword.newpassword.title")}</h2>

                <p>{getMessage("clp.resetpassword.newpassword.introduction")}</p>
                <form onSubmit={handleSubmit(performResetPassword)} autoComplete="on">
                    <fieldset>
                        <label className="textfield">
                            <PasswordFieldWithValidation
                                {...{
                                    name: "password",
                                    error: errors.password,
                                    placeholder: getMessage("global.password"),
                                    autoComplete: "new-password",
                                }}
                                registerHandler={((passwordValidation: RegisterOptions) => register("password", passwordValidation) )}
                            />
                            <span className="textfield__label">{getMessage("global.password")}</span>
                            <ErrorField field={errors.password} />
                        </label>
                    </fieldset>
                    <fieldset>
                        <label className="textfield">
                            <PasswordFieldWithValidation
                                {...{
                                    name: "password-retype",
                                    error: errors.passwordretype,
                                    placeholder: getMessage("global.password.retype"),
                                    autoComplete: "new-password",
                                }}
                                registerHandler={((passwordValidation: RegisterOptions) => register("passwordretype", passwordValidation) )}
                            />
                            <span className="textfield__label">{getMessage("global.password")}</span>
                            <ErrorField field={errors.passwordretype} />
                        </label>
                    </fieldset>
                    <ErrorField field={errors.root?.formError} />
                    <button type="submit" className="btn btn-full btn--primary btn-right">{getMessage("clp.resetpassword.submit")}</button>
                </form>
            </div>
        </>
    )
}

function ResetPasswordSuccess () {
    const { getMessage } = useI18n();

    return (
        <>
            <p>{getMessage("clp.resetpassword.success.message", "Dein Passwort wurde erfolgreich geändert.")}</p>
        </>
    )
}

const autoLogin = async (performLogin: PerformLoginFunction, password: string, cdcIncludeFields: string) => {
    
    try {
        const pwResetLoginID = localStorage.getItem("pwResetLoginID");
        if (pwResetLoginID) {
            localStorage.removeItem("pwResetLoginID");

            if (await linkAccountsIfInLinkingFlow(performLogin, {
                email: pwResetLoginID,
                password: password,
                include: cdcIncludeFields
            })) {
                return;
            }

            await performLogin({
                email: pwResetLoginID,
                password: password,
                include: cdcIncludeFields
                // extraProfileFields: extraProfileFields
            });
        }
    }
    catch (errorResponse) {
        console.error("Automatic login failed. LoginID might have been changed since password reset token was generated. Redirecting to login page for login.");
        console.error(errorResponse);
    }
}