import {AccountFormItemDetail, RegisterFormInputFunction} from "../../../hooks/GigyaSchema";
import {AccountSchema} from "../../../GigyaContext";
import "./NewsletterComponent.css";
import {Cms} from "../../../CmsContext";
import {UseFormSetValue} from "react-hook-form/dist/types/form";
import { ChangeEvent, Fragment, useCallback, useMemo } from "react";
import { SwitcherComponent } from "../SwitcherComponent";
import { useI18n } from "_shared/hooks/I18n";
import { ErrorField } from "_shared/components/ErrorField";
import { hasNewsletterSubscriptions, useAccount } from "Account";

export interface NewsletterComponentProps {
    component: Cms.SubscriptionTiles,
    accountSchema?: AccountSchema,
    registerFormInput:RegisterFormInputFunction<any>,
    formItemDetails:Map<string, AccountFormItemDetail<any>>,
    setValue: UseFormSetValue<any>
}

export const NewsletterComponent = ({component, accountSchema, registerFormInput, formItemDetails, setValue}: NewsletterComponentProps) => {

    const { getMessage } = useI18n();
    const { account } = useAccount();

    const newsletterKeys = useMemo(() => getDisplayNewsletters(accountSchema!, component.subscriptions)
        , [accountSchema, component.subscriptions]);

    const changeAll = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        newsletterKeys.forEach(key => {
            setValue("subscriptions." + key + ".email.isSubscribed", event.target.checked, { shouldDirty: true });
        });
        
    }, [newsletterKeys, setValue]);

    if (newsletterKeys.length === 0) {
        return <></>;
    }

    const components = <SwitcherComponents newsletterKeys={newsletterKeys} component={component} registerFormInput={registerFormInput} formItemDetails={formItemDetails} />;
    return (
            <div className="preferences newsletter">
                {component.headline && <h3>{component.headline}</h3>}
                {newsletterKeys.length === 1 
                    ? components
                    : <SwitcherComponent headline={getMessage("mpp.newsletter.toggleall.headline")} onChange={changeAll} defaultChecked={hasNewsletterSubscriptions(account)}>
                        {components}
                    </SwitcherComponent>
                }
            </div>
    );
}

const getDisplayNewsletters = (accountSchema: AccountSchema, subscriptions?: Cms.SubscriptionMap) => {
    if (!accountSchema.subscriptionsSchema?.fields) {
        return [];
    }
    return Object.keys(accountSchema.subscriptionsSchema?.fields)
        .filter(key => isNewsletterSubscription(key))
        .filter(key => !isFutureNewsletter(key, subscriptions))
        .filter(key => !isTimedOutNewsletter(key, subscriptions));
}

const isNewsletterSubscription = (key: string) => {
    return key.startsWith("sfmc.NLT");
}

const isFutureNewsletter = (key: string, subscriptions?: Cms.SubscriptionMap) => {
    const meta = subscriptions?.[key];
    return meta?.displayStart && new Date(meta.displayStart) > new Date();
}

const isTimedOutNewsletter = (key: string, subscriptions?: Cms.SubscriptionMap) => {
    const meta = subscriptions?.[key];
    return meta?.displayEnd && new Date(meta.displayEnd) < new Date()
}

interface SwitcherComponentsProps {
    newsletterKeys: string[],
    component: Cms.SubscriptionTiles,
    registerFormInput:RegisterFormInputFunction<any>,
    formItemDetails:Map<string, AccountFormItemDetail<any>>
}

const SwitcherComponents = ({newsletterKeys, component, registerFormInput, formItemDetails}: SwitcherComponentsProps ) => {
    
    return <> {newsletterKeys.map(key => {
            const formField = "subscriptions." + key + ".email.isSubscribed";
            const formItemDetail= formItemDetails.get(formField);
            const meta = component?.subscriptions?.[key];

            const {useFormRegisterReturn, errorDetails} = registerFormInput(formItemDetail!);
            return <Fragment key={key}>
                <SwitcherComponent icon={meta?.icon} headline={meta?.name ?? key}
                                descriptions={meta?.descriptions}
                                defaultChecked={formItemDetail?.value ?? false}
                                {...useFormRegisterReturn}
                                >
                    <ErrorField id={`error-${formField}`} field={errorDetails} />
                </SwitcherComponent>
            </Fragment>;
        })
    }
    </>
}