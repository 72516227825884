import {PasswordField} from "./PasswordField";
import {ErrorField} from "./ErrorField";
import {useI18n} from "../hooks/I18n";
import {useLogin} from "../../central-login-page/hooks/Hooks";
import React, {forwardRef, Ref, useImperativeHandle, useRef, useState} from "react";
import {FieldError} from "react-hook-form";
import {assertIsGigyaErrorResponse} from "../../GigyaContext";

/**
 * Field to show a password confirmation field.
 *
 * @param ref reference to trigger check, call ref.confirm() to start. Promise returns success.
 */
export const CheckPasswordField = forwardRef(({dummy}: {dummy?: boolean}, ref: Ref<any>) => { // dummy is against "no empty object" error, I don't know how to fix it.
    const { getMessage, getCdcErrorMessage } = useI18n();
    const { checkPassword } = useLogin();

    const [passwordError, setPasswordError] = useState<FieldError | null>(null);
    const refField = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => {
        return {
            confirm(): Promise<void> {
                return new Promise(function(resolve, reject) {
                    checkPassword(refField.current!.value)
                        .then(_ => {
                            setPasswordError(null);
                            resolve();
                        }).catch(response => {
                            assertIsGigyaErrorResponse(response);
                            setPasswordError({
                                type: "pattern",
                                message: getCdcErrorMessage(response, "password")
                            });
                            reject();
                        });
                });
            }
        }
    }, [checkPassword, getCdcErrorMessage]);

    return (
        <label className="textfield">
        <PasswordField
            {...{
                ref: refField,
                name: "password",
                error: passwordError,
                placeholder: getMessage("global.password"),
                autoComplete: "current-password",
                required: true
            }}
        />
        <span className="textfield__label">{getMessage("global.password")}</span>
        <ErrorField field={passwordError} />
    </label>)
});