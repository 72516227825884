import "./MarketingPreferencesLayout.css";
import "./MarketingPreferencesLayout.css";

import {Cms, useCmsData} from "CmsContext";
import {PreferenceTab, PreferenceTabHandle} from "./components/PreferenceTab";
import {useEffect, useMemo, useRef, useState, useReducer} from "react";
import {AccountSchema, gigyaWithPromise, isGigyaErrorResponse, useGigya} from "../GigyaContext";
import {Header} from "./components/Header";
import {Footer} from "./components/Footer";
import {MessageKey, useI18n} from "_shared/hooks/I18n";
import {Snackbar} from "./components/Snackbar";
import {Optin} from "./components/Optin";
import {useLocation, useNavigate} from "react-router-dom";
import {ScrollSlider} from "./components/ScrollSlider";
import {containsNewsletterSubscriptionInSubscribedStatus, hasNewsletterSubscriptions, useAccount} from "../Account";
import {UserCentricsComponent} from "../_shared/components/UserCentricsComponent";
import {AdobeTagManagerComponent} from "../_shared/components/AdobeTagManagerComponent";
import {Meta} from "../_shared/components/Meta";
import {useMetaData} from "../MetaDataContext";
import {CDNResponsiveImage} from "../_shared/components/CDNResponsiveImage";
import {mergeMaps, replaceEmptyStringsWithNull, toNestedObject} from "../_shared/Utils";
import {FieldOverwritingData} from "../hooks/GigyaSchema";
import { ProductReminderDeletionFailedError, useProductReminderContext } from "./components/productreminder";
import { ConfirmOverlay } from "../_shared/components/confirmoverlay";
import { CmsTabStatus, useTabsStatus } from "./components/MarketingPreferencesTabStatus";
import { FieldValues } from "react-hook-form";

interface ConfirmOverlayCallback {
    promiseResolver: (confirmed: boolean) => void;
}

class DataModification {
    data: FieldOverwritingData<any>;
    type: "add" | "reset";

    constructor();
    constructor(data: FieldOverwritingData<any>);
    constructor(...myArray: any[]) {
        if (myArray.length === 0) {
            this.data = new Map();
            this.type = "reset";
        }else {
            this.data = myArray[0];
            this.type = "add";
        }
    }
}

export const MarketingPreferencesLayout = () => {
    const {cmsData} = useCmsData();
    const { getMessage } = useI18n();
    const {isGigyaReady, gigya, getSchema} = useGigya();
    const { hash } = useLocation();
    const {account, refreshAccount} = useAccount();
    const navigate = useNavigate();

    const tabsStatus = useTabsStatus(cmsData?.components?.preferenceCenter?.tabs);

    const [activeTab, setActiveTab] = useState(() => {
        // search active tab from URL anchor (i.e., #data)
        let activeTab = cmsData ? cmsData.components.preferenceCenter.tabs[0] : null;
        if(cmsData){
            cmsData.components.preferenceCenter.tabs.map((item,_idx) => {
                if(hash && hash.substring(1) === item.anchor) {
                    activeTab = item;
                }
                return item;
            });
        }
        return activeTab;
    });

    const tabStatusOfActiveTab = activeTab ? tabsStatus.get(activeTab) : undefined;

    const { productReminderContext, deleteMarkedProductReminders, reloadProductReminders } = useProductReminderContext();

    const formRef = useRef<PreferenceTabHandle<any>>(null);
    const scrollAnchorRef = useRef<HTMLDivElement>(null);

    const [gigyaSchema, setGigyaSchema] = useState<AccountSchema>();
    const [snackbarVisibility, setSnackbarVisibility] = useState(false);
    const [globalErrorMessage, setGlobalErrorMessage] = useState("");
    const [confirmOverlayCallback, setConfirmOverlayCallback] = useState<ConfirmOverlayCallback | undefined>();

    const [showClickedOnDisabledTabDialog, setShowClickedOnDisabledTabDialog] = useState(false)

    const [data, changeData] = useReducer(
        (state: FieldOverwritingData<any>, action: DataModification) => {
            if (action.type === "add") {
                return mergeMaps(state, action.data) as FieldOverwritingData<any>;
            }
            return new Map() as FieldOverwritingData<any>;
        },
        new Map());

    useMemo(() => {
        // effect reloads the active tab if cmsData is changed (i.e., language switch)
        //
        if (cmsData && !!cmsData.components.preferenceCenter.tabs.length) {
            const activeTabForMe = cmsData.components.preferenceCenter.tabs.find(tab => tab.anchor === activeTab?.anchor) ?? cmsData.components.preferenceCenter.tabs[0]
            setActiveTab(activeTabForMe);
        }
    }, [cmsData, activeTab?.anchor]);

    useEffect(() => {
        if (!isGigyaReady()) {
            return;
        }
        getSchema!().then(setGigyaSchema); // was ist denn das?
    }, [isGigyaReady, getSchema]);

    const setActiveTabWithHistory = (validateAndSaveIntoState:boolean) => (tab: Cms.Tab) => {
        const tabHandle = formRef.current;
        if (!tabHandle) {
            return;
        }

        const validRun = () => {
            setActiveTab(tab);
            window.history.replaceState({}, tab.label, document.URL.replace(/#.*$/, "") + "#" + tab.anchor);
        }

        if (validateAndSaveIntoState) {
            tabHandle.validateAll()
                .then(() => tabHandle.getData())
                .then(d =>  changeData(new DataModification(d)))
                .then(validRun)
                .catch(_ => console.error);

        }else {
            validRun();
        }
    }

    const onTabClickHandler = (tab: Cms.Tab) => {
        if (tabsStatus.get(tab)?.enabled) {
            setActiveTabWithHistory(!!tabStatusOfActiveTab?.enabled)(tab);
        } else {
            setShowClickedOnDisabledTabDialog(!!tabStatusOfActiveTab?.enabled);
        }
    }

    const showNextTabAfterSaving = function () {
        if (tabsStatus.size === 0) {
            return;
        }

        let active: CmsTabStatus | undefined;
        if (!activeTab) {
            active = tabsStatus.values().next().value;
        } else {
            const tabsStatusArray = Array.from(tabsStatus.values());
            const currentTabIndex = tabsStatusArray.map(s => s.tab).indexOf(activeTab);
            active = tabsStatusArray.find((tabStatus, index) => {
                return tabStatus.enabled && index > currentTabIndex;
            });
            if (!active) {
                active = tabsStatusArray.find((tabStatus, index) => {
                    return tabStatus.enabled;
                })
            }
        }
        
        if (active) {
            setActiveTabWithHistory(false)(active.tab);
            window.scrollBy({
                "top": scrollAnchorRef.current ? scrollAnchorRef.current.getBoundingClientRect().top - 20 : 0,
                "left": 0,
                "behavior": "smooth"
            });
        }
    }

    if (tabStatusOfActiveTab && !tabStatusOfActiveTab.enabled) {
        showNextTabAfterSaving();
        return(<></>);
    }

    if (!cmsData || !gigyaSchema || !gigya) {
        return(<></>);
    }

    const validateAndSend = (formData: FieldValues) => {
        formRef.current!.validateAll()
            .then(() => new Map(Object.entries(formData)))
            .then(d => { changeData(new DataModification(d)); return d; })
            .then(d => mergeMaps(data, d))
            .then(Object.fromEntries)
            .then(toNestedObject)
            .then(obj => performSetAccountInfo(obj));
    }

    const performSetAccountInfo = async (formData: any) => {
        setGlobalErrorMessage("");
        formRef.current!.clearErrors();

        //copy data
        const submitData = JSON.parse(JSON.stringify(formData))

        let unsubscribeAll = false;
        
        if (submitData.subscriptions) {
            const activeNewsletterPresent = containsNewsletterSubscriptionInSubscribedStatus(submitData.subscriptions);

            const activeProductReminderPresent = productReminderContext.productReminders.length > productReminderContext.productReminderIdsMarkedForDelete.length;

            unsubscribeAll = !activeNewsletterPresent && !activeProductReminderPresent;
        }
        try {
            if (unsubscribeAll) {
                const confirmed = await new Promise<boolean>((resolve) => {
                    setConfirmOverlayCallback({ promiseResolver: resolve })
                });
                if (!confirmed) {
                    return;
                }
            }
            
            const doPerformSetAccountInfo = async () => {
                const requestParams = {
                    ...replaceEmptyStringsWithNull(submitData), // clear data - replace empty strings with null
                    regToken: account.regToken
                }
                console.debug("SEND DATA ", requestParams);
                await gigyaWithPromise(gigya!.accounts.setAccountInfo, requestParams);
                if (!unsubscribeAll) {
                    await refreshAccount();
                    changeData(new DataModification()); // reset
                }
            };

            const performDeleteProductReminders = async () => {
                await deleteMarkedProductReminders();
                if (!unsubscribeAll) {
                    reloadProductReminders();
                }
            }

            await Promise.all([doPerformSetAccountInfo(), performDeleteProductReminders()]);

            if (!unsubscribeAll) {
                setSnackbarVisibility(true);
                setTimeout(() => setSnackbarVisibility(false), 5000); // Hide after 5 seconds

                showNextTabAfterSaving();
            } else {
                navigate("/unsubscribe-success");
            }
        }
        catch (error: unknown) {
            if (isGigyaErrorResponse(error)) {
                console.error("Error during setAccountInfo", error);
                const cdcErrorMessage = getMessage(`mpp.errors.${error.errorCode}.general` as MessageKey, error.errorDetails);
                setGlobalErrorMessage(cdcErrorMessage);

                if (error.validationErrors) {
                    for(const validationError of error.validationErrors) {
                        formRef.current!.setError(validationError.fieldName, {type: "custom", message: getMessage(`mpp.errors.${validationError.errorCode}.${validationError.fieldName}` as MessageKey, validationError.message)});
                    }
                }
            }
            else if (error instanceof ProductReminderDeletionFailedError) {
                console.error("Error during product reminder deletion", error);
                setGlobalErrorMessage(getMessage("mpp.errors.productreminders.deletion.failed"));
            }
            else {
                throw error;
            }
        }
        finally {
            setConfirmOverlayCallback(undefined);
        }
    }

    return (
        <div className="preference-center">
            <Meta titleProperty="mpc.page.title" />
            <UserCentricsComponent pageType={"Form Page"} pageName={getMessage("tracking.cpp.form")} primaryCategory={getMessage("tracking.cpp.form")} />
            <AdobeTagManagerComponent />
            <Header header={cmsData.components.header} />
            <div className="page__main" role="main">
                <div className="page__content">
                    <div className="container-full">
                        <div className="mod mod-overview-intro-2">
                            <div className="mod-overview-intro-2__media">
                                <CDNResponsiveImage
                                    className="img-responsive img-cover cq-dd-image"
                                    title={cmsData.components.preferenceCenter.welcomeimage.alt}
                                    alt={cmsData.components.preferenceCenter.welcomeimage.alt}
                                    sizes="(min-width: 1817px) 1817px, 100vw"
                                    src={cmsData.components.preferenceCenter.welcomeimage.src}
                                    widths={[768, 1024, 1817]} // no other sizes in germany: could be added "3000" for retina displays
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="mod mod-headline">
                            <h1 data-binding="true">
                                {getMessage("mpp.headline.pre")}
                                <span className="preSpaceContent">{account?.profile?.firstName && (` ${account?.profile?.firstName}`)}</span>
                                {getMessage("mpp.headline.post")}
                            </h1>
                        </div>
                        <div className="mod mod-copy">
                            <div className="rte">
                                <p>{getMessage("mpp.description")}</p>
                            </div>
                        </div>
                        <div ref={scrollAnchorRef} className="mod mod-headline">
                            <h2>{getMessage("mpp.subheadline")}</h2>
                        </div>
                        <div className="mod mod-tabs">
                            <div className="mod-tabs__nav">
                                <ScrollSlider items={Array.from(tabsStatus.values())}
                                    activeItem={tabStatusOfActiveTab}
                                    callbackFunction={onTabClickHandler}/>
                            </div>
                            <div className="mod-tabs__content">
                                {tabStatusOfActiveTab && <PreferenceTab ref={formRef}
                                    key={tabStatusOfActiveTab.tab.anchor}
                                    tabStatus={tabStatusOfActiveTab}
                                    accountSchema={gigyaSchema}
                                    globalErrorMessage={globalErrorMessage}
                                    saveForm={validateAndSend}
                                    dataToOverwrite={data as any}
                                />}
                                {confirmOverlayCallback && <ConfirmUnsubscribeAllOverlay confirmCallback={confirmOverlayCallback} />}
                                {showClickedOnDisabledTabDialog && <ClickedOnDisabledTabDialog confirmCallback={() => setShowClickedOnDisabledTabDialog(false)} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer footer={cmsData.components.footer} socialMediaBar={cmsData.components.socialmediabar}></Footer>
            <Snackbar headline={getMessage("mpp.successheadline")} description={getMessage("mpp.successdescription")} isActive={snackbarVisibility} hideHandler={()=>setSnackbarVisibility(false)} />
            <DataProcessingConsentedOptInDialog />
        </div>
    )
}



const ConfirmUnsubscribeAllOverlay = ( { confirmCallback } : { confirmCallback: ConfirmOverlayCallback } ) => {
    const { getMessage } = useI18n();

    return <ConfirmOverlay headline={getMessage("mpp.unsubscribeall.confirm.headline")}
        description={getMessage("mpp.unsubscribeall.confirm.description")}
        confirmText={getMessage("mpp.unsubscribeall.confirm.confirm")}
        denyText={getMessage("mpp.unsubscribeall.confirm.deny")}
        confirmHandler={() => confirmCallback.promiseResolver(true)}
        denyHandler={() => confirmCallback.promiseResolver(false)} />
}

const ClickedOnDisabledTabDialog = ( { confirmCallback } : { confirmCallback: () => void } ) => {
    const { getMessage } = useI18n();

    return <ConfirmOverlay headline={getMessage("mpp.newsletter.subscribe.info.headline")}
        description={getMessage("mpp.newsletter.subscribe.info.description")}
        confirmText={getMessage("mpp.newsletter.subscribe.info.confirm")}
        confirmHandler={confirmCallback} />
}

const DataProcessingConsentedOptInDialog = () => {
    const {metaData} = useMetaData();
    const {account} = useAccount();
    const [optInVisibility, setOptInVisibility] = useState(false);

    useEffect(() => {
        //if the customer has no NL subscriptions, he'll not see the Opt-In-Dialog
        if (!hasNewsletterSubscriptions(account)) {
            setOptInVisibility(false);
            return;
        }

        let forceConsent = metaData?.marketingPreferenceCenter.forceConsent===true;
        let consented = account.data?.sfmc?.dataProcessingConsented===true;
        setOptInVisibility(forceConsent && !consented);

    }, [account, metaData?.marketingPreferenceCenter.forceConsent]);

    if (!optInVisibility) {
        return <></>;
    }
    return <Optin hideHandler={() => setOptInVisibility(false)} />
}