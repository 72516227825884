import {AccountHomePage} from "account-page/screens/AccountHomePage";
import {AccountLayout} from "account-page/screens/AccountLayout";
import {CentralLoginLayout} from 'central-login-page/CentralLoginLayout';
import {DoubleOptInDoneScreen} from "central-login-page/screens/DoubleOptInDoneScreen";
import {FinalizeRegistrationScreen, NavigateOrigin} from 'central-login-page/screens/FinalizeRegistrationScreen';
import {LinkAccountsScreen} from "central-login-page/screens/LinkAccountsScreen";
import {LoginScreen} from 'central-login-page/screens/LoginScreen';
import {RegistrationScreen} from 'central-login-page/screens/RegistrationScreen';
import {ResetPasswordScreen} from "central-login-page/screens/ResetPasswordScreen";
import {ResetPasswordStartProcessScreen} from "central-login-page/screens/ResetPasswordStartProcessScreen";
import {ErrorPage} from "central-page/ErrorPage";
import {MainLayout} from "central-page/MainLayout";
import {MarketingPreferencesLayout} from "marketing-preferences-page/MarketingPreferencesLayout";
import {MarketingPreferencesProvider} from "marketing-preferences-page/MarketingPreferencesProvider";
import {createBrowserRouter, NavigateFunction, Outlet, RouterProvider} from "react-router-dom";
import { UnsubscribePage } from "marketing-preferences-page/UnsubscribePage";
import {ErrorPagePreferences} from "./marketing-preferences-page/ErrorPagePreferences";
import { PersonalData } from "account-page/screens/PersonalData";
import { Addresses } from "account-page/screens/Addresses";
import { AccessData } from "account-page/screens/AccessData";
import { RegistrationConfirmationScreen, SsoFlowContinuingScreen } from "central-login-page/screens/RegistrationConfirmationScreen";
import { DOIPagePreferences } from "marketing-preferences-page/DOIPagePreferences";

export const AldiRouterProvider = () => {

    const router = createBrowserRouter([
    {
        path: "/",
        element: <MainLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <AccountLayout><AccountHomePage /></AccountLayout>
            },
            {
                path: "login",
                children: [
                    {
                        index: true,
                        element: <CentralLoginLayout><LoginScreen /></CentralLoginLayout>
                    },
                    {
                        path: "reset-password/start",
                        element: <CentralLoginLayout><ResetPasswordStartProcessScreen /></CentralLoginLayout>
                    },
                    {
                        path: "reset-password",
                        element: <CentralLoginLayout><ResetPasswordScreen /></CentralLoginLayout>
                    },
                    {
                        path: "redirect",
                        element: <SsoFlowContinuingScreen />
                    }

                ]
            },
            {
                path: "registration",
                children: [
                    {
                        index: true,
                        element: <CentralLoginLayout><RegistrationScreen /></CentralLoginLayout>
                    },
                    {
                        path: "finalize",
                        element: <CentralLoginLayout><FinalizeRegistrationScreen /></CentralLoginLayout>
                    },
                    {
                        path: "confirmation",
                        element: <RegistrationConfirmationScreen />
                    }
                ]
            },
            {
                path: "double-opt-in-done",
                element: <DoubleOptInDoneScreen />
            },
            {
                path: "social/link-accounts",
                element: <LinkAccountsScreen />
            },
            {
                path: "addresses",
                element: <AccountLayout><Addresses /></AccountLayout>
            },
            {
                path: "personaldata",
                element: <AccountLayout><PersonalData /></AccountLayout>
            },
            {
                path: "accessdata",
                element: <AccountLayout><AccessData /></AccountLayout>
            }
        ],
    },
    {
        path: "/preferences",
        element: <Outlet />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "token",
                element: <MarketingPreferencesProvider/>,
                errorElement: <ErrorPage />,
                children: [
                    {
                        path: ":token",
                        element: <MarketingPreferencesLayout />,
                        errorElement: <ErrorPage />
                    },
                ]
            },
            {
                path: "double-opt-in-done",
                element: <DOIPagePreferences headline="mpp.doi.headline" description="mpp.doi.description" />
            },
            {
                path: "error/:errorCodeParameter",
                element: <ErrorPagePreferences headline="Es ist ein unerwarteter Fehler aufgetreten" />
            },
            {
                path: "*",
                element: <MarketingPreferencesProvider />
            },
            {
                index: true,
                element: <MarketingPreferencesProvider />
            }
        ]
    },
    {
        path: "/unsubscribe-success",
        element: <UnsubscribePage />
    },
    {
        path: "/error/:errorCode",
        element: <ErrorPage />
    }
    ]);

    return <RouterProvider router={router} />
}

export const navigateToFinalizeRegistration = (navigate: NavigateFunction, origin: NavigateOrigin) => {
    navigate("/registration/finalize", { state: { origin: origin} } );
}
