import { useEffect, useMemo, useReducer, createContext, useContext, ReactNode, Dispatch, useCallback } from "react";
import { useGigya, gigyaWithPromise, logGigyaResponseErrorDetails, assertIsGigyaErrorResponse, GigyaWebSdk } from "GigyaContext";
import { continueSsoFlow, ensureSsoFlowResumed } from "_shared/SsoUtils";

export interface AccountContext {
    /**
     * The account object the user is logged in with. This object is cached and can be refreshed by using the refreshAccount() member 
     */
    account: Account,
    /**
     * Allows to perform an account refresh, in case up-to-date data is required.
     */
    refreshAccount: RefreshAccountFunction,
    /**
     * Allows to perform an account refresh, in case up-to-date data is required.
     */
    loadAccount: LoadAccountFunction,
    /**
     * In case the account is not registered, a regToken has to be used
     */
    initRegistration: InitRegistrationFunction,
    submitLoginEvent: SubmitLoginEventFunction,

    cdcIncludeFields: string,
    extraProfileFields: string
}

export interface Account {
    loggedIn: boolean,
    UID?: string,
    profile?: AccountProfile,
    data?: AccountData,
    preferences?: AccountPreferences,
    subscriptions?: AccountSubscriptions,
    regToken?: string
}

export interface Phone {
    number: string,
    type?: string,
}

export interface AccountProfile {
    email: string,
    firstName?: string,
    lastName?: string,
    zip?: string,
    country?: string,
    phones?: Phone[] | Phone
}

export interface AccountData {
    customerassignment?: string,
    salutation?: string,
    sfmc?: AccountDataSfmc
    preference?: AccountDataPreference
}

export interface AccountDataPreference {
    FAVORITE_STORE: string,
}

export interface AccountDataSfmc {
    dataProcessingConsented?: boolean,
    subscriptionKey: string
}

export interface AccountPreferences {
    /**
     * key of the given consent; e.g. privacy.aec 
     */
    [key: string]: AccountPreference
}

export interface AccountPreference {
    isConsentGranted: boolean,
    docDate: string,
    lang: string,
    lastConsentModified: string,
    actionTimestamp: string
}

export interface EmailSubscription {
    isSubscribed: boolean,
    doubleOptIn: {
        status: "NotConfirmed" | "Pending" | "Confirmed"
    }
}

export interface AccountSubscription {
    email: EmailSubscription
}

export interface AccountSubscriptions {
    SVC_PRODUCT_REMINDER?: AccountSubscription,
    sfmc?: Record<string, AccountSubscription | undefined>
}

type AccountReducerAction = AccountReducerLoginAction | AccountReducerLogoutAction | AccountReducerInitRegistrationAction | AccountReducerGetAccountInfoAction;

interface AccountReducerLoginAction {
    type: "login",
    loginResponse: GigyaWebSdk.LoginResponse
}

interface AccountReducerLogoutAction {
    type: "logout"
}

interface AccountReducerInitRegistrationAction {
    type: "initRegistration",
    regToken: string,
    profile?: AccountProfile,
    data?: AccountData,
    preferences?: AccountPreferences,
    subscriptions?: AccountSubscriptions
}

interface AccountReducerGetAccountInfoAction {
    type: "getAccountInfo",
    response: GigyaWebSdk.GetAccountInfoResponse,
    regToken?: string
}

export type InitRegistrationFunction = (regToken: string, profile?: AccountProfile, data?: AccountData, preferences?: AccountPreferences) => void
export type RefreshAccountFunction = () => Promise<void>;
export type LoadAccountFunction = (regToken: string) => Promise<void>;
export type SubmitLoginEventFunction = (loginResponse: GigyaWebSdk.LoginResponse) => Promise<void>;

type AccountInfoResponse = GigyaWebSdk.GetAccountInfoResponse | GigyaWebSdk.LoginResponse;

const defaultCdcIncludeFields = "data,preferences,profile";
const defaultCdcExtraProfileFields = 'phones';

const anonymousAccount: Account = {
    loggedIn: false
}

const anonymousAccountContext: AccountContext = {
    account: anonymousAccount,
    refreshAccount: () => Promise.resolve(),
    loadAccount: (regToken) => Promise.resolve(),
    initRegistration: () => {},
    submitLoginEvent: async () => {},
    cdcIncludeFields: defaultCdcIncludeFields,
    extraProfileFields: defaultCdcExtraProfileFields
}

const accountContext = createContext<AccountContext>(anonymousAccountContext);

function createAccount(loginResponse: AccountInfoResponse): Account {
    return {...anonymousAccount,
        loggedIn: true,
        UID: loginResponse.UID,
        ...(loginResponse.profile && {profile: loginResponse.profile}),
        ...(loginResponse.data && {data: loginResponse.data}),
        ...(loginResponse.preferences && {preferences: loginResponse.preferences}),
        ...(loginResponse.subscriptions && {subscriptions: loginResponse.subscriptions})
    }
}

export const AccountProvider = ({children, cdcIncludeFields = defaultCdcIncludeFields, extraProfileFields = defaultCdcExtraProfileFields}: {children: ReactNode, cdcIncludeFields?: string, extraProfileFields?:string}) => {
    const [account, dispatchAccount] = useReducer(accountReducer, anonymousAccount);
    
    const { gigya, onReadyCallbackRegistry } = useGigya();

    useEffect(() => {
        const onReady = async ({ gigya }: {gigya: GigyaWebSdk.Gigya}) => {

            gigya.accounts.addEventHandlers({
                onLogin: onLoginHandler(gigya, dispatchAccount),
                onLogout: onLogoutHandler(dispatchAccount)
            });
            
            try {
                const response = await gigyaWithPromise(gigya.accounts.getAccountInfo, {
                    include: cdcIncludeFields,
                    extraProfileFields: extraProfileFields,
                });
                const submitLoginEvent = submitLoginEventFunc(gigya, dispatchAccount);
                await submitLoginEvent(response);
            }
            catch (error: unknown) {
                assertIsGigyaErrorResponse(error);
                if (error.errorCode !== 403005) {
                    logGigyaResponseErrorDetails("Unexpected error during call to getAccountInfo", error);
                }
            }
        }
        onReadyCallbackRegistry.addListener(onReady);
        return () => onReadyCallbackRegistry.removeListener(onReady);
    }, [onReadyCallbackRegistry, cdcIncludeFields, extraProfileFields]);
   
    const { Provider } = accountContext;

    const refreshAccountFunc: RefreshAccountFunction = useMemo(() => createRefreshAccountFunc(gigya, dispatchAccount, cdcIncludeFields, account.regToken, extraProfileFields)
    , [gigya, cdcIncludeFields, account.regToken, extraProfileFields]);

    const loadAccountFunc: LoadAccountFunction = useCallback((regToken: string) => createRefreshAccountFunc(gigya, dispatchAccount, cdcIncludeFields, regToken, extraProfileFields)()
    , [gigya, cdcIncludeFields, extraProfileFields]);

    const value = useMemo(() => {
        return {
            account: account,
            refreshAccount: refreshAccountFunc,
            loadAccount: loadAccountFunc,
            initRegistration: (regToken: string, profile?: AccountProfile, data?: AccountData, preferences?: AccountPreferences) => dispatchAccount(createInitRegistrationAction(regToken, profile, data, preferences)),
            submitLoginEvent: submitLoginEventFunc(gigya, dispatchAccount),
            cdcIncludeFields: cdcIncludeFields,
            extraProfileFields: extraProfileFields
    }}, [account, refreshAccountFunc, loadAccountFunc, gigya, cdcIncludeFields, extraProfileFields]);

    return(
        <Provider value={value}>
            {children}
        </Provider>
    )
}

const onLoginHandler = (gigya: GigyaWebSdk.Gigya | null, dispatchAccount: Dispatch<AccountReducerAction>) => (loginResponse: GigyaWebSdk.LoginResponse) => {
    localStorage.removeItem("pwResetLoginID");
    if (!loginResponse.newUser) {
        continueSsoFlow(gigya);
    }
    dispatchAccount(createLoginAction(loginResponse));
}

const submitLoginEventFunc = (gigya: GigyaWebSdk.Gigya | null, dispatchAccount: Dispatch<AccountReducerAction>) => async (loginResponse: GigyaWebSdk.LoginResponse) => {
    localStorage.removeItem("pwResetLoginID");
    await ensureSsoFlowResumed(gigya);
    dispatchAccount(createLoginAction(loginResponse));
}

const onLogoutHandler = (dispatchAccount: Dispatch<AccountReducerAction>) => () => {
    dispatchAccount(createLogoutAction());
}

const accountReducer = (account: Account, action: AccountReducerAction): Account => {
    switch (action.type) {
        case "logout":
            return anonymousAccount;
        case "login":
            return createAccount(action.loginResponse);
        case "initRegistration":
            return {
                ...account, 
                regToken: action.regToken,
                profile: action.profile,
                data: action.data,
                preferences: action.preferences
            }
        case "getAccountInfo":
            return {
                ...account,
                profile: action.response.profile,
                data: action.response.data,
                preferences: action.response.preferences,
                subscriptions: action.response.subscriptions,
                ...(action.regToken && { regToken: action.regToken })
            }
    }
}

const createLogoutAction = (): AccountReducerLogoutAction => {
    return {
        type: "logout"
    };
}

const createLoginAction = (loginResponse: GigyaWebSdk.LoginResponse): AccountReducerLoginAction => {
    return {
        type: "login",
        loginResponse: loginResponse
    };
}

const createInitRegistrationAction = (regToken: string, profile?: AccountProfile, data?: AccountData, preferences?: AccountPreferences): AccountReducerInitRegistrationAction => {
    return {
        type: "initRegistration",
        "regToken": regToken,
        ...(profile && { "profile": profile }),
        ...(data && { "data": data }),
        ...(preferences && { "preferences": preferences }),
    };
}

const createGetAccountInfoAction = (response: GigyaWebSdk.GetAccountInfoResponse, regToken?: string): AccountReducerGetAccountInfoAction => {
    return {
        type: "getAccountInfo",
        response: response,
        regToken: regToken
    };
}

const createRefreshAccountFunc = (gigya: GigyaWebSdk.Gigya | null, dispatchAccount: Dispatch<AccountReducerAction>, cdcIncludeFields: string, accountRegToken?: string, extraProfileFields?:string) => async (regToken = accountRegToken): Promise<void> => {
    if (!gigya) {
        return;
    }

    try {
        const response = await gigyaWithPromise(gigya.accounts.getAccountInfo, {
            include: cdcIncludeFields,
            extraProfileFields: extraProfileFields,
            ...(regToken && { "regToken": regToken })
        });
        
        dispatchAccount(createGetAccountInfoAction(response, regToken));
    }
    catch (errorResponse) {
        assertIsGigyaErrorResponse(errorResponse);
        console.error("Unexpected error during call to getAccountInfo", errorResponse);
        dispatchAccount(createLogoutAction());
        throw errorResponse;
    }
}

export const hasNewsletterSubscriptions = (account: Account): boolean => {
    if (!account?.subscriptions) {
        return false;
    }

    return containsNewsletterSubscriptionInSubscribedStatus(account?.subscriptions);
}

export const containsNewsletterSubscriptionInSubscribedStatus = (subscriptions: AccountSubscriptions): boolean => {
    if (!subscriptions?.sfmc) {
        return false;
    }

    return Object.keys(subscriptions.sfmc)
        .filter(key => key.startsWith("NLT"))
        .find(newsletterKey => subscriptions.sfmc![newsletterKey]!.email.isSubscribed) !== undefined;
}

/**
 * Hook to access the account and information like if it is logged in etc.
 * usage: const { account, refreshAccount } = useAccount()}
 * 
 * @returns 
 */
export const useAccount = (): AccountContext => useContext(accountContext);