import {ReactComponent as LogoNord} from '_assets/aldi-essentials-867/assets/brands/aldi/images/logo.svg';
import {ReactComponent as LogoSued} from '_assets/aldi-essentials-867/assets/brands/aldi/images/aldi-logo-sued.svg';
import SpriteIcon from '_assets/aldi-essentials-867/assets/brands/aldi/images/sprite.svg';
import React, {useState, useEffect, useId} from 'react';
import {MessageKey, useI18n} from '_shared/hooks/I18n';
import { gigyaWithPromise, useGigya } from "GigyaContext";
import {useAccount} from 'Account';
import "./Header.css";
import { NavLink, useLocation } from 'react-router-dom';
import {useCmsData} from "CmsContext";

function Header({
    menu = false,
}) {

    const {cmsData} = useCmsData();
    const [menuVisible, setMenuVisible] = useState(false);
    const { getMessage } = useI18n();
    const {account} = useAccount();
    const { gigya } = useGigya();
    const menuId = useId();

    useEffect(() => {
        if(menuVisible){
            document.documentElement.classList.add('menu-is-open');
        } else {
            document.documentElement.classList.remove('menu-is-open');
        }
    },[menuVisible]);

    let location = useLocation();
    useEffect(() => {
        setMenuVisible(false);
      }, [location]);

      if (!cmsData || !gigya) {
        return <></>;
    }


    const performLogout = () => {
        gigyaWithPromise(gigya.accounts.logout, {}).then(r => console.log("Logout performed"));
    };

    return (<header className="mod mod-header header-account" role="banner">
        <div className='mod-header__wrapper'>
            <div className='container'>
                <div className='mod-header__inlay'>
                    <div className='mod-header__logo'>
                        <LogoNord aria-hidden={true} className="logo-nord" />
                        <LogoSued aria-hidden={true} className="logo-sued" />
                    </div>
                    {
                        menu && (<div className='mod-header__nav'>
                            <nav id="main-navigation" className='mod mod-main-navigation'>
                                <button aria-expanded={menuVisible} aria-controls={menuId} onClick={() => setMenuVisible(!menuVisible)}>
                                    <span className="icon-open">
                                        <svg className="icon icon--sm icon--close" aria-hidden="true">
                                            <use href={
                                                SpriteIcon + "#close"
                                            }></use>
                                        </svg></span>
                                    <span className="icon-closed">
                                        <svg className="icon icon--account" aria-hidden="true">
                                            <use href={
                                                SpriteIcon + "#account"
                                            }></use>
                                        </svg>
                                    </span>
                                    <span className='text-title'>
                                        {account?.profile?.firstName && (`${account.profile.firstName}${account.profile.lastName ? " " + account.profile.lastName : ""}`)}</span>
                                </button>
                                <div className='mod-main-navigation__panels'
                                    aria-hidden={!menuVisible}
                                    id={menuId}
                                    style={
                                        { "--navigation-pannels-top": "60px" } as React.CSSProperties
                                    }
                                >
                                    <div className='mod-main-navigation__panel is-open'>
                                        <div className='mod-main-navigation__menu mod-main-navigation__menu--level-1 is-active'>
                                            <ul className='mod-main-navigation__list'>
                                                {
                                                    cmsData.components.accountPage.accountLinks.map((item,idx) => {
                                                        return item && item.id && item.url && item.label && (
                                                            <li key={"item" + idx} className='mod-main-navigation__item'>
                                                                <NavLink className={({ isActive }) =>"mod-main-navigation__action" + (isActive ? " is-selected" : "")} to={item.url}>{getMessage("ap.navigation." + item.id as MessageKey, item.label)}</NavLink>
                                                            </li>
                                                        )
                                                    })
                                                } 
                                                <li className='mod-main-navigation__item'>
                                                    <button className="mod-main-navigation__action btn btn--secondary" onClick={performLogout}>{getMessage("ap.navigation.logout")}</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='mod-main-navigation__backdrop' onClick={() => setMenuVisible(false)}></div>
                            </nav>
                        </div>)
                    }
                </div>
            </div>
        </div>
    </header>

    )
}

export default Header;
