import {useCmsData} from "CmsContext";
import { Footer } from "../marketing-preferences-page/components/Footer";
import { Header } from "../marketing-preferences-page/components/Header";
import {UserCentricsComponent} from "../_shared/components/UserCentricsComponent";
import {AdobeTagManagerComponent} from "../_shared/components/AdobeTagManagerComponent";
import {Meta} from "../_shared/components/Meta";
import {useI18n} from "../_shared/hooks/I18n";

export const IndexPage = () => {
    const { getMessage } = useI18n();
    const {cmsData} = useCmsData();

    if (!cmsData) {
        return <></>;
    }

    return (
        <div className="preference-center">
            <Meta titleProperty="clp.page.title" />
            <UserCentricsComponent pageType={"Error Page"} pageName={getMessage("tracking.clp.not-available")} primaryCategory={getMessage("tracking.clp.not-available")} />
            <AdobeTagManagerComponent />
            <Header header={cmsData.components.header} />
            <div className="page__main" role="main">
                <div className="page__content">
                    <div className="container">
                        <div className="mod mod-headline">
                            <h1>Willkommen zu Ihrem ALDI Account!</h1>
                        </div>
                        <div className="mod mod-copy">
                            <div className="rte">
                                <p>Diese Seite steht erst zu einem späteren Zeitpunkt zur Verfügung.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer footer={cmsData.components.footer} socialMediaBar={cmsData.components.socialmediabar}></Footer>
        </div>

    );
}