import {createContext, ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {FatalErrorPage} from "./central-page/FatalErrorPage";

const UI_ENDPOINT = process.env.REACT_APP_UI_ENDPOINT;

export namespace Meta {
    export interface MetaDataOwnId {
        application: string,
        environment: string
    }

    interface MetaDataSso {
        apiKey: string,
        loginSiteName: string,
        customLogin: boolean
    }

    interface MetaDataCms {
        id: string
    }

    interface MetaDataUserCentrics {
        settingsId: string
    }

    interface MetaDataAdobeTagManager {
        launchUrl: string
    }

    export interface MetaData {
        id: string,
        currency: string,
        environmentType: string,
        defaultLocale: string,
        locales: string[],
        ownId?: MetaDataOwnId,
        restApi: string,
        sso: MetaDataSso,
        cms: MetaDataCms,
        marketingPreferenceCenter: { apiKey: string, forceConsent: boolean },
        aldiGermany: boolean,
        validation?: Validation,
        userCentrics?: MetaDataUserCentrics,
        adobeTagManager?: MetaDataAdobeTagManager
    }


    interface Validation {
        zipCode?: ZipCodeValidation
    }

    interface ZipCodeValidation {
        [countryIsoCode: string]: RegexValidation
    }

    interface RegexValidation {
        regex: string,
        errorMessage: string
    }
}

interface MetaDataData {
    metaData?: Meta.MetaData
}

async function fetchMetaData() : Promise<Meta.MetaData> {
    if (process.env.REACT_APP_METADATA) {
        return JSON.parse(process.env.REACT_APP_METADATA);
    }

    const response = await fetch(UI_ENDPOINT+ "/rest/v1/metadata");
    return await response.json();
}

const metaDataContext = createContext<MetaDataData>({
    metaData: undefined
});

export const MetaDataProvider = ({children}: {children: ReactNode}) => {
    const [metaData, setMetaData] = useState<Meta.MetaData>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        fetchMetaData().then(setMetaData).catch(() => setError("metadata fetch failed."));
    },[]);

    const metaDataData = useMemo(() => {
        return {
            metaData: metaData
        }
    }, [metaData]);

    const { Provider } = metaDataContext;

    if (error) {
        return <FatalErrorPage message={error} />
    }
    return(
        <Provider value={metaDataData}>
            {children}
        </Provider>
    )
}

export const useMetaData = () => useContext(metaDataContext);