import {MouseEventHandler} from "react";
import "./Optin_ConfirmOverlay.css";

export interface ConfirmOverlayProps {
    headline: string,
    description: string,
    confirmText: string,
    denyText?: string,
    confirmHandler: MouseEventHandler<HTMLButtonElement>,
    denyHandler?: MouseEventHandler<HTMLButtonElement> 
}

export const ConfirmOverlay = ({headline, description, confirmText, denyText, confirmHandler, denyHandler}: ConfirmOverlayProps) => {
    return (
        <div className="mod-overlayConfirm">
            <div className="mod-overlayConfirm__content">
                <h2>{headline}</h2>
                <p>{description}</p>
                <div className="row">
                    <button onClick={confirmHandler} className="btn btn--primary">{confirmText}</button>
                    {denyText && <button onClick={denyHandler} className="btn btn--secondary">{denyText}</button>}
                </div>
            </div>
        </div>
    );
}