
import {MessageKey, useI18n} from "_shared/hooks/I18n";
import {useCmsData} from "CmsContext";

export const SimpleFooter = () => {
    const { getMessage } = useI18n();
    const { cmsData } = useCmsData();

    if (!cmsData?.components?.simpleFooter?.footerLinks) {
        return <></>;
    }

    return (
        <footer className="mod mod-footer" role="contentinfo">
            <div className="container">
                <ul className="mod-footer__list">
                    <li>
                        <div className="mod-footer__area">
                           <ul>
                               {
                                   cmsData.components.simpleFooter.footerLinks.map((item) => {
                                       return (
                                           <li key={item.id}>
                                               <a href={item.url} id={"simplefooter_" + item.id}
                                                  title={getMessage("global.footer." + item.id + ".text" as MessageKey, item.label)}>{getMessage("global.footer." + item.id + ".text" as MessageKey, item.label)}</a>
                                            </li>
                                       )
                                   })
                               }
                           </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </footer>
    )
}
