import Header from './shared-components/Header';
import {AuthenticatedAccessOnlyPage} from 'central-page/AuthenticatedAccessOnlyPage';
import {Meta} from "../../_shared/components/Meta";
import {SimpleFooter} from "../../_shared/components/SimpleFooter";
import {ReactNode} from "react";
import "./AccountLayout.css";

export const AccountLayout = ({children}: {children: ReactNode}) => {
    return (
        <AuthenticatedAccessOnlyPage>
            <Meta titleProperty="account.page.title" />
            <Header menu={true}/>
            <div className="page__main account-page" role="main">
                <div className="page__content">
                    <div className="container">
                        {children}
                    </div>
                </div>
            </div>
            <SimpleFooter/>
        </AuthenticatedAccessOnlyPage>
    );
}
