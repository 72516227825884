import { gigyaWithPromise, useGigya } from "GigyaContext";
import { SsoClientsOverview } from "_shared/components/SsoClientsOverview";
import {MessageKey, useI18n} from "_shared/hooks/I18n";
import {useAccount} from 'Account';
import {Cms, useCmsData} from "CmsContext";
import './AccountHomePage.css';
import { NavLink } from "react-router-dom";

export const AccountHomePage = () => {

    const {cmsData} = useCmsData();
    const {getMessage} = useI18n();
    const {account} = useAccount();
    const { gigya } = useGigya();

    if (!cmsData || !gigya || !account) {
        return <></>;
    }

    const performLogout = () => {
        gigyaWithPromise(gigya.accounts.logout, {}).then(r => console.log("Logout performed"));
    };

    return (
        <div>
            {
                account.profile?.firstName &&
                <h2 className="heading-welcome">{`${getMessage("ap.welcome")} ${account.profile.firstName}${account.profile.lastName ? " " + account.profile.lastName : ""}!`}</h2>
            }
            <h3>{getMessage("ap.services.heading")}</h3>
            <SsoClientsOverview />
            <h3>{getMessage("ap.account.heading")}</h3>
            <div className="mod mod-tiles mod-tiles--accountlinks">
                <div className="tiles-grid tiles-grid--4">
                    {
                        cmsData.components.accountPage.accountLinks.map((item,_idx) => <AccountNavLink key={item.id} link={item} />)
                    }
                </div>
            </div>
            <button type="button" className="btn btn--primary btn-full" onClick={performLogout}>{getMessage("global.logout")}</button>
        </div>
    );
}

const AccountNavLink = ({link}: {link: Cms.TextLinkWithDescription}) => {
    const {getMessage} = useI18n();

    return (
        <NavLink className="mod mod-content-tile-enhanced mod-content-tile-enhanced--secondary mod-content-tile-enhanced--hover" to={link.url}>
            <div className="mod-content-tile-enhanced__content">
                <h4 className="mod-content-tile-enhanced__title">{getMessage("ap.account." + link.id + ".title" as MessageKey, link.label)}</h4>
                <p>{getMessage("ap.account." + link.id + ".description" as MessageKey, link.description)}</p>
            </div>
        </NavLink>
    )
}