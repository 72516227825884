import {Helmet} from "react-helmet-async";
import {MessageKey, useI18n} from "../hooks/I18n";

export const Meta = ({titleProperty}: {titleProperty: MessageKey}) => {
    const { currentLanguage, getMessage } = useI18n();

    return (
        <Helmet title={getMessage(titleProperty, "ALDI")} htmlAttributes={{ lang : currentLanguage }}/>
    )
}
