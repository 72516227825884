import "./SelectList.css";

export type SelectListOption = {
    value: string;
    label: string;
}

export type SelectListCallbackParameters = {
    selectedOption: SelectListOption
}

export const SelectList = ({options, callback} : {options: Array<SelectListOption>, callback: ({selectedOption}: SelectListCallbackParameters) => void}) => {

    return (
        <div className="select-box">
            <ul>
                {options.map((option) => { return (
                    <li key={option.value} data-value={option.value} onMouseDown={() => callback({selectedOption: option})}>
                        {option.label}
                    </li>
                )})}
            </ul>
        </div>
    )
}
