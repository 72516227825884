import {FormInput} from "./FormInput";
import {UseAccountFormRegisterFormInputReturn} from "../../hooks/GigyaSchema";

export const PhoneNumberField = ({registerInput, typeRegisterInput, defaultRegisterInput} : {registerInput:UseAccountFormRegisterFormInputReturn, typeRegisterInput:UseAccountFormRegisterFormInputReturn, defaultRegisterInput:UseAccountFormRegisterFormInputReturn}) => {

    return <>
        <FormInput {...registerInput} />
        <FormInput {...typeRegisterInput} type="hidden" />
        <FormInput {...defaultRegisterInput} type="hidden" />
    </>
}