import {Outlet} from 'react-router-dom';
import {GigyaProvider, useGigya} from '../GigyaContext';
import LoadingScreen from './LoadingScreen';
import './MainLayout.css'
import {AccountProvider} from 'Account';
import {IndexPage} from 'central-page/IndexPage';
import {useMetaData} from "../MetaDataContext";

export const MainLayout = () => {
    const { metaData } = useMetaData();

    if (metaData && !metaData.sso) {
        return <IndexPage />
    }

    return (
        <GigyaProvider type="CLP">
            <AccountProvider>
                <Content />
          </AccountProvider>
        </GigyaProvider>
    );
}

const Content = () => {
    const { isGigyaReady } = useGigya();

    return (
        <>
            {
                isGigyaReady() && <Outlet/>
            }
            {
                !isGigyaReady() && <LoadingScreen/>
            }
        </>
    );
}
