import {UseAccountFormRegisterFormInputReturn} from "../../hooks/GigyaSchema";
import {useI18n} from "../hooks/I18n";
import React, {useId} from "react";
import {ErrorField} from "./ErrorField";
import {Cms} from "../../CmsContext";

export const SalutationComponent= ({registerInput, label, cmsInputField} : {registerInput:UseAccountFormRegisterFormInputReturn, label?:string, cmsInputField?:Cms.ProfileFieldSalutation}) => {
    const { getMessage } = useI18n();

    const salutationInputId = useId();
    const salutationErrorFieldId = useId();
    const salutationDescriptionId = useId();

    let {useFormRegisterReturn:sal_rr, errorDetails:sal_ed, ...sal_props} = registerInput;

    const options: React.ReactNode[] = [];

    const emptyValue: string = (cmsInputField)?cmsInputField.empty:getMessage("account.data.salutation.empty");
    const labelValue: string = (label)?label:getMessage("account.data.salutation");

    if (cmsInputField) {
        cmsInputField.options.map((value, index) => {
            options.push(<option key={value.id} value={value.id}>{value.label}</option>);
            return index;
        });
    } else {
        options.push(<option key="mr" value="mr">{getMessage("account.data.salutation.mr")}</option>)
        options.push(<option key="mrs" value="mrs">{getMessage("account.data.salutation.mrs")}</option>)
        options.push(<option key="div" value="div">{getMessage("account.data.salutation.div")}</option>)
    }

    return (
        <label className={`textfield textfield--select ${sal_ed ? "has-error" : ""}`}>
            <select id={salutationInputId} className="textfield__control" {...sal_props} {...sal_rr}
                    aria-invalid={sal_ed ? true : undefined}
                    aria-errormessage={sal_ed ? String(salutationErrorFieldId) : undefined}
                    aria-describedby={salutationDescriptionId}
            >
                {
                    sal_rr.required || <option value="">{emptyValue}</option>
                }
                {options}
            </select>
            <span id={salutationDescriptionId} className="textfield__label">{labelValue}</span>
            <ErrorField id={salutationErrorFieldId} field={sal_ed} />
        </label>
    )
}