import {isRouteErrorResponse, useParams, useRouteError} from "react-router-dom";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { useCmsData } from "CmsContext";
import {MessageKey, useI18n} from "../_shared/hooks/I18n";
import {UserCentricsComponent} from "../_shared/components/UserCentricsComponent";
import {AdobeTagManagerComponent} from "../_shared/components/AdobeTagManagerComponent";
import {Meta} from "../_shared/components/Meta";
import { CDNResponsiveImage } from "_shared/components/CDNResponsiveImage";

/**
 * Error page for CPC.
 *
 * You can call via headline and description (both optional, headline has a general fallback).
 * You can call via errorCode (headline and description will be read from properties with the errorCode as part of the property key)
 * You can call via url (url parameter "errorCodeParameter" is same like errorCode)
 * You can set in router for route error (error is read from "useRouteError()")
 *
 * @param headline
 * @param description
 * @param errorCode
 * @constructor
 */
export const ErrorPagePreferences = ({ headline, description, errorCode }: { headline?: string, description?: string, errorCode?:string } ) => {
    const {cmsData} = useCmsData();
    const { errorCodeParameter } = useParams();
    const { getMessage } = useI18n();

    const errorCodeResult = errorCode || errorCodeParameter;
    
    const routeError = useRouteError();
    routeError && console.error("Error shown on error page ", routeError);

    if (!description && isRouteErrorResponse(routeError)) {
        description = routeError.statusText;
    }
    if (!description && errorCodeResult) {
        description = getMessage("global.errors." + errorCodeResult + ".description" as MessageKey, "Fehlercode: " + errorCodeResult);
    }
    if (errorCodeResult) {
        headline = getMessage("global.errors." + errorCodeResult + ".headline" as MessageKey, getMessage("global.errors.general.headline", "Es ist ein unerwarteter Fehler aufgetreten."));
    }
    // fallback
    if (!headline) {
        headline = getMessage("global.errors.general.headline");
    }

    // cmsData and metaData can be undefined!!!

    return (
        <div className="preference-center">
            <Meta titleProperty="error.page.title" />
            <UserCentricsComponent pageType={"Error Page"} pageName={headline} primaryCategory={"errorpages"} subCategory1={headline} />
            <AdobeTagManagerComponent />
            {cmsData && <Header header={cmsData.components.header} />}
            <div className="page__main" role="main">
                <div className="page__content">
                    { errorCodeResult === "doi-expired" && cmsData && cmsData.components.preferenceCenter.doubleoptinimage &&
                        <div className="container-full">
                            <div className="mod mod-overview-intro-2">
                                <div className="mod-overview-intro-2__media">
                                    <CDNResponsiveImage
                                        className="img-responsive img-cover cq-dd-image"
                                        title={cmsData.components.preferenceCenter.doubleoptinimage.alt}
                                        alt={cmsData.components.preferenceCenter.doubleoptinimage.alt}
                                        sizes="(min-width: 1817px) 1817px, 100vw"
                                        src={cmsData.components.preferenceCenter.doubleoptinimage.src}
                                        widths={[768, 1024, 1817]} // no other sizes in germany: could be added "3000" for retina displays
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="container">
                        <div className="mod mod-headline">
                            <h1 data-binding="true">{headline}</h1>
                        </div>
                        <div className="mod mod-copy">
                            <div className="rte">
                                <p dangerouslySetInnerHTML={{__html: description || ""}}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {cmsData && <Footer footer={cmsData.components.footer} socialMediaBar={cmsData.components.socialmediabar}></Footer>}
        </div>
    );
} 