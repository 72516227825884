import {AccountFormItemDetail, RegisterFormInputFunction} from "../../hooks/GigyaSchema";
import {useCallback, useId, useMemo, useState} from "react";
import {useAccount} from "../../Account";
import {useGigya} from "../../GigyaContext";
import {UseFormReturn} from "react-hook-form";
import {ErrorField} from "../../_shared/components/ErrorField";
import {useI18n} from "../../_shared/hooks/I18n";
import {useMetaData} from "../../MetaDataContext";
import {SelectList, SelectListCallbackParameters, SelectListOption} from "_shared/components/SelectList";
import {Cms} from "../../CmsContext";

export type FavoriteStoreComponentProp = {
    registerFormInput: RegisterFormInputFunction<any>,
    formItemDetails: Map<string, AccountFormItemDetail<any>>,
    useFormReturn: UseFormReturn<any>,
    cmsInputField: Cms.ProfileFieldFavoriteStore
}

interface FavoriteStoreEntry {
    id: string,
    uiText: string
}

interface FavoriteStoreResponse {
    status: string,
    retailStores: FavoriteStoreEntry[]
}

export const FavoriteStoreComponent = ({registerFormInput, formItemDetails, useFormReturn, cmsInputField} : FavoriteStoreComponentProp) => {

    const {setValue, setError, clearErrors } = useFormReturn;

    const [retailStores, setRetailStores] = useState<SelectListOption[]>([]);
    const [selectedStoreName, setSelectedStoreName] = useState<string|undefined>(undefined);
    const [inputValue, setInputValue] = useState<string>("");
    const [showStoreList, setShowStoreList] = useState<Boolean>(false);
    const [ favoriteId, setFavoriteId] = useState<string>("");
    const errorFieldId = useId();

    const {metaData} = useMetaData();
    const {account} = useAccount();
    const {isGigyaReady} = useGigya();
    const {getMessage} = useI18n();

    let fs_fid= formItemDetails.get(cmsInputField.cdcProperty)!;
    let {useFormRegisterReturn:fs_rr, errorDetails:fs_ed, ...fs_props} = {...registerFormInput(fs_fid!)}
    let {onChange:fs_onChange, ...fs_rr_x} = {...fs_rr}

    const setSelection = useCallback((id: string, label: string) => {
        setInputValue(label);
        setSelectedStoreName(label);
        setFavoriteId(id);
        setValue(fs_props.name, id);
    }, [setInputValue, setSelectedStoreName, setFavoriteId, setValue, fs_props.name]);

    const callApi = useCallback(async (currentStoreId:string) : Promise<FavoriteStoreEntry[]> => {
        if (!metaData || !currentStoreId) {
            return [];
        }
        return fetch(metaData.restApi + "/rest/v1/retailstores/search?" + new URLSearchParams({
            "q": currentStoreId,
        }), {
            method: 'GET',
            headers:{},
        })
        .then((rawResponse) => rawResponse.json())
        .then(json => json as FavoriteStoreResponse)
        .then(response => response.retailStores);
    }, [metaData]);

    useMemo(() => {
        const setCurrentStoreId = async (currentStoreId: string) => {
            const response = await callApi(currentStoreId);
            if (response.length === 1) {
                let rs = response[0];
                setSelection(rs.id, rs.uiText);
            }
        }

        if (!isGigyaReady() || !metaData || !account) {
            return;
        }

        // initial
        if (selectedStoreName === undefined) {
            const currentStoreId = fs_fid.value;
            if (currentStoreId !== undefined) {
                setCurrentStoreId(currentStoreId).catch(console.error)
            }
        }
    },[account, metaData, isGigyaReady, selectedStoreName, callApi, setSelection, fs_fid.value]);

    const onChange = async (event:any) => {
        let value =  event.target.value;

        setSelection(value, value);

        if(!value || value === "") {
            setShowStoreList(false);
            setRetailStores([]);
            return;
        }

        if (!metaData) {
            return;
        }

        // print list
        const response = await callApi(value);
        const ns:SelectListOption[] = [];
        response.forEach((rs)=>{
            ns.push({value:rs.id, label: rs.uiText});
        });
        setRetailStores(ns);

        if (value && ns.length === 0) {
            // show error if value is set but an empty list (no search result is found)
            setError(fs_props.name, {type: "custom", message: getMessage("mpp.favorite-store.not-selected")});
        }else {
            setShowStoreList(true);
            clearErrors(fs_props.name);
        }
    };

    const onFocus = () => {
        if(retailStores.length > 0){
            setShowStoreList(true);
        }
    }

    const onSelect = function ({selectedOption} : SelectListCallbackParameters) {
        clearErrors(fs_props.name);
        setSelection(selectedOption.value, selectedOption.label);
        setShowStoreList(false);
        setRetailStores([]);
    }

    return(
        <fieldset
            onBlur={()=>setShowStoreList(false)}
            onFocus={()=>onFocus()}
        >
            <label className={`textfield ${fs_ed ? "has-error" : ""}`}>
                <input type="text"
                    className="textfield__control"
                    placeholder={cmsInputField.empty}
                    onChange={onChange}
                    aria-errormessage={fs_ed ? errorFieldId : undefined}
                    aria-invalid={fs_ed ? true : undefined}
                    value={inputValue}
                />
                <span className="textfield__label">{cmsInputField.label}</span>
                {(showStoreList && retailStores.length >= 1) && <SelectList options={retailStores} callback={onSelect} />}
                <input type="hidden"
                    data-attr-value={cmsInputField.label}
                    {...fs_rr_x}
                    {...fs_props}
                    value={favoriteId}
                />
                <ErrorField id={errorFieldId} field={fs_ed} />
            </label>
        </fieldset>
    );
}