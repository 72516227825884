import { ConsentLink } from "_shared/components/ConsentLink";
import { ErrorField } from "_shared/components/ErrorField";
import {MessageKey, useI18n} from "_shared/hooks/I18n";
import { queryObjectProperty } from "_shared/Utils";
import {UseFormRegister} from "react-hook-form/dist/types/form";
import {FieldErrors, FieldPath, FieldValues} from "react-hook-form";

export interface Consentable {
    preferences: any
}

export const ConsentCheckBox = <TFieldValues extends FieldValues&Consentable> ( { formFieldName, errors, register}: { formFieldName: FieldPath<TFieldValues>, errors: FieldErrors, register: UseFormRegister<TFieldValues>} ) => {

    const { getMessage } = useI18n();
    const consentId = extractConsentId(formFieldName);

    return (
        <fieldset>
            <label className="checkbox">
                <input type="checkbox"
                    className={`checkbox__control ${errors[formFieldName] ? "error" : ""}`}
                    {
                        ...register(formFieldName, { required: {
                            value: true, message: getMessage("clp.registration.iconsent.missing")}
                        })
                    }
                />
                <span className="checkbox__label">{getMessage("clp.registration.iconsent.start")}
                    <ConsentLink consentKey={consentId} linkText={getMessage("clp.registration.iconsent." + consentId as MessageKey)} />{getMessage("clp.registration.iconsent.end")}
                </span>
                <ErrorField field={queryObjectProperty(errors, formFieldName)} />
            </label>
        </fieldset>
    );
}

const extractConsentId = (formFieldName: string): string => {
    let p = formFieldName.substring("preferences.".length)
    p = p.substring(0, p.length - ".isConsentGranted".length);
    return p;
}