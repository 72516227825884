import { NavLink } from 'react-router-dom';
import "./Navigation.css"

export interface NavigationEntry {
    label: string,
    route: string
}

export const Navigation = ({ entries }: {entries: NavigationEntry[]}) => {
    return (
        <div className="mod mod-tabs navigation">
            <div className="mod-tabs__nav">
                <div className="scroll-slider">
                    <div className="scroll-slider__wrapper scroll-tabs">
                        <ul className="tabs tabs--block scroll-slider__items">
                        {
                            entries.map((entry, index) =>
                                <NavigationBarItem key={index} route={entry.route} label={entry.label} />
                            )
                        }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const NavigationBarItem = ({ route, label }: {route: string, label: string}) => {
    return (
        <li className='tabs__item'>
            <NavLink 
                className={({ isActive }) =>
                    "tabs__action" + (isActive ? " is-selected" : "")
                } 
                to={route}>{label}
            </NavLink>
        </li>
    );
}