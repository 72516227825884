import { useAccount } from 'Account';
import { Navigate, useLocation } from 'react-router-dom';
import {ReactNode} from "react";

export const AuthenticatedAccessOnlyPage = ({ children }: { children:ReactNode }) => {
    const { account } = useAccount();

    const location = useLocation();

    if (!account.loggedIn) {
        return <Navigate to="/login" 
            state={{
                from: location
            }}
            replace={true} />;
    }
    
    return (<>
            {children}
        </>)
}