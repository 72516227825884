import "./Snackbar.css";
import SpriteSvg from '_assets/aldi-essentials-867/assets/brands/aldi/images/sprite.svg';
import {MouseEventHandler} from "react";


export const Snackbar = ({headline, description, isActive, hideHandler}: {headline:string, description:string, isActive:boolean, hideHandler: MouseEventHandler<any>}) => {

    return (
        <div className={`mod mod-snackbar mod-snackbar--fixed mod-snackbar--fade-in mod-snackbar--success ${isActive ? "show": ""}`}>
            <svg className="icon icon--verified icon--baseline mod-snackbar__icon">
                <use href={`${SpriteSvg}#verified`}></use>
            </svg>
            <strong>{headline}</strong>
            {description}
            <button onClick={hideHandler} type="button" className="ic-btn ic-btn--primary mod-snackbar__close" title="Title">
                <svg className="icon icon--close">
                    <use href={`${SpriteSvg}#close`}></use>
                </svg>
            </button>
        </div>
    );
}