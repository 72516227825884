import React, { ChangeEvent } from "react";
import {UseFormSetValue} from "react-hook-form/dist/types/form";
import {ErrorField} from "../../_shared/components/ErrorField";
import { SwitcherComponent } from "./SwitcherComponent";

/**
 * Switch component to render a switch with a headline and an optional description text.
 * @deprecated Use SwitcherComponent
 *
 * @param icon an icon
 * @param headline a headline
 * @param descriptions array of descriptions, if more than one render as list, can be undefined
 * @param errorDetails error details
 * @param defaultChecked the initial value
 * @param setValue set value function from forms
 * @param inputProps collection of further stuff for checkbox
 */
export const FormBoundSwitcherComponent = ( {icon, headline, descriptions, errorDetails, defaultChecked, setValue, ...inputProps} :{setValue: UseFormSetValue<any>, errorDetails: any, icon:string, headline:string, descriptions?:string[], defaultChecked: boolean} ) => {

    const inputs = [<input key={1} type="checkbox" className="hidden" defaultChecked={defaultChecked} {...inputProps}/>];
    const errorDetailList = [errorDetails];

    return (
        <FormBoundSwitcherMultiComponent icon={icon} headline={headline} setValue={setValue} inputs={inputs} defaultChecked={defaultChecked} descriptions={descriptions} errorDetails={errorDetailList} />
    );
}

/**
 * Switch component to render a switch with a headline and an optional description text.
 * If the switcher is changed you can set one or more (hidden) checkbox input elements. So you can set/unset more than one value.
 *
 * @deprecated Use SwitcherComponent
 * 
 * @param errorDetails error details
 * @param icon an icon
 * @param headline headline
 * @param descriptions array of descriptions, if more than one render as list, can be undefined
 * @param inputs hidden input elements to set (maybe change it)
 * @param setValue set value function from forms
 * @param defaultChecked the default value
 */
export const FormBoundSwitcherMultiComponent = ( {errorDetails, icon, headline, descriptions, inputs, setValue, defaultChecked }:{errorDetails: any[], icon:string, headline:string, descriptions?:string[], defaultChecked?:boolean, setValue: UseFormSetValue<any>, inputs:React.JSX.Element[]} ) => {

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        inputs.forEach((input)=>{
            setValue(input.props.name, event.target.checked);
        })
    }

    return <>
        <SwitcherComponent headline={headline} icon={icon} defaultChecked={defaultChecked} descriptions={descriptions} onChange={onChange}>
            {errorDetails.map((err, index) => (<ErrorField key={index} field={err} />))}
        </SwitcherComponent>
        {inputs}
    </>
}

