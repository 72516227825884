import {AccountSchema, GigyaWebSdk, useGigya} from "GigyaContext";
import {useI18n} from "_shared/hooks/I18n";
import {FieldOverwritingData, useAccountForm} from "hooks/GigyaSchema";
import {BooleanTilesComponent} from "./BooleanTilesComponent";
import {RadioTilesComponent} from "./RadioTilesComponent";
import {ProfileComponent} from "./ProfileComponent";
import React, {ForwardedRef, forwardRef, useImperativeHandle, useRef} from "react";
import {Separator} from "./Separator";
import {Cms} from "../../CmsContext";
import {FieldValues, SubmitHandler, UseFormClearErrors, UseFormSetError} from 'react-hook-form';
import { ProductReminderComponent } from "./productreminder";
import { CmsTabStatus } from "./MarketingPreferencesTabStatus";
import { useNewsletter } from "./newslettercomponent";

export type PreferenceTabProp<TFieldValues extends FieldValues> = {
    tabStatus: CmsTabStatus,
    accountSchema: AccountSchema | undefined,
    globalErrorMessage: string,
    saveForm: SubmitHandler<FieldValues>,
    dataToOverwrite: FieldOverwritingData<TFieldValues>
}

export type PreferenceTabHandle<TFieldValues extends FieldValues> = {
    validateAll: () => Promise<void>,
    getData: () => FieldOverwritingData<TFieldValues>,
    setError: UseFormSetError<FieldValues>,
    clearErrors: UseFormClearErrors<FieldValues>
};

type PreferenceTabFormFieldValues = GigyaWebSdk.ApiFunctionArgsSetAccountInfo;

type BeforeSaveHandler<T extends FieldValues> = (data: T) => Promise<T>;

export const PreferenceTab = forwardRef(<TFieldValues extends PreferenceTabFormFieldValues>({tabStatus, accountSchema, globalErrorMessage, saveForm, dataToOverwrite} : PreferenceTabProp<TFieldValues>, ref: ForwardedRef<PreferenceTabHandle<TFieldValues>>) => {
    const {getMessage} = useI18n();
    const {gigya} = useGigya();
    const fields = getSchemaPaths(tabStatus.tab, accountSchema);

    const useAccountFormReturn = useAccountForm(fields, dataToOverwrite);
    const {formItemDetails, registerFormInput, handleSubmit, setValue, trigger, setError, clearErrors } = useAccountFormReturn;

    const formRef = useRef<HTMLFormElement>(null);

    const { createNewsletterComponent, createInitialNewsletterOptInCheck } = useNewsletter(setValue);

    // The problem here is that this should be in a separate component (hook useAccountForm).
    // But on the other hand, the data and validation should be handled separately in the overlying component.
    useImperativeHandle<PreferenceTabHandle<TFieldValues>, PreferenceTabHandle<TFieldValues>>(ref, () => {
        if (!tabStatus.enabled) {
            return {
                setError: setError,
                clearErrors: clearErrors,
                validateAll: () => Promise.resolve(),
                getData: () => new Map()
            }
        }

        return {
            setError: setError,
            clearErrors: clearErrors,
            validateAll: () => {
                return new Promise(function(resolve, reject) {
                    const allValidPromises: Promise<boolean>[] = Object.keys(formRef.current!.elements).map((_key, index) => {
                        const element = formRef!.current!.elements[index] as HTMLInputElement;
                        if (element && element.name && element.type !== "submit") {
                            return trigger(element.name, { shouldFocus: true }); // returns Promise<boolean>
                        } else {
                            return Promise.resolve(true);
                        }
                    });
                    Promise.all(allValidPromises)
                        .then(booleans => booleans.every(x => x) ? resolve() : reject())
                        .catch(_ => reject());
                });
            },
            getData: () => {
                const d = new Map();
                Object.keys(formRef.current!.elements).forEach((_key, index) => {
                    const element = formRef.current!.elements[index] as HTMLInputElement;
                    if (element && element.name && element.type !== "submit") {
                        d.set(element.name, element.type === "checkbox" ? element.checked : element.value);
                    }
                });
                return d;
            }
        }
    }, [formRef, tabStatus, trigger, clearErrors, setError]);

    if (!gigya) {
        return <></>;
    }

    const components: React.ReactNode[] = [];
    tabStatus.tab.components.map((component, index) => {
        switch (component.type) {
            case "booleantiles":
                components.push(<BooleanTilesComponent key={index} component={component as Cms.Tiles} registerFormInput={registerFormInput} formItemDetails={formItemDetails} setValue={setValue}/>);
                break;
            case "radiotiles":
                components.push(<RadioTilesComponent key={index} component={component as Cms.RadioTiles} registerFormInput={registerFormInput} formItemDetails={formItemDetails} setValue={setValue}/>);
                break;
            case "productreminders":
                components.push(<ProductReminderComponent key={index} />);
                break;
            case "profile":
                components.push(<ProfileComponent key={index} component={component as Cms.ProfileTiles} registerFormInput={registerFormInput} formItemDetails={formItemDetails} useFormReturn={useAccountFormReturn} />);
                break;
            case "subscriptions":
                components.push(createNewsletterComponent({
                    key: index,
                    component: component as Cms.SubscriptionTiles,
                    accountSchema: accountSchema,
                    registerFormInput: registerFormInput,
                    formItemDetails: formItemDetails,
                    setValue: setValue
                }));
                break;
            case "separator":
                components.push(<Separator key={index} />);
                break;
            default:
                // unknown;
                break;
        }
        return component;
    })

    function getSchemaPaths(tab:Cms.Tab, accountSchema?:AccountSchema) {
        let paths: string[] = [];
        tab.components.forEach((component)=>{
            switch (component.type) {
                case "booleantiles":
                    if ((component as Cms.Tiles).tiles) {
                        (component as Cms.Tiles).tiles.forEach((tile) => {
                            tile.schemaPaths.forEach((p)=>{
                                paths.push(p);
                            })
                        });
                    }
                    break;
                case "radiotiles":
                    if ((component as Cms.RadioTiles).tiles) {
                        (component as Cms.RadioTiles).tiles.forEach((tile) => {
                            tile.schemaPaths.forEach((p)=>{
                                paths.push(p);
                            })
                        });
                    }
                    break;
                case "productreminders":
                    // nothing
                    break;
                case "profile":
                    paths.push("profile.email");
                    paths.push("profile.birthDay");
                    paths.push("profile.birthMonth");
                    paths.push("profile.birthYear");
                    paths.push("profile.firstName");
                    paths.push("profile.lastName");
                    paths.push("profile.zip");
                    paths.push("profile.country");
                    paths.push("data.salutation");
                    paths.push("data.preference.FAVORITE_STORE");
                    paths.push("data.preference.SHOPPING_FREQUENCY");
                    break;
                case "subscriptions":
                    for (const key in accountSchema?.subscriptionsSchema?.fields) {
                        paths.push("subscriptions." + key + ".email.isSubscribed");
                    }
                    break;
                case "separator":
                    // nothing
                    break;
                default:
                    // unknown;
                    console.error("Unknown component: " + component.type)
                    break;
            }
            return component;
        });
        return paths;
    }

    const beforeSaveHandlers = (tab: Cms.Tab) => {
        let handlers: BeforeSaveHandler<PreferenceTabFormFieldValues>[] = [];
        tab.components.forEach((component) => {
            switch (component.type) {
                case "subscriptions":
                    handlers.push(createInitialNewsletterOptInCheck());
                    break;
            }
        });
        return handlers;
    }

    const handleSaveForm = async (data: FieldValues) => {
        let newData = data;
        for (const handler of beforeSaveHandlers(tabStatus.tab)) {
            newData = await handler(newData);
        }
        saveForm(newData);
    }

    return (
        <>
            <div className={`mod mod-copy tab ${tabStatus.tab.anchor}`}>
                <div className="rte">
                    {tabStatus.tab.description && <p>{tabStatus.tab.description}</p>}
                    {tabStatus.enabled && <form onSubmit={handleSubmit(handleSaveForm)} ref={formRef} autoComplete="on" data-attr-name={`Profile - ${tabStatus.tab.label}`}>
                        {components.map((elem) => elem)}
                        {globalErrorMessage && <span className="textfield__error active">{globalErrorMessage}</span>}
                        <button type="submit" className="btn btn--primary btn-right">{getMessage("global.button.save")}</button>
                    </form>}
                </div>
            </div>
        </>);
})