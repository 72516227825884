import {GigyaWebSdk, gigyaWithPromise} from "../GigyaContext";

let ssoContinuing = false;

export const ensureSsoFlowResumed = async (gigya: GigyaWebSdk.Gigya | null): Promise<void> => {
    if (!gigya) {
        return;
    }

    const sessionExists = await gigya.hasSession();

    if (!sessionExists) {
        return;
    }

    try {
        await gigyaWithPromise(gigya.accounts.session.verify, {});
    }
    catch (errorResponse: unknown) {
        //happens when start sso flow twice, while you already have a session. After logout the session seems broken and another login fails.
        console.error("hasSession returned true but account info was invalid.");
        console.error(errorResponse);
        return;
    }

    continueSsoFlow(gigya);
}

/**
 * In case we known, that we've a session, we can continue sso in a synchronuous way:
 * https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/b46abc43d8b646ab8f3c5bde5f347402.html?locale=en-US&q=gigya.sso.continue
 * 
 * @param gigya 
 * @returns 
 */
export const continueSsoFlow = (gigya: GigyaWebSdk.Gigya | null) => {
    if (!gigya) {
        return false;
    }

    if (!ensureSsoTokenExistsAsQueryParameter()) {
        return false;
    }
    removeSsoTokenFromLocalStorage();
    gigya.sso.continue();
    ssoContinuing = true;

    //make sure that we reset ssoContinuing, just in case something goes wrong on the gigya site...
    setTimeout(() => {
        ssoContinuing = false;
    }, 10000);
    return true;
}

/**
 * 
 * @returns Checks whether gigya is currently redirecting the user to an sso client
 */
export const isSsoContinuing = () => {
    return ssoContinuing;
}

/**
 * Gigya sends multiple arguments within our url, when gigya.accounts.sso.login is called. See
 * https://team.aldi-nord.com/confluence/display/CDC/Schnittstellendokumentation+Central+Login+Page for more details.
 * 
 */
export const handleSsoQueryArguments = () => {
    const queryParams = getQueryParameters();
    
    maintainSsoTokenInLocalStorage(queryParams);

    //save regSource in session storage
    const regSource = queryParams.get("gig_regSource");
    if (regSource) {
        sessionStorage.setItem("regSource", regSource);
    } else {
        sessionStorage.removeItem("regSource");
    }

    //send the user directory to registration page, if "preselectRegistration" is set to 'true'
    if (queryParams.has("gig_preselectRegistration") && queryParams.get("gig_preselectRegistration")?.toLowerCase() === 'true') {
        const url = new URL(window.location.origin + "/registration");
        window.history.replaceState(window.history.state, "not used", url);
    }
}

/**
 * Defines if a SSO flow is active and the customer should be redirected to some
 * 3rd party client after login/registration (a SSO token has been found somewhere) or not.
 * Does not answer the question, if Gigya is currently performing the redirect.
 * @returns 
 */
export const isSsoFlowActive = (): boolean => {
    return getSsoTokenFromQueryParameter() != null || getSsoTokenFromLocalStorage() != null;
}

const maintainSsoTokenInLocalStorage = (queryParams: URLSearchParams) => {
    const ssoToken = queryParams.get("gig_ssoToken");
    
    if (queryParams.has("error") && queryParams.has("error_description") &&
        queryParams.get("error")?.toLowerCase() === "invalid_token" &&
        queryParams.get("error_description")?.toLowerCase() === "invalid sso token") {
            removeSsoTokenFromLocalStorage();
            return;
    }

    if (ssoToken) {
        //if you change this, please update the documentation: https://team.aldi-nord.com/confluence/display/CDC/Datenschutzhinweise
        localStorage.setItem("ssoToken", JSON.stringify({ 
            token: ssoToken,
            expires: Date.now() + 15 * 60 * 1000
        }));
    } else {
        const storedSsoToken = getSsoTokenFromLocalStorage();
        if (storedSsoToken && storedSsoToken.expires <= Date.now()) {
            removeSsoTokenFromLocalStorage();
        }
    }
}

const removeSsoTokenFromLocalStorage = () => {
    localStorage.removeItem("ssoToken")
}

const getSsoTokenFromLocalStorage = () => {
    const storedSsoToken = localStorage.getItem("ssoToken");
    if (!storedSsoToken) {
        return null;
    }
    
    return JSON.parse(storedSsoToken);
}

const ensureSsoTokenExistsAsQueryParameter = () => {
    if (getSsoTokenFromQueryParameter()) {
        return true;
    }
    
    const storedSsoToken = getSsoTokenFromLocalStorage();
    if (!storedSsoToken) {
        return false;
    }
    
    const url = new URL(window.location.toString());
    url.searchParams.set("gig_ssoToken", storedSsoToken.token);

    window.history.replaceState(window.history.state, "not used", url);
    return true;
}

const getQueryParameters = () => new URLSearchParams(window.location.search);

const getSsoTokenFromQueryParameter = () => getQueryParameters().get("gig_ssoToken");
