import SpriteIcon from '_assets/aldi-essentials-867/assets/brands/aldi/images/sprite.svg';
import {AccountFormItemDetail, RegisterFormInputFunction} from "../../hooks/GigyaSchema";
import "./BooleanTilesComponent.css";
import {UseFormSetValue} from "react-hook-form/dist/types/form";
import React, {useId, ReactElement} from "react";
import {Cms} from "../../CmsContext";
import {CDNResponsiveImage} from "../../_shared/components/CDNResponsiveImage";
import {FieldValues} from 'react-hook-form';

export type BooleanTilesComponentProp<TFieldValues extends FieldValues> = {
    component:Cms.Tiles,
    registerFormInput:RegisterFormInputFunction<TFieldValues>,
    formItemDetails:Map<string, AccountFormItemDetail<TFieldValues>>,
    setValue: UseFormSetValue<any>
}

/**
 * Renders a boolean tile.
 * You can set/unset more checkboxes with one switcher (see "schemaPaths" array).
 *
 * @param component Tiles object
 * @param registerFormInput RegisterFormInputFunction
 * @param formItemDetails form details map
 * @param setValue set value function from forms
 */
export const BooleanTilesComponent = <TFieldValues extends FieldValues>({component, registerFormInput, formItemDetails, setValue}: BooleanTilesComponentProp<TFieldValues>) => {

     const tiles: ReactElement[] = [];
     component.tiles.forEach((tile, tileIndex)=>{
         const inputs: ReactElement[] = [];
         let value: boolean = false;
         tile.schemaPaths.forEach((schemaPath:string, _idx:number)=> {
             const formItemDetail=formItemDetails.get(schemaPath)!;
             const {useFormRegisterReturn, errorDetails, ...props} = {...registerFormInput(formItemDetail)};
             // it's a checkbox to handle true/false as boolean
             inputs.push(<input key={schemaPath} type="checkbox" className="hidden" defaultChecked={formItemDetail.value} {...useFormRegisterReturn} {...props}/>)
             value = value || formItemDetail.value;
         });
         if (inputs.length > 0) {
             tiles.push(<BooleanTileComponent key={tileIndex} tile={tile} setValue={setValue} inputs={inputs} value={value}/>)
         } else {
             console.error(`${tile.schemaPaths} doesn't exist in CDC schema!`)
         }
    });

    return (
        <div className="preferences booleantiles">
            <h3>{component.label}</h3>
            {component.description && <p>{component.description}</p>}
            <div className="radio-thumb__collection">
                {tiles.map((elem) => elem)}
            </div>
        </div>
    );
}

type BooleanTileComponentProp = {
    tile: Cms.Tile,
    inputs: ReactElement[],
    value: boolean,
    setValue: UseFormSetValue<any>
}

const BooleanTileComponent = ( {tile, setValue, inputs, value }: BooleanTileComponentProp) => {
    const id = useId();

    const onChange = async (event:any) => {
        const checked: boolean = event.target.checked;
        inputs.forEach((input)=>{
            setValue(input.props.name, checked)
        });
    }

    return (
        <>
            <label className="radio-thumb preferences booleantile" id={tile.label}>
                <input tabIndex={0} defaultChecked={value} value="true" onChange={onChange} type="checkbox" className="radio-thumb__control" aria-describedby={id} data-attr-value={tile.label} />
                <span className="radio-thumb__thumb" aria-hidden={true} >
                    <CDNResponsiveImage
                        className="img-responsive"
                        sizes="(min-width: 1440px) 286px, (min-width: 1024px) 210px, (min-width: 768px) 232px, (min-width: 560px) 250px, calc(((100vw - 32px) / 2) - 14px)"
                        alt={tile.label}
                        src={tile.imageUrl}
                        // use heights: Because the images are landscape and therefore the height is relevant
                        heights={[210, 232, 250, 286]} // no other sizes: could be added 572, 858
                    />
                    <svg className="icon icon--check radio-thumb__icon">
                        <use href={SpriteIcon + "#check"}></use>
                    </svg>
                </span>
                <p id={id} className="radio-thumb__headline">{tile.label}</p>
            </label>
            {inputs.map((elem) => elem)}
        </>
    );
}